import { ScaleButton } from '@/StyledComponents/TeamStyled';
import Layout from '@/components/Layout/Layout';
import Cust404SvgImage from '@/components/svg/Cust404SvgImage';
import { Icon } from '@iconify/react';
import { motion } from "framer-motion";
import Link from 'next/link';
import { Container } from 'reactstrap';


export default function Custom404() {
    return (
        <Layout>
        <Container>
            <div className="privacy m-auto p-10 flex flex-col items-center">
                {/* <h1 className="">404</h1>
                <p>This page could not be found.</p> */}

                <Cust404SvgImage />
                <Link href="/" target="_self" title="Back To Home page">
                    <ScaleButton className="black-app-btn !mx-[0] ">
                        Back To Home page
                        <motion.span
                            animate={{ x: [-3, 6, -3] }}
                            transition={{
                                duration: 1,
                                repeat: Infinity,
                                ease: "linear",
                            }}
                        >
                            <Icon icon="tabler:arrow-right" />
                        </motion.span>
                    </ScaleButton>
                </Link>

            </div>
        </Container>
        </Layout>
    )
}