import React from "react";

export default function Cust404SvgImage() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style={{ enableBackground: "new 0 0 500 500" }}
      className="w-full md:w-[700px] lg:w-[700px]"
    >
      <g id="Background_Complete">
        <g>
          <g>
            <polygon
              style={{ fill: "#EBEBEB" }}
              points="213.681,321.719 212.765,327.349 211.779,333.398 202.781,388.567 188.066,388.567      197.064,333.398 198.05,327.349 198.966,321.719    "
            />
            <path
              style={{ fill: "#C7C7C7" }}
              d="M211.549,321.719c-0.775,5.588-1.609,11.167-2.451,16.745l-2.595,16.722l-2.719,16.702     l-1.404,8.344l-1.461,8.335l1.197-8.377l1.253-8.368l2.595-16.722l2.72-16.702C209.614,332.835,210.552,327.272,211.549,321.719z     "
            />
            <polygon
              style={{ fill: "#C7C7C7" }}
              points="212.765,327.349 211.779,333.398 197.064,333.398 198.05,327.349    "
            />
          </g>
          <g>
            <polygon
              style={{ fill: "#EBEBEB" }}
              points="286.319,321.719 287.235,327.349 288.221,333.398 297.219,388.567 311.934,388.567      302.936,333.398 301.949,327.349 301.034,321.719    "
            />
            <path
              style={{ fill: "#C7C7C7" }}
              d="M288.497,321.719c0.775,5.588,1.608,11.167,2.451,16.745l2.594,16.722l2.719,16.702l1.404,8.344     l1.461,8.335l-1.197-8.377l-1.254-8.368l-2.595-16.722l-2.72-16.702C290.432,332.835,289.494,327.272,288.497,321.719z"
            />
            <polygon
              style={{ fill: "#C7C7C7" }}
              points="287.235,327.349 301.949,327.349 302.936,333.398 288.221,333.398    "
            />
          </g>
          <g>
            <polygon
              style={{ fill: "#C7C7C7" }}
              points="213.144,306.2 201.477,306.2 202.928,295.325 211.694,295.325    "
            />
            <g>
              <ellipse
                transform="matrix(0.1738 -0.9848 0.9848 0.1738 -111.8915 441.7422)"
                style={{ fill: "#DBDBDB" }}
                cx="207.311"
                cy="287.553"
                rx="10.873"
                ry="10.873"
              />

              <ellipse
                transform="matrix(0.2306 -0.9731 0.9731 0.2306 -120.2939 422.9768)"
                style={{ fill: "#FFFFFF" }}
                cx="207.311"
                cy="287.553"
                rx="8.265"
                ry="8.265"
              />
              <g>
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M214.22,287.074c-0.146-2.019-1.172-3.878-2.699-5.073c-1.53-1.2-3.555-1.754-5.558-1.456       c-1.936,0.289-3.697,1.438-4.778,3.108c-1.096,1.668-1.466,3.856-0.872,5.961c0.478,1.727,1.683,3.222,3.227,4.087       c1.542,0.878,3.387,1.148,5.059,0.777c1.685-0.353,3.202-1.297,4.245-2.598c1.051-1.293,1.679-2.925,1.747-4.605       c0.004-0.079-0.025-0.146-0.066-0.149c-0.039,0-0.075,0.061-0.079,0.143c-0.028,0.682-0.177,1.352-0.386,1.993       c-0.225,0.638-0.539,1.237-0.914,1.794c-0.389,0.548-0.844,1.044-1.354,1.487c-0.519,0.433-1.095,0.802-1.716,1.109       c-1.753,0.841-3.843,0.901-5.642,0.135c-1.797-0.751-3.244-2.309-3.827-4.198c-0.626-2-0.317-4.138,0.723-5.786       c1.033-1.647,2.76-2.786,4.652-3.061c2.611-0.375,5.248,0.583,6.847,2.726c0.386,0.529,0.688,1.109,0.923,1.719       c0.224,0.615,0.36,1.258,0.433,1.92c0.005,0.018,0.016,0.025,0.025,0.019C214.22,287.118,214.224,287.097,214.22,287.074z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M213.252,287.082c-0.146-1.736-1.036-3.31-2.342-4.329c-1.311-1.019-3.026-1.496-4.748-1.26       c-1.658,0.231-3.186,1.203-4.135,2.636c-0.961,1.428-1.302,3.327-0.802,5.163c0.4,1.507,1.451,2.821,2.801,3.572       c1.346,0.763,2.957,0.993,4.412,0.66c1.463-0.317,2.775-1.144,3.674-2.278c0.906-1.128,1.446-2.534,1.511-3.991       c0.003-0.069-0.025-0.127-0.066-0.129c-0.039,0-0.075,0.053-0.079,0.124c-0.123,2.382-1.637,4.474-3.775,5.51       c-1.504,0.738-3.322,0.803-4.88,0.142c-1.558-0.646-2.817-2.006-3.307-3.643c-0.528-1.733-0.245-3.581,0.662-4.986       c0.902-1.408,2.396-2.368,4.007-2.582c2.23-0.29,4.452,0.499,5.84,2.318c0.332,0.449,0.594,0.942,0.801,1.461       c0.196,0.524,0.319,1.073,0.392,1.642c0.005,0.016,0.016,0.022,0.025,0.016C213.252,287.12,213.256,287.101,213.252,287.082z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M212.284,287.089c-0.146-1.454-0.899-2.742-1.985-3.586c-1.091-0.837-2.498-1.238-3.938-1.064       c-1.38,0.172-2.675,0.967-3.492,2.164c-0.826,1.188-1.137,2.798-0.733,4.366c0.323,1.287,1.22,2.42,2.375,3.058       c1.15,0.649,2.527,0.838,3.766,0.542c1.242-0.28,2.348-0.991,3.104-1.957c0.76-0.962,1.213-2.143,1.275-3.377       c0.003-0.058-0.025-0.108-0.067-0.109c-0.039,0-0.075,0.044-0.079,0.104c-0.118,2.007-1.394,3.743-3.18,4.636       c-1.256,0.635-2.802,0.705-4.118,0.149c-1.32-0.542-2.389-1.703-2.788-3.089c-0.431-1.467-0.173-3.024,0.6-4.186       c0.771-1.169,2.031-1.949,3.363-2.104c0.933-0.099,1.835,0.004,2.665,0.318c0.829,0.316,1.587,0.851,2.168,1.592       c0.279,0.369,0.499,0.775,0.679,1.203c0.168,0.432,0.277,0.888,0.35,1.364c0.005,0.012,0.016,0.02,0.025,0.014       C212.285,287.121,212.288,287.105,212.284,287.089z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M211.317,287.096c-0.147-1.171-0.763-2.174-1.628-2.843c-0.871-0.656-1.969-0.98-3.127-0.867       c-1.102,0.113-2.164,0.732-2.848,1.692c-0.691,0.948-0.973,2.27-0.663,3.569c0.245,1.068,0.989,2.018,1.949,2.544       c0.954,0.534,2.097,0.683,3.119,0.424c1.02-0.243,1.921-0.837,2.533-1.637c0.615-0.796,0.98-1.752,1.038-2.763       c0.003-0.048-0.025-0.088-0.066-0.09c-0.039,0-0.075,0.036-0.079,0.085c-0.113,1.632-1.152,3.012-2.585,3.762       c-1.007,0.532-2.281,0.607-3.357,0.156c-1.082-0.437-1.96-1.4-2.267-2.535c-0.333-1.2-0.102-2.467,0.538-3.387       c0.639-0.93,1.666-1.531,2.719-1.626c0.74-0.058,1.447,0.027,2.098,0.268c0.65,0.244,1.256,0.655,1.728,1.234       c0.226,0.29,0.405,0.608,0.557,0.945c0.141,0.341,0.236,0.703,0.309,1.087c0.005,0.009,0.016,0.017,0.026,0.011       C211.317,287.122,211.321,287.109,211.317,287.096z"
                />
              </g>
            </g>
          </g>
          <g>
            <polygon
              style={{ fill: "#C7C7C7" }}
              points="298.27,306.2 286.604,306.2 288.054,295.325 296.82,295.325    "
            />
            <g>
              <ellipse
                transform="matrix(0.1509 -0.9886 0.9886 0.1509 -35.944 533.26)"
                style={{ fill: "#DBDBDB" }}
                cx="292.437"
                cy="287.553"
                rx="10.873"
                ry="10.873"
              />

              <ellipse
                transform="matrix(0.1508 -0.9886 0.9886 0.1508 -35.9152 533.2984)"
                style={{ fill: "#FFFFFF" }}
                cx="292.437"
                cy="287.553"
                rx="8.265"
                ry="8.265"
              />
              <g>
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M299.346,287.074c-0.146-2.019-1.172-3.878-2.699-5.073c-1.53-1.2-3.555-1.754-5.558-1.456       c-1.937,0.289-3.697,1.438-4.778,3.108c-1.096,1.668-1.466,3.856-0.872,5.961c0.478,1.727,1.683,3.222,3.227,4.087       c1.542,0.878,3.387,1.148,5.059,0.777c1.685-0.353,3.202-1.297,4.245-2.598c1.051-1.293,1.679-2.925,1.747-4.605       c0.004-0.079-0.025-0.146-0.066-0.149c-0.039,0-0.075,0.061-0.079,0.143c-0.028,0.682-0.177,1.352-0.386,1.993       c-0.225,0.638-0.539,1.237-0.914,1.794c-0.388,0.548-0.844,1.044-1.354,1.487c-0.519,0.433-1.095,0.802-1.716,1.109       c-1.753,0.841-3.843,0.901-5.642,0.135c-1.796-0.751-3.245-2.309-3.827-4.198c-0.626-2-0.317-4.138,0.723-5.786       c1.033-1.647,2.76-2.786,4.652-3.061c2.611-0.375,5.248,0.583,6.847,2.726c0.386,0.529,0.688,1.109,0.923,1.719       c0.224,0.615,0.36,1.258,0.433,1.92c0.005,0.018,0.016,0.025,0.025,0.019C299.346,287.118,299.35,287.097,299.346,287.074z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M298.378,287.082c-0.146-1.736-1.036-3.31-2.342-4.329c-1.311-1.019-3.026-1.496-4.748-1.26       c-1.658,0.231-3.186,1.203-4.135,2.636c-0.961,1.428-1.302,3.327-0.802,5.163c0.4,1.507,1.451,2.821,2.801,3.572       c1.346,0.763,2.957,0.993,4.412,0.66c1.463-0.317,2.775-1.144,3.674-2.278c0.906-1.128,1.446-2.534,1.511-3.991       c0.004-0.069-0.025-0.127-0.066-0.129c-0.039,0-0.075,0.053-0.079,0.124c-0.123,2.382-1.637,4.474-3.775,5.51       c-1.504,0.738-3.322,0.803-4.88,0.142c-1.558-0.646-2.816-2.006-3.307-3.643c-0.528-1.733-0.245-3.581,0.661-4.986       c0.902-1.408,2.396-2.368,4.007-2.582c2.23-0.29,4.453,0.499,5.84,2.318c0.332,0.449,0.594,0.942,0.801,1.461       c0.196,0.524,0.319,1.073,0.392,1.642c0.005,0.016,0.016,0.022,0.025,0.016C298.379,287.12,298.382,287.101,298.378,287.082z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M297.411,287.089c-0.146-1.454-0.899-2.742-1.985-3.586c-1.091-0.837-2.498-1.238-3.938-1.064       c-1.38,0.172-2.675,0.967-3.492,2.164c-0.826,1.188-1.137,2.798-0.732,4.366c0.323,1.287,1.22,2.42,2.375,3.058       c1.15,0.649,2.527,0.838,3.766,0.542c1.242-0.28,2.348-0.991,3.104-1.957c0.76-0.962,1.213-2.143,1.275-3.377       c0.003-0.058-0.025-0.108-0.066-0.109c-0.039,0-0.075,0.044-0.079,0.104c-0.118,2.007-1.394,3.743-3.18,4.636       c-1.256,0.635-2.802,0.705-4.119,0.149c-1.32-0.542-2.388-1.703-2.787-3.089c-0.431-1.467-0.173-3.024,0.6-4.186       c0.771-1.169,2.031-1.949,3.363-2.104c0.933-0.099,1.835,0.004,2.665,0.318c0.828,0.316,1.587,0.851,2.168,1.592       c0.279,0.369,0.499,0.775,0.679,1.203c0.168,0.432,0.277,0.888,0.35,1.364c0.005,0.012,0.016,0.02,0.026,0.014       C297.411,287.121,297.415,287.105,297.411,287.089z"
                />
                <path
                  style={{ fill: "#EBEBEB" }}
                  d="M296.443,287.096c-0.146-1.171-0.762-2.174-1.628-2.843c-0.871-0.656-1.969-0.98-3.127-0.867       c-1.102,0.113-2.164,0.732-2.848,1.692c-0.691,0.948-0.973,2.27-0.663,3.569c0.245,1.068,0.989,2.018,1.949,2.544       c0.954,0.534,2.097,0.683,3.119,0.424c1.02-0.243,1.921-0.837,2.533-1.637c0.615-0.796,0.98-1.752,1.038-2.763       c0.003-0.048-0.025-0.088-0.066-0.09c-0.039,0-0.075,0.036-0.079,0.085c-0.113,1.632-1.152,3.012-2.585,3.762       c-1.007,0.532-2.281,0.607-3.357,0.156c-1.081-0.437-1.96-1.4-2.267-2.535c-0.333-1.2-0.102-2.467,0.538-3.387       c0.639-0.93,1.666-1.531,2.719-1.626c0.74-0.058,1.447,0.027,2.098,0.268c0.65,0.244,1.256,0.655,1.728,1.234       c0.226,0.29,0.405,0.608,0.557,0.945c0.141,0.341,0.236,0.703,0.309,1.087c0.005,0.009,0.016,0.017,0.026,0.011       C296.443,287.122,296.447,287.109,296.443,287.096z"
                />
              </g>
            </g>
          </g>
          <g>
            <rect
              x="176.694"
              y="300.657"
              style={{ fill: "#DBDBDB" }}
              width="146.612"
              height="28.043"
            />
            <g>
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="206.827,300.657 196.187,328.699 177.253,328.699 187.894,300.657     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="245.639,300.657 234.999,328.699 216.059,328.699 226.699,300.657     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="284.445,300.657 273.805,328.699 254.871,328.699 265.512,300.657     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="323.257,300.657 312.617,328.699 293.677,328.699 304.317,300.657     "
              />
            </g>
          </g>
          <g>
            <rect
              x="179.662"
              y="354.626"
              style={{ fill: "#DBDBDB" }}
              width="140.676"
              height="17.747"
            />
            <g>
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="208.575,354.626 198.365,372.373 180.199,372.373 190.408,354.626     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="245.816,354.626 235.606,372.373 217.433,372.373 227.643,354.626     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="283.05,354.626 272.841,372.373 254.674,372.373 264.884,354.626     "
              />
              <polygon
                style={{ fill: "#EBEBEB" }}
                points="320.292,354.626 310.082,372.373 291.908,372.373 302.118,354.626     "
              />
            </g>
          </g>
        </g>
        <g>
          <path
            style={{ fill: "#EBEBEB" }}
            d="M467.799,167.107H354.618c0,0,11.222-30.651,28.493-30.651s22.681,21.157,22.681,21.157    s11.653-11.495,21.224-11.495c9.572,0,17.895,15.19,17.895,15.19S458.436,153.139,467.799,167.107z"
          />
          <path
            style={{ fill: "#EBEBEB" }}
            d="M164.467,95.221h-52.892c0,0,11.819-17.123,21.13-17.123c9.312,0,12.063,11.416,12.063,11.416    s6.571-5.327,12.66-4.376C163.516,86.089,164.467,95.221,164.467,95.221z"
          />
          <path
            style={{ fill: "#EBEBEB" }}
            d="M449.78,218.51h-52.892c0,0,11.819-17.123,21.13-17.123c9.311,0,12.063,11.416,12.063,11.416    s6.571-5.327,12.66-4.376C448.828,209.377,449.78,218.51,449.78,218.51z"
          />
          <path
            style={{ fill: "#EBEBEB" }}
            d="M135.226,138.126H32.201c0,0,14.109-31.498,29.201-33.467    c15.093-1.969,22.311,23.405,22.311,23.405s9.734-8.968,17.39-8.968s13.562,11.812,13.562,11.812S129.976,122.814,135.226,138.126    z"
          />
        </g>
      </g>
      <g id="Background_Simple" style={{ display: "none" }}>
        <g style={{ display: "inline" }}>
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.28,137.79l-0.25,0.43l-0.68,1.16c-0.06,0.01-0.11,0.04-0.18,0.04c-0.66,0-1.19-0.54-1.19-1.2    s0.53-1.19,1.19-1.19C282.68,137.03,283.11,137.35,283.28,137.79z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,131.04c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C282.83,129.84,283.37,130.38,283.37,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,123.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C282.83,122.66,283.37,123.19,283.37,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,116.67c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C282.83,115.48,283.37,116.01,283.37,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,109.49c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C282.83,108.29,283.37,108.83,283.37,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,102.3c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C282.83,101.11,283.37,101.64,283.37,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,95.12c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C282.83,93.92,283.37,94.46,283.37,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M283.37,87.94c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C282.83,86.74,283.37,87.28,283.37,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M285.77,133.43c0.02,0,0.04,0.02,0.07,0.02l-1.07,1.81c-0.11-0.19-0.2-0.39-0.2-0.63    C284.57,133.97,285.1,133.43,285.77,133.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C286.43,126.25,286.96,126.79,286.96,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,120.26c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C286.43,119.07,286.96,119.6,286.96,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,113.08c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C286.43,111.88,286.96,112.42,286.96,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,105.9c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C286.43,104.7,286.96,105.24,286.96,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,98.71c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C286.43,97.52,286.96,98.05,286.96,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,91.53c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C286.43,90.33,286.96,90.87,286.96,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M286.96,84.34c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C286.43,83.15,286.96,83.68,286.96,84.34z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,123.86c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C290.02,122.66,290.55,123.19,290.55,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,116.67c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C290.02,115.48,290.55,116.01,290.55,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,109.49c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C290.02,108.29,290.55,108.83,290.55,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,102.3c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C290.02,101.11,290.55,101.64,290.55,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,95.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C290.02,93.92,290.55,94.46,290.55,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.55,87.94c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C290.02,86.74,290.55,87.28,290.55,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M290.35,80.12c0.12,0.18,0.2,0.39,0.2,0.63c0,0.66-0.53,1.2-1.19,1.2c-0.6,0-1.08-0.45-1.17-1.03    l0.45-0.17l0.72-0.26L290.35,80.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="292.95" cy="113.08" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M294.15,105.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C293.61,104.7,294.15,105.24,294.15,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M294.15,98.71c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C293.61,97.52,294.15,98.05,294.15,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M294.15,91.53c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C293.61,90.33,294.15,90.87,294.15,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M294.15,84.34c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C293.61,83.15,294.15,83.68,294.15,84.34z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M293.95,119.65l-1.06,1.8c-0.63-0.03-1.14-0.55-1.14-1.19c0-0.66,0.54-1.19,1.2-1.19    C293.38,119.07,293.74,119.31,293.95,119.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M296.38,115.51l-0.68,1.16l-0.26,0.45c-0.06-0.14-0.09-0.29-0.09-0.45    C295.35,116.07,295.8,115.59,296.38,115.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M297.74,109.49c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S297.74,108.83,297.74,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M297.74,102.3c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S297.74,101.64,297.74,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M297.74,95.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S297.74,94.46,297.74,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M297.74,87.94c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S297.74,87.28,297.74,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M297.74,80.75c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S297.74,80.09,297.74,80.75z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.33,105.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S301.33,105.24,301.33,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.33,98.71c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S301.33,98.05,301.33,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.33,91.53c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S301.33,90.87,301.33,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.33,84.34c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S301.33,83.68,301.33,84.34z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.33,77.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.09,0.03-0.18,0.05-0.27l1.83-0.68    C301.12,76.43,301.33,76.76,301.33,77.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M303.73,101.11c0.35,0,0.66,0.16,0.88,0.4l-0.46,0.79l-0.42,0.72l-0.26,0.43    c-0.53-0.12-0.94-0.58-0.94-1.15C302.53,101.64,303.06,101.11,303.73,101.11z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M304.92,95.12c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C304.39,93.92,304.92,94.46,304.92,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M304.92,87.94c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C304.39,86.74,304.92,87.28,304.92,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M304.92,80.75c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C304.39,79.56,304.92,80.09,304.92,80.75z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M306.91,97.6l-0.75,1.28c-0.01-0.05-0.04-0.11-0.04-0.17C306.12,98.19,306.45,97.76,306.91,97.6z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M308.51,91.53c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S308.51,90.87,308.51,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M308.51,84.34c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S308.51,83.68,308.51,84.34z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M308.51,77.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S308.51,76.5,308.51,77.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M312.11,87.94c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S312.11,87.28,312.11,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M312.11,80.75c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S312.11,80.09,312.11,80.75z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M312.11,73.57c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.31,0.13-0.58,0.31-0.79l0.89-0.33    l0.14-0.05C311.64,72.47,312.11,72.96,312.11,73.57z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="314.5" cy="77.16" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M314.5,83.15c0.29,0,0.54,0.11,0.74,0.28l-1.19,2.02c-0.44-0.18-0.75-0.6-0.75-1.11    C313.3,83.68,313.84,83.15,314.5,83.15z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M314.5,71.17c-0.04,0-0.07-0.02-0.11-0.02l0.98-0.36C315.15,71.02,314.85,71.17,314.5,71.17z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M316.94,80.54c0.05-0.29,0.2-0.53,0.41-0.71L316.94,80.54z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M319.29,73.57c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S319.29,72.91,319.29,73.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M322.88,69.98c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C322.35,68.78,322.88,69.32,322.88,69.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M324.72,67.3l-0.05,0.09c-0.02-0.01-0.05-0.03-0.07-0.04L324.72,67.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,105.9c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S258.23,105.24,258.23,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,98.71c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S258.23,98.05,258.23,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M257.03,92.72c-0.11,0-0.22-0.03-0.32-0.06l1.3-0.49C257.8,92.5,257.45,92.72,257.03,92.72z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,174.14c0,0.09-0.03,0.17-0.05,0.25l-0.38,0.65c-0.21,0.18-0.47,0.3-0.77,0.3    c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19S261.82,173.48,261.82,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,166.96c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S261.82,166.3,261.82,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,159.77c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S261.82,159.11,261.82,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,152.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S261.82,151.93,261.82,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S261.82,144.75,261.82,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,138.22c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S261.82,137.56,261.82,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,131.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S261.82,130.38,261.82,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,123.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S261.82,123.19,261.82,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,116.67c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S261.82,116.01,261.82,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,109.49c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S261.82,108.83,261.82,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,102.3c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S261.82,101.64,261.82,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M261.82,95.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S261.82,94.46,261.82,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S265.41,162.71,265.41,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S265.41,155.52,265.41,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,149c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S265.41,148.34,265.41,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S265.41,141.15,265.41,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,134.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S265.41,133.97,265.41,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S265.41,126.79,265.41,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S265.41,119.6,265.41,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,113.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S265.41,112.42,265.41,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,105.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S265.41,105.24,265.41,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,98.71c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S265.41,98.05,265.41,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M265.41,91.53c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S265.41,90.87,265.41,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M264.21,169.35c0.17,0,0.32,0.04,0.47,0.1l-1.2,2.02c-0.27-0.22-0.46-0.54-0.46-0.92    C263.02,169.89,263.55,169.35,264.21,169.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M267.81,89.13c-0.31,0-0.59-0.12-0.8-0.31l0.8-0.3l1.16-0.43    C268.9,88.67,268.41,89.13,267.81,89.13z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S269,159.11,269,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2S269,151.93,269,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S269,144.75,269,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19S269,137.56,269,138.22    z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,131.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S269,130.38,269,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,123.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S269,123.19,269,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,116.67c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S269,116.01,269,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,109.49c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S269,108.83,269,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,102.3c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S269,101.64,269,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M269,95.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S269,94.46,269,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,149c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S272.59,148.34,272.59,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,141.81c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S272.59,141.15,272.59,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,134.63c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S272.59,133.97,272.59,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,127.45c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S272.59,126.79,272.59,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,120.26c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S272.59,119.6,272.59,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,113.08c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S272.59,112.42,272.59,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,105.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S272.59,105.24,272.59,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,98.71c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S272.59,98.05,272.59,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.59,91.53c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S272.59,90.87,272.59,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M272.56,156.03l-0.74,1.26c-0.13,0.05-0.27,0.09-0.42,0.09c-0.66,0-1.2-0.54-1.2-1.2    c0-0.66,0.54-1.19,1.2-1.19C272,154.99,272.48,155.44,272.56,156.03z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M274.99,151.39c0.09,0,0.18,0.04,0.27,0.06l-0.27,0.45l-0.41,0.69l-0.47,0.8    c-0.19-0.21-0.32-0.49-0.32-0.8C273.79,151.93,274.33,151.39,274.99,151.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S276.19,144.75,276.19,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,138.22c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S276.19,137.56,276.19,138.22z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="274.99" cy="131.04" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,123.86c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S276.19,123.19,276.19,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,116.67c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S276.19,116.01,276.19,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,109.49c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S276.19,108.83,276.19,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,102.3c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S276.19,101.64,276.19,102.3z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="274.99" cy="95.12" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M276.19,87.94c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S276.19,87.28,276.19,87.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,141.81c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C279.24,140.62,279.78,141.15,279.78,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,134.63c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C279.24,133.43,279.78,133.97,279.78,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,127.45c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C279.24,126.25,279.78,126.79,279.78,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,120.26c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C279.24,119.07,279.78,119.6,279.78,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,113.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C279.24,111.88,279.78,112.42,279.78,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,105.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C279.24,104.7,279.78,105.24,279.78,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,98.71c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C279.24,97.52,279.78,98.05,279.78,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.78,91.53c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C279.24,90.33,279.78,90.87,279.78,91.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M279.72,84.08c0.02,0.09,0.06,0.17,0.06,0.26c0,0.66-0.54,1.2-1.2,1.2c-0.46,0-0.84-0.27-1.04-0.65    L279.72,84.08z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="239.07" cy="152.59" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C239.73,144.21,240.27,144.75,240.27,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C239.73,137.03,240.27,137.56,240.27,138.22z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="239.07" cy="131.04" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,123.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C239.73,122.66,240.27,123.19,240.27,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,116.67c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C239.73,115.48,240.27,116.01,240.27,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,109.49c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C239.73,108.29,240.27,108.83,240.27,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,102.3c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C239.73,101.11,240.27,101.64,240.27,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S243.86,198.62,243.86,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S243.86,191.44,243.86,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S243.86,184.26,243.86,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S243.86,177.07,243.86,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S243.86,169.89,243.86,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S243.86,162.71,243.86,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S243.86,155.52,243.86,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,149c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S243.86,148.34,243.86,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S243.86,141.15,243.86,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,134.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S243.86,133.97,243.86,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S243.86,126.79,243.86,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S243.86,119.6,243.86,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,113.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S243.86,112.42,243.86,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,105.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S243.86,105.24,243.86,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M243.86,98.71c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.14,0.03-0.27,0.08-0.4l1.69-0.63    C243.6,97.89,243.86,98.26,243.86,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M242.66,205.27c0.3,0,0.57,0.12,0.78,0.31l-1.18,2c-0.46-0.16-0.79-0.59-0.79-1.11    C241.47,205.81,242,205.27,242.66,205.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M245.59,201.91l-0.51,0.88C245.11,202.42,245.3,202.1,245.59,201.91z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,195.69c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C246.92,194.5,247.45,195.03,247.45,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,188.51c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C246.92,187.31,247.45,187.85,247.45,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,181.33c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C246.92,180.13,247.45,180.66,247.45,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,174.14c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C246.92,172.95,247.45,173.48,247.45,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,166.96c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C246.92,165.76,247.45,166.3,247.45,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,159.77c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C246.92,158.58,247.45,159.11,247.45,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,152.59c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C246.92,151.39,247.45,151.93,247.45,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,145.41c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C246.92,144.21,247.45,144.75,247.45,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,138.22c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C246.92,137.03,247.45,137.56,247.45,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,131.04c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C246.92,129.84,247.45,130.38,247.45,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,123.86c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C246.92,122.66,247.45,123.19,247.45,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,116.67c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C246.92,115.48,247.45,116.01,247.45,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,109.49c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C246.92,108.29,247.45,108.83,247.45,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M247.45,102.3c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C246.92,101.11,247.45,101.64,247.45,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,190.91,251.04,191.44,251.04,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C250.51,183.72,251.04,184.26,251.04,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,176.54,251.04,177.07,251.04,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C250.51,169.35,251.04,169.89,251.04,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C250.51,162.17,251.04,162.71,251.04,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,154.99,251.04,155.52,251.04,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C250.51,147.8,251.04,148.34,251.04,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,140.62,251.04,141.15,251.04,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C250.51,133.43,251.04,133.97,251.04,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C250.51,126.25,251.04,126.79,251.04,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,120.26c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,119.07,251.04,119.6,251.04,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,113.08c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C250.51,111.88,251.04,112.42,251.04,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,105.9c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C250.51,104.7,251.04,105.24,251.04,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M251.04,98.71c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C250.51,97.52,251.04,98.05,251.04,98.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M253.44,187.31c0.23,0,0.43,0.09,0.62,0.2l-0.59,1l-0.03,0.05l-0.58,0.98    c-0.36-0.21-0.62-0.58-0.62-1.03C252.24,187.85,252.78,187.31,253.44,187.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S254.63,180.66,254.63,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S254.63,173.48,254.63,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S254.63,166.3,254.63,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S254.63,159.11,254.63,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S254.63,151.93,254.63,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S254.63,144.75,254.63,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S254.63,137.56,254.63,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,131.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S254.63,130.38,254.63,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,123.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S254.63,123.19,254.63,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,116.67c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S254.63,116.01,254.63,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,109.49c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S254.63,108.83,254.63,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,102.3c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S254.63,101.64,254.63,102.3z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M254.63,95.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.45,0.26-0.82,0.62-1.03l0.31-0.11    c0.09-0.02,0.17-0.06,0.27-0.06C254.1,93.92,254.63,94.46,254.63,95.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S258.23,177.07,258.23,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="257.03" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S258.23,162.71,258.23,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S258.23,155.52,258.23,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,149c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S258.23,148.34,258.23,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S258.23,141.15,258.23,141.81z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="257.03" cy="134.63" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S258.23,126.79,258.23,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M258.23,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S258.23,119.6,258.23,120.26z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="257.03" cy="113.08" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,159.77c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S225.9,159.11,225.9,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,152.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S225.9,151.93,225.9,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S225.9,144.75,225.9,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,138.22c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S225.9,137.56,225.9,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,131.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S225.9,130.38,225.9,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,123.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S225.9,123.19,225.9,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,116.67c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S225.9,116.01,225.9,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,109.49c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S225.9,108.83,225.9,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C228.96,226.82,229.49,227.36,229.49,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C228.96,219.64,229.49,220.18,229.49,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,212.46,229.49,212.99,229.49,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C228.96,205.27,229.49,205.81,229.49,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C228.96,198.09,229.49,198.62,229.49,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,190.91,229.49,191.44,229.49,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C228.96,183.72,229.49,184.26,229.49,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,176.54,229.49,177.07,229.49,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C228.96,169.35,229.49,169.89,229.49,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C228.96,162.17,229.49,162.71,229.49,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,154.99,229.49,155.52,229.49,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,149c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C228.96,147.8,229.49,148.34,229.49,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,140.62,229.49,141.15,229.49,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C228.96,133.43,229.49,133.97,229.49,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C228.96,126.25,229.49,126.79,229.49,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,120.26c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C228.96,119.07,229.49,119.6,229.49,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,113.08c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C228.96,111.88,229.49,112.42,229.49,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M229.49,105.9c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C228.96,104.7,229.49,105.24,229.49,105.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M232.8,223.68l-0.45,0.75l-0.46,0.79l-0.21,0.36c-0.56-0.1-0.99-0.57-0.99-1.15    c0-0.66,0.54-1.2,1.2-1.2C232.26,223.23,232.58,223.41,232.8,223.68z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S233.08,216.58,233.08,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S233.08,209.4,233.08,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S233.08,202.22,233.08,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S233.08,195.03,233.08,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S233.08,187.85,233.08,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S233.08,180.66,233.08,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S233.08,173.48,233.08,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S233.08,166.3,233.08,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S233.08,159.11,233.08,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S233.08,151.93,233.08,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S233.08,144.75,233.08,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S233.08,137.56,233.08,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,131.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S233.08,130.38,233.08,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,123.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S233.08,123.19,233.08,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,116.67c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S233.08,116.01,233.08,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M233.08,109.49c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S233.08,108.83,233.08,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M232.81,101.57c0.16,0.2,0.27,0.45,0.27,0.73c0,0.66-0.53,1.2-1.19,1.2    c-0.65,0-1.16-0.51-1.19-1.15l0.14-0.05l1.05-0.39L232.81,101.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M235.13,219.71l-0.8,1.36c-0.02-0.08-0.05-0.15-0.05-0.23    C234.28,220.3,234.64,219.86,235.13,219.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,212.46,236.68,212.99,236.68,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="235.48" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C236.14,198.09,236.68,198.62,236.68,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,190.91,236.68,191.44,236.68,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C236.14,183.72,236.68,184.26,236.68,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,176.54,236.68,177.07,236.68,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="235.48" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C236.14,162.17,236.68,162.71,236.68,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,154.99,236.68,155.52,236.68,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,149c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C236.14,147.8,236.68,148.34,236.68,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,141.81c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,140.62,236.68,141.15,236.68,141.81z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="235.48" cy="134.63" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,127.45c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C236.14,126.25,236.68,126.79,236.68,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,120.26c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C236.14,119.07,236.68,119.6,236.68,120.26z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="235.48" cy="113.08" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M236.68,105.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C236.14,104.7,236.68,105.24,236.68,105.9z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="239.07" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C239.73,201.68,240.27,202.22,240.27,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C239.73,194.5,240.27,195.03,240.27,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="239.07" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C239.73,180.13,240.27,180.66,240.27,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C239.73,172.95,240.27,173.48,240.27,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C239.73,165.76,240.27,166.3,240.27,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M240.27,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C239.73,158.58,240.27,159.11,240.27,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,190.91,215.12,191.44,215.12,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C214.59,183.72,215.12,184.26,215.12,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,176.54,215.12,177.07,215.12,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C214.59,169.35,215.12,169.89,215.12,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C214.59,162.17,215.12,162.71,215.12,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,154.99,215.12,155.52,215.12,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C214.59,147.8,215.12,148.34,215.12,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,140.62,215.12,141.15,215.12,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C214.59,133.43,215.12,133.97,215.12,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C214.59,126.25,215.12,126.79,215.12,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,120.26c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,119.07,215.12,119.6,215.12,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,113.08c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C214.59,111.88,215.12,112.42,215.12,113.08z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="217.52" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C218.18,237.6,218.72,238.14,218.72,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C218.18,230.42,218.72,230.95,218.72,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C218.18,223.23,218.72,223.77,218.72,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C218.18,216.05,218.72,216.58,218.72,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="217.52" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C218.18,201.68,218.72,202.22,218.72,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C218.18,194.5,218.72,195.03,218.72,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="217.52" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C218.18,180.13,218.72,180.66,218.72,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C218.18,172.95,218.72,173.48,218.72,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C218.18,165.76,218.72,166.3,218.72,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C218.18,158.58,218.72,159.11,218.72,159.77z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="217.52" cy="152.59" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C218.18,144.21,218.72,144.75,218.72,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C218.18,137.03,218.72,137.56,218.72,138.22z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="217.52" cy="131.04" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,123.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C218.18,122.66,218.72,123.19,218.72,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,116.67c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C218.18,115.48,218.72,116.01,218.72,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M218.72,109.49c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C218.18,108.29,218.72,108.83,218.72,109.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S222.31,234.54,222.31,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S222.31,227.36,222.31,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S222.31,220.18,222.31,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,212.99,222.31,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,206.47c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S222.31,205.81,222.31,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S222.31,198.62,222.31,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,191.44,222.31,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S222.31,184.26,222.31,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,177.07,222.31,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S222.31,169.89,222.31,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S222.31,162.71,222.31,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,155.52,222.31,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,149c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S222.31,148.34,222.31,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,141.15,222.31,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,134.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S222.31,133.97,222.31,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S222.31,126.79,222.31,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S222.31,119.6,222.31,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.31,113.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S222.31,112.42,222.31,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.14,241.81l-1.04,1.77c-0.66-0.01-1.18-0.54-1.18-1.19c0-0.66,0.53-1.2,1.19-1.2    C221.56,241.19,221.93,241.45,222.14,241.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M222.23,105.51c0.04,0.12,0.08,0.25,0.08,0.39c0,0.66-0.54,1.19-1.2,1.19    c-0.5,0-0.93-0.31-1.1-0.75L222.23,105.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M224.6,237.62l-0.69,1.18l-0.29,0.49c-0.07-0.15-0.11-0.32-0.11-0.49    C223.51,238.17,223.99,237.68,224.6,237.62z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S225.9,230.95,225.9,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S225.9,223.77,225.9,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S225.9,216.58,225.9,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S225.9,209.4,225.9,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S225.9,202.22,225.9,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,195.69c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S225.9,195.03,225.9,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,188.51c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S225.9,187.85,225.9,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S225.9,180.66,225.9,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,174.14c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S225.9,173.48,225.9,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M225.9,166.96c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S225.9,166.3,225.9,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M203.15,273.52c0.11,0,0.21,0.03,0.31,0.06l-0.31,0.52l-0.36,0.61l-0.49,0.84    c-0.21-0.21-0.34-0.51-0.34-0.84C201.96,274.05,202.49,273.52,203.15,273.52z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,266.34,204.35,266.87,204.35,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C203.81,259.15,204.35,259.69,204.35,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,251.97,204.35,252.5,204.35,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,245.98c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C203.81,244.78,204.35,245.32,204.35,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C203.81,237.6,204.35,238.14,204.35,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,230.42,204.35,230.95,204.35,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C203.81,223.23,204.35,223.77,204.35,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C203.81,216.05,204.35,216.58,204.35,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C203.81,208.86,204.35,209.4,204.35,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C203.81,201.68,204.35,202.22,204.35,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,194.5,204.35,195.03,204.35,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,188.51c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C203.81,187.31,204.35,187.85,204.35,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C203.81,180.13,204.35,180.66,204.35,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,172.95,204.35,173.48,204.35,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C203.81,165.76,204.35,166.3,204.35,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C203.81,158.58,204.35,159.11,204.35,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,152.59c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C203.81,151.39,204.35,151.93,204.35,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C203.81,144.21,204.35,144.75,204.35,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C203.81,137.03,204.35,137.56,204.35,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,131.04c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C203.81,129.84,204.35,130.38,204.35,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,123.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C203.81,122.66,204.35,123.19,204.35,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M204.35,116.67c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C203.81,115.48,204.35,116.01,204.35,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,263.94c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C207.4,262.74,207.94,263.28,207.94,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C207.4,255.56,207.94,256.09,207.94,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,248.38,207.94,248.91,207.94,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C207.4,241.19,207.94,241.73,207.94,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C207.4,234.01,207.94,234.54,207.94,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,228.02c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C207.4,226.82,207.94,227.36,207.94,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C207.4,219.64,207.94,220.18,207.94,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,212.46,207.94,212.99,207.94,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,206.47c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C207.4,205.27,207.94,205.81,207.94,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C207.4,198.09,207.94,198.62,207.94,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,190.91,207.94,191.44,207.94,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C207.4,183.72,207.94,184.26,207.94,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,176.54,207.94,177.07,207.94,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,170.55c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C207.4,169.35,207.94,169.89,207.94,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C207.4,162.17,207.94,162.71,207.94,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,154.99,207.94,155.52,207.94,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,149c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C207.4,147.8,207.94,148.34,207.94,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,141.81c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,140.62,207.94,141.15,207.94,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,134.63c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C207.4,133.43,207.94,133.97,207.94,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,127.45c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C207.4,126.25,207.94,126.79,207.94,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,120.26c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C207.4,119.07,207.94,119.6,207.94,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M207.94,113.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C207.4,111.88,207.94,112.42,207.94,113.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M210.34,109.95l1.19-0.45c-0.01,0.66-0.54,1.18-1.19,1.18c-0.36,0-0.67-0.16-0.89-0.4    L210.34,109.95z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.46,259.98l-0.22,0.37l-0.68,1.15c-0.07,0.01-0.14,0.04-0.22,0.04c-0.67,0-1.2-0.53-1.2-1.19    s0.53-1.2,1.2-1.2C210.86,259.15,211.3,259.5,211.46,259.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C211,251.97,211.53,252.5,211.53,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C211,244.78,211.53,245.32,211.53,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C211,237.6,211.53,238.14,211.53,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C211,230.42,211.53,230.95,211.53,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C211,223.23,211.53,223.77,211.53,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C211,216.05,211.53,216.58,211.53,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C211,208.86,211.53,209.4,211.53,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,202.88c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C211,201.68,211.53,202.22,211.53,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,195.69c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C211,194.5,211.53,195.03,211.53,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,188.51c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C211,187.31,211.53,187.85,211.53,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,181.33c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C211,180.13,211.53,180.66,211.53,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,174.14c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C211,172.95,211.53,173.48,211.53,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,166.96c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C211,165.76,211.53,166.3,211.53,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,159.77c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C211,158.58,211.53,159.11,211.53,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,152.59c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C211,151.39,211.53,151.93,211.53,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,145.41c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C211,144.21,211.53,144.75,211.53,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,138.22c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C211,137.03,211.53,137.56,211.53,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,131.04c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C211,129.84,211.53,130.38,211.53,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,123.86c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C211,122.66,211.53,123.19,211.53,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M211.53,116.67c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C211,115.48,211.53,116.01,211.53,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M213.93,255.56c0.04,0,0.07,0.02,0.11,0.02l-1.08,1.84c-0.13-0.19-0.23-0.41-0.23-0.66    C212.73,256.09,213.27,255.56,213.93,255.56z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,248.38,215.12,248.91,215.12,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C214.59,241.19,215.12,241.73,215.12,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C214.59,234.01,215.12,234.54,215.12,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C214.59,226.82,215.12,227.36,215.12,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C214.59,219.64,215.12,220.18,215.12,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C214.59,212.46,215.12,212.99,215.12,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C214.59,205.27,215.12,205.81,215.12,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M215.12,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C214.59,198.09,215.12,198.62,215.12,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,190.91,193.57,191.44,193.57,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C193.04,183.72,193.57,184.26,193.57,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,176.54,193.57,177.07,193.57,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C193.04,169.35,193.57,169.89,193.57,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C193.04,162.17,193.57,162.71,193.57,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,154.99,193.57,155.52,193.57,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C193.04,147.8,193.57,148.34,193.57,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,140.62,193.57,141.15,193.57,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C193.04,133.43,193.57,133.97,193.57,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C193.04,126.25,193.57,126.79,193.57,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,120.26c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,119.07,193.57,119.6,193.57,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M192.38,291.48c0.17,0,0.34,0.04,0.49,0.11l-1.19,2.03c-0.3-0.22-0.5-0.55-0.5-0.95    C191.18,292.01,191.72,291.48,192.38,291.48z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S197.16,281.24,197.16,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S197.16,274.05,197.16,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,266.87,197.16,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S197.16,259.69,197.16,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,252.5,197.16,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S197.16,245.32,197.16,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S197.16,238.14,197.16,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,230.95,197.16,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S197.16,223.77,197.16,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S197.16,216.58,197.16,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S197.16,209.4,197.16,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S197.16,202.22,197.16,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,195.03,197.16,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S197.16,187.85,197.16,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S197.16,180.66,197.16,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,173.48,197.16,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S197.16,166.3,197.16,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S197.16,159.11,197.16,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S197.16,151.93,197.16,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S197.16,144.75,197.16,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S197.16,137.56,197.16,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,131.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S197.16,130.38,197.16,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,123.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S197.16,123.19,197.16,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M197.16,116.67c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S197.16,116.01,197.16,116.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S200.76,270.46,200.76,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="199.56" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S200.76,256.09,200.76,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,248.91,200.76,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S200.76,241.73,200.76,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S200.76,234.54,200.76,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="199.56" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S200.76,220.18,200.76,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,212.99,200.76,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="199.56" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S200.76,198.62,200.76,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,191.44,200.76,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S200.76,184.26,200.76,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,177.07,200.76,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="199.56" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S200.76,162.71,200.76,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,155.52,200.76,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,149c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S200.76,148.34,200.76,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,141.15,200.76,141.81z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="199.56" cy="134.63" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S200.76,126.79,200.76,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.76,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S200.76,119.6,200.76,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M199.56,114.28c-0.18,0-0.35-0.05-0.51-0.13l1.6-0.59C200.47,113.98,200.05,114.28,199.56,114.28z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M200.74,278.21l-0.7,1.19c-0.15,0.06-0.31,0.1-0.48,0.1c-0.66,0-1.2-0.53-1.2-1.19    s0.54-1.2,1.2-1.2C200.19,277.11,200.69,277.6,200.74,278.21z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C182.26,223.23,182.8,223.77,182.8,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C182.26,216.05,182.8,216.58,182.8,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C182.26,201.68,182.8,202.22,182.8,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,194.5,182.8,195.03,182.8,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C182.26,180.13,182.8,180.66,182.8,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,172.95,182.8,173.48,182.8,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C182.26,165.76,182.8,166.3,182.8,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C182.26,158.58,182.8,159.11,182.8,159.77z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="152.59" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C182.26,144.21,182.8,144.75,182.8,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,137.03,182.8,137.56,182.8,138.22z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="131.04" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,123.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C182.26,122.66,182.8,123.19,182.8,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S186.39,299.2,186.39,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,292.01,186.39,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S186.39,284.83,186.39,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S186.39,277.65,186.39,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S186.39,270.46,186.39,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S186.39,263.28,186.39,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S186.39,256.09,186.39,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,248.91,186.39,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S186.39,241.73,186.39,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S186.39,234.54,186.39,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S186.39,227.36,186.39,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S186.39,220.18,186.39,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,212.99,186.39,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,206.47c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S186.39,205.81,186.39,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S186.39,198.62,186.39,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,191.44,186.39,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S186.39,184.26,186.39,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,177.07,186.39,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S186.39,169.89,186.39,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S186.39,162.71,186.39,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,155.52,186.39,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,149c0,0.66-0.54,1.19-1.2,1.19S184,149.66,184,149s0.53-1.2,1.19-1.2    S186.39,148.34,186.39,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S186.39,141.15,186.39,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,134.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S186.39,133.97,186.39,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,127.45c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S186.39,126.79,186.39,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M186.39,120.26c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.2,0.06-0.38,0.15-0.55l1.49-0.55    C186.08,119.33,186.39,119.76,186.39,120.26z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,296.27c0,0.13-0.04,0.25-0.07,0.37l-0.29,0.48c-0.21,0.21-0.51,0.34-0.84,0.34    c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2S189.98,295.61,189.98,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S189.98,288.42,189.98,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S189.98,281.24,189.98,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S189.98,274.05,189.98,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,266.87,189.98,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S189.98,259.69,189.98,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,252.5,189.98,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S189.98,245.32,189.98,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S189.98,238.14,189.98,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,230.95,189.98,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S189.98,223.77,189.98,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S189.98,216.58,189.98,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S189.98,209.4,189.98,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S189.98,202.22,189.98,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,195.69c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,195.03,189.98,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,188.51c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S189.98,187.85,189.98,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S189.98,180.66,189.98,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,174.14c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,173.48,189.98,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,166.96c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S189.98,166.3,189.98,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,159.77c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S189.98,159.11,189.98,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,152.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S189.98,151.93,189.98,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S189.98,144.75,189.98,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,138.22c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S189.98,137.56,189.98,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,131.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S189.98,130.38,189.98,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M189.98,123.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S189.98,123.19,189.98,123.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,285.49c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C193.04,284.29,193.57,284.83,193.57,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,278.31c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C193.04,277.11,193.57,277.65,193.57,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C193.04,269.93,193.57,270.46,193.57,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C193.04,262.74,193.57,263.28,193.57,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C193.04,255.56,193.57,256.09,193.57,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,248.38,193.57,248.91,193.57,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C193.04,241.19,193.57,241.73,193.57,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C193.04,234.01,193.57,234.54,193.57,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C193.04,226.82,193.57,227.36,193.57,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C193.04,219.64,193.57,220.18,193.57,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C193.04,212.46,193.57,212.99,193.57,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C193.04,205.27,193.57,205.81,193.57,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M193.57,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C193.04,198.09,193.57,198.62,193.57,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,317.82c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,317.16,175.61,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,310.63c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,309.97,175.61,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,303.45c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S175.61,302.79,175.61,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,296.27c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,295.61,175.61,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,289.08c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S175.61,288.42,175.61,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,281.24,175.61,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S175.61,274.05,175.61,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,266.87,175.61,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,259.69,175.61,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,252.5,175.61,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S175.61,245.32,175.61,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,238.14,175.61,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,230.95,175.61,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S175.61,223.77,175.61,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S175.61,216.58,175.61,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S175.61,209.4,175.61,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S175.61,202.22,175.61,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,195.03,175.61,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S175.61,187.85,175.61,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S175.61,180.66,175.61,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,173.48,175.61,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S175.61,166.3,175.61,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S175.61,159.11,175.61,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S175.61,151.93,175.61,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S175.61,144.75,175.61,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S175.61,137.56,175.61,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.61,131.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S175.61,130.38,175.61,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M175.27,123.02c0.21,0.22,0.34,0.51,0.34,0.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19    c0-0.03,0.01-0.05,0.02-0.08l1.18-0.44L175.27,123.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,314.23c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,313.03,179.2,313.57,179.2,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,307.04c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C178.67,305.85,179.2,306.38,179.2,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,299.86c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C178.67,298.66,179.2,299.2,179.2,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,292.67c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,291.48,179.2,292.01,179.2,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,285.49c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C178.67,284.29,179.2,284.83,179.2,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,278.31c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,277.11,179.2,277.65,179.2,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C178.67,269.93,179.2,270.46,179.2,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C178.67,262.74,179.2,263.28,179.2,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C178.67,255.56,179.2,256.09,179.2,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,248.38,179.2,248.91,179.2,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,241.19,179.2,241.73,179.2,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C178.67,234.01,179.2,234.54,179.2,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C178.67,226.82,179.2,227.36,179.2,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,219.64,179.2,220.18,179.2,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,212.46,179.2,212.99,179.2,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C178.67,205.27,179.2,205.81,179.2,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C178.67,198.09,179.2,198.62,179.2,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,190.91,179.2,191.44,179.2,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,183.72,179.2,184.26,179.2,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,176.54,179.2,177.07,179.2,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C178.67,169.35,179.2,169.89,179.2,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C178.67,162.17,179.2,162.71,179.2,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,154.99,179.2,155.52,179.2,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C178.67,147.8,179.2,148.34,179.2,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C178.67,140.62,179.2,141.15,179.2,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C178.67,133.43,179.2,133.97,179.2,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M179.2,127.45c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C178.67,126.25,179.2,126.79,179.2,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M181.6,309.44c0.25,0,0.46,0.09,0.65,0.22l-0.57,0.97l-0.08,0.14l-0.54,0.92    c-0.38-0.2-0.66-0.59-0.66-1.06C180.4,309.97,180.94,309.44,181.6,309.44z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="303.45" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,296.27c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C182.26,295.07,182.8,295.61,182.8,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,289.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C182.26,287.89,182.8,288.42,182.8,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,281.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C182.26,280.7,182.8,281.24,182.8,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,274.71c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C182.26,273.52,182.8,274.05,182.8,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,266.34,182.8,266.87,182.8,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C182.26,259.15,182.8,259.69,182.8,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,251.97,182.8,252.5,182.8,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="181.6" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C182.26,237.6,182.8,238.14,182.8,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M182.8,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C182.26,230.42,182.8,230.95,182.8,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,332.19c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C167.89,330.99,168.43,331.52,168.43,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,325c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,323.81,168.43,324.34,168.43,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,317.82c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,316.62,168.43,317.16,168.43,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,310.63c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,309.44,168.43,309.97,168.43,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,303.45c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C167.89,302.25,168.43,302.79,168.43,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,296.27c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,295.07,168.43,295.61,168.43,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,289.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C167.89,287.89,168.43,288.42,168.43,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,281.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,280.7,168.43,281.24,168.43,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,274.71c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C167.89,273.52,168.43,274.05,168.43,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,266.34,168.43,266.87,168.43,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,259.15,168.43,259.69,168.43,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,251.97,168.43,252.5,168.43,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,245.98c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C167.89,244.78,168.43,245.32,168.43,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,237.6,168.43,238.14,168.43,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,230.42,168.43,230.95,168.43,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C167.89,223.23,168.43,223.77,168.43,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C167.89,216.05,168.43,216.58,168.43,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C167.89,208.86,168.43,209.4,168.43,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C167.89,201.68,168.43,202.22,168.43,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,194.5,168.43,195.03,168.43,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,188.51c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C167.89,187.31,168.43,187.85,168.43,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C167.89,180.13,168.43,180.66,168.43,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,172.95,168.43,173.48,168.43,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C167.89,165.76,168.43,166.3,168.43,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C167.89,158.58,168.43,159.11,168.43,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,152.59c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C167.89,151.39,168.43,151.93,168.43,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C167.89,144.21,168.43,144.75,168.43,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C167.89,137.03,168.43,137.56,168.43,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M168.43,131.04c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C167.89,129.84,168.43,130.38,168.43,131.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,321.41c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C171.49,320.21,172.02,320.75,172.02,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,314.23c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,313.03,172.02,313.57,172.02,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,307.04c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C171.49,305.85,172.02,306.38,172.02,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,299.86c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C171.49,298.66,172.02,299.2,172.02,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,292.67c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,291.48,172.02,292.01,172.02,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,285.49c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C171.49,284.29,172.02,284.83,172.02,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,278.31c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,277.11,172.02,277.65,172.02,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,271.12c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C171.49,269.93,172.02,270.46,172.02,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,263.94c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C171.49,262.74,172.02,263.28,172.02,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,256.76c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C171.49,255.56,172.02,256.09,172.02,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,249.57c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,248.38,172.02,248.91,172.02,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,242.39c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,241.19,172.02,241.73,172.02,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,235.2c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C171.49,234.01,172.02,234.54,172.02,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,228.02c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C171.49,226.82,172.02,227.36,172.02,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,220.84c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,219.64,172.02,220.18,172.02,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,213.65c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,212.46,172.02,212.99,172.02,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,206.47c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C171.49,205.27,172.02,205.81,172.02,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,199.29c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C171.49,198.09,172.02,198.62,172.02,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,192.1c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,190.91,172.02,191.44,172.02,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,184.92c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,183.72,172.02,184.26,172.02,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,177.73c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,176.54,172.02,177.07,172.02,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,170.55c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C171.49,169.35,172.02,169.89,172.02,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,163.37c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C171.49,162.17,172.02,162.71,172.02,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,156.18c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,154.99,172.02,155.52,172.02,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,149c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C171.49,147.8,172.02,148.34,172.02,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,141.81c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C171.49,140.62,172.02,141.15,172.02,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,134.63c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C171.49,133.43,172.02,133.97,172.02,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M172.02,127.45c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C171.49,126.25,172.02,126.79,172.02,127.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M170.82,327.4c0.32,0,0.6,0.12,0.81,0.32l-1.17,2c-0.48-0.16-0.83-0.6-0.83-1.13    C169.63,327.93,170.16,327.4,170.82,327.4z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M173.83,323.98l-0.6,1.02l-0.01,0.02c0-0.01,0-0.01,0-0.02    C173.22,324.56,173.47,324.18,173.83,323.98z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="339.37" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,332.19c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S161.25,331.52,161.25,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,325c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,324.34,161.25,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,317.82c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,317.16,161.25,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,310.63c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,309.97,161.25,310.63z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="303.45" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,295.61,161.25,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S161.25,288.42,161.25,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,281.24,161.25,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S161.25,274.05,161.25,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,266.87,161.25,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,259.69,161.25,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,252.5,161.25,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,238.14,161.25,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,230.95,161.25,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S161.25,223.77,161.25,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S161.25,216.58,161.25,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S161.25,202.22,161.25,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,195.69c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,195.03,161.25,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S161.25,180.66,161.25,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,174.14c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,173.48,161.25,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,166.96c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S161.25,166.3,161.25,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,159.77c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S161.25,159.11,161.25,159.77z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="152.59" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S161.25,144.75,161.25,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M161.25,138.22c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S161.25,137.56,161.25,138.22z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="160.05" cy="131.04" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M163.34,341.83l-0.84,1.42c-0.02-0.09-0.06-0.19-0.06-0.29    C162.44,342.41,162.83,341.96,163.34,341.83z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,335.78c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,335.12,164.84,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,328.59c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S164.84,327.93,164.84,328.59z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="321.41" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,313.57,164.84,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S164.84,306.38,164.84,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S164.84,299.2,164.84,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,292.01,164.84,292.67z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="285.49" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,277.65,164.84,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S164.84,270.46,164.84,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S164.84,256.09,164.84,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,248.91,164.84,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,241.73,164.84,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S164.84,234.54,164.84,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,220.18,164.84,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,212.99,164.84,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S164.84,198.62,164.84,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,191.44,164.84,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,184.26,164.84,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,177.07,164.84,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S164.84,162.71,164.84,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,155.52,164.84,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,149c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S164.84,148.34,164.84,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.84,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S164.84,141.15,164.84,141.81z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="163.64" cy="134.63" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M164.72,126.95c0.07,0.15,0.12,0.32,0.12,0.5c0,0.66-0.54,1.19-1.2,1.19    c-0.54,0-0.98-0.37-1.13-0.86L164.72,126.95z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,285.49c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C149.93,284.29,150.47,284.83,150.47,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,278.31c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,277.11,150.47,277.65,150.47,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,271.12c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C149.93,269.93,150.47,270.46,150.47,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,263.94c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C149.93,262.74,150.47,263.28,150.47,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C149.93,255.56,150.47,256.09,150.47,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,248.38,150.47,248.91,150.47,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,241.19,150.47,241.73,150.47,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C149.93,234.01,150.47,234.54,150.47,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,228.02c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C149.93,226.82,150.47,227.36,150.47,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,219.64,150.47,220.18,150.47,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,212.46,150.47,212.99,150.47,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,206.47c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C149.93,205.27,150.47,205.81,150.47,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C149.93,198.09,150.47,198.62,150.47,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,190.91,150.47,191.44,150.47,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,183.72,150.47,184.26,150.47,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,176.54,150.47,177.07,150.47,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,170.55c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C149.93,169.35,150.47,169.89,150.47,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C149.93,162.17,150.47,162.71,150.47,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,154.99,150.47,155.52,150.47,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,149c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,147.8,150.47,148.34,150.47,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,141.81c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,140.62,150.47,141.15,150.47,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,134.63c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C149.93,133.43,150.47,133.97,150.47,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,332.19c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C153.53,330.99,154.06,331.52,154.06,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,325c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,323.81,154.06,324.34,154.06,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,317.82c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,316.62,154.06,317.16,154.06,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,310.63c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,309.44,154.06,309.97,154.06,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,303.45c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C153.53,302.25,154.06,302.79,154.06,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,296.27c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,295.07,154.06,295.61,154.06,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,289.08c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C153.53,287.89,154.06,288.42,154.06,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,281.9c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,280.7,154.06,281.24,154.06,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,274.71c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C153.53,273.52,154.06,274.05,154.06,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,266.34,154.06,266.87,154.06,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,259.15,154.06,259.69,154.06,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,251.97,154.06,252.5,154.06,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C153.53,244.78,154.06,245.32,154.06,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,237.6,154.06,238.14,154.06,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,230.42,154.06,230.95,154.06,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C153.53,223.23,154.06,223.77,154.06,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C153.53,216.05,154.06,216.58,154.06,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C153.53,208.86,154.06,209.4,154.06,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,202.88c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C153.53,201.68,154.06,202.22,154.06,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,195.69c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,194.5,154.06,195.03,154.06,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,188.51c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C153.53,187.31,154.06,187.85,154.06,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,181.33c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C153.53,180.13,154.06,180.66,154.06,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,174.14c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,172.95,154.06,173.48,154.06,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,166.96c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C153.53,165.76,154.06,166.3,154.06,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,159.77c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C153.53,158.58,154.06,159.11,154.06,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,152.59c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C153.53,151.39,154.06,151.93,154.06,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,145.41c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C153.53,144.21,154.06,144.75,154.06,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.06,138.22c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C153.53,137.03,154.06,137.56,154.06,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M154.04,130.94c0,0.03,0.02,0.06,0.02,0.1c0,0.66-0.53,1.2-1.19,1.2c-0.4,0-0.74-0.21-0.96-0.51    l0.96-0.36l0.9-0.33L154.04,130.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,335.78c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,334.58,157.65,335.12,157.65,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,328.59c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C157.12,327.4,157.65,327.93,157.65,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,321.41c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C157.12,320.21,157.65,320.75,157.65,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,314.23c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,313.03,157.65,313.57,157.65,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,307.04c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C157.12,305.85,157.65,306.38,157.65,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,299.86c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C157.12,298.66,157.65,299.2,157.65,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,292.67c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,291.48,157.65,292.01,157.65,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,285.49c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C157.12,284.29,157.65,284.83,157.65,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,278.31c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,277.11,157.65,277.65,157.65,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C157.12,269.93,157.65,270.46,157.65,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C157.12,262.74,157.65,263.28,157.65,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C157.12,255.56,157.65,256.09,157.65,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,248.38,157.65,248.91,157.65,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,241.19,157.65,241.73,157.65,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C157.12,234.01,157.65,234.54,157.65,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C157.12,226.82,157.65,227.36,157.65,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,219.64,157.65,220.18,157.65,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,212.46,157.65,212.99,157.65,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C157.12,205.27,157.65,205.81,157.65,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C157.12,198.09,157.65,198.62,157.65,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,190.91,157.65,191.44,157.65,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,183.72,157.65,184.26,157.65,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,176.54,157.65,177.07,157.65,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C157.12,169.35,157.65,169.89,157.65,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C157.12,162.17,157.65,162.71,157.65,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,154.99,157.65,155.52,157.65,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C157.12,147.8,157.65,148.34,157.65,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,141.81c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C157.12,140.62,157.65,141.15,157.65,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M157.65,134.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C157.12,133.43,157.65,133.97,157.65,134.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,230.95,139.69,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S139.69,223.77,139.69,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S139.69,216.58,139.69,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S139.69,209.4,139.69,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S139.69,202.22,139.69,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,195.03,139.69,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S139.69,187.85,139.69,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S139.69,180.66,139.69,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,173.48,139.69,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S139.69,166.3,139.69,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,159.77c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S139.69,159.11,139.69,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,152.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S139.69,151.93,139.69,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,145.41c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S139.69,144.75,139.69,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,138.22c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,137.56,139.69,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,321.41c0,0.66-0.54,1.2-1.2,1.2c-0.29,0-0.55-0.12-0.76-0.29l-0.21-0.25    c-0.13-0.19-0.23-0.41-0.23-0.66c0-0.66,0.54-1.2,1.2-1.2S143.29,320.75,143.29,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,313.57,143.29,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S143.29,306.38,143.29,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S143.29,299.2,143.29,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,292.01,143.29,292.67z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="142.09" cy="285.49" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,277.65,143.29,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S143.29,270.46,143.29,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="142.09" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S143.29,256.09,143.29,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,248.91,143.29,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,241.73,143.29,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S143.29,234.54,143.29,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="142.09" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,220.18,143.29,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,212.99,143.29,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="142.09" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S143.29,198.62,143.29,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,191.44,143.29,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,184.26,143.29,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,177.07,143.29,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="142.09" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S143.29,162.71,143.29,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,155.52,143.29,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,149c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S143.29,148.34,143.29,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M143.29,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S143.29,141.15,143.29,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M142.09,135.83c-0.24,0-0.44-0.09-0.63-0.2l1.76-0.66C143.07,135.46,142.63,135.83,142.09,135.83z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,325c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,323.81,146.88,324.34,146.88,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,317.82c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,316.62,146.88,317.16,146.88,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,310.63c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,309.44,146.88,309.97,146.88,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,303.45c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C146.34,302.25,146.88,302.79,146.88,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,296.27c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,295.07,146.88,295.61,146.88,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,289.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C146.34,287.89,146.88,288.42,146.88,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,281.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,280.7,146.88,281.24,146.88,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,274.71c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C146.34,273.52,146.88,274.05,146.88,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,266.34,146.88,266.87,146.88,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,259.15,146.88,259.69,146.88,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,251.97,146.88,252.5,146.88,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,245.98c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C146.34,244.78,146.88,245.32,146.88,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,237.6,146.88,238.14,146.88,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,230.42,146.88,230.95,146.88,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C146.34,223.23,146.88,223.77,146.88,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C146.34,216.05,146.88,216.58,146.88,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C146.34,208.86,146.88,209.4,146.88,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C146.34,201.68,146.88,202.22,146.88,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,194.5,146.88,195.03,146.88,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,188.51c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C146.34,187.31,146.88,187.85,146.88,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C146.34,180.13,146.88,180.66,146.88,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,172.95,146.88,173.48,146.88,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C146.34,165.76,146.88,166.3,146.88,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C146.34,158.58,146.88,159.11,146.88,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,152.59c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C146.34,151.39,146.88,151.93,146.88,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C146.34,144.21,146.88,144.75,146.88,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M146.88,138.22c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C146.34,137.03,146.88,137.56,146.88,138.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,328.59c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C149.93,327.4,150.47,327.93,150.47,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,321.41c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C149.93,320.21,150.47,320.75,150.47,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,314.23c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C149.93,313.03,150.47,313.57,150.47,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,307.04c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C149.93,305.85,150.47,306.38,150.47,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,299.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C149.93,298.66,150.47,299.2,150.47,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M150.47,292.67c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C149.93,291.48,150.47,292.01,150.47,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.07,139.13c-0.2,0.17-0.46,0.29-0.76,0.29v-0.01L132.07,139.13z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,310.63c0,0.41-0.22,0.75-0.53,0.97l-0.67-0.77l-0.17-0.2l-0.7-0.8    c0.22-0.24,0.53-0.39,0.87-0.39C131.97,309.44,132.51,309.97,132.51,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,303.45c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S132.51,302.79,132.51,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S132.51,295.61,132.51,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S132.51,288.42,132.51,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S132.51,281.24,132.51,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S132.51,274.05,132.51,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S132.51,266.87,132.51,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S132.51,259.69,132.51,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S132.51,252.5,132.51,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S132.51,245.32,132.51,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S132.51,238.14,132.51,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S132.51,230.95,132.51,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S132.51,223.77,132.51,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S132.51,216.58,132.51,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S132.51,209.4,132.51,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S132.51,202.22,132.51,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,195.69c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S132.51,195.03,132.51,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,188.51c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S132.51,187.85,132.51,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S132.51,180.66,132.51,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,174.14c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S132.51,173.48,132.51,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,166.96c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S132.51,166.3,132.51,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,159.77c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S132.51,159.11,132.51,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,152.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S132.51,151.93,132.51,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M132.51,145.41c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S132.51,144.75,132.51,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,307.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S136.1,306.38,136.1,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,299.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S136.1,299.2,136.1,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,292.67c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,292.01,136.1,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,285.49c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S136.1,284.83,136.1,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,278.31c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S136.1,277.65,136.1,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S136.1,270.46,136.1,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S136.1,263.28,136.1,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S136.1,256.09,136.1,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,248.91,136.1,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S136.1,241.73,136.1,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S136.1,234.54,136.1,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S136.1,227.36,136.1,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S136.1,220.18,136.1,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,212.99,136.1,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,206.47c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S136.1,205.81,136.1,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,199.29c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S136.1,198.62,136.1,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,192.1c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,191.44,136.1,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,184.92c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S136.1,184.26,136.1,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,177.73c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,177.07,136.1,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,170.55c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S136.1,169.89,136.1,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,163.37c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S136.1,162.71,136.1,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,156.18c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,155.52,136.1,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,149c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S136.1,148.34,136.1,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,141.81c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S136.1,141.15,136.1,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M136.1,314.23c0,0.53-0.36,0.97-0.84,1.12l-1.43-1.63c0.19-0.41,0.6-0.69,1.08-0.69    C135.57,313.03,136.1,313.57,136.1,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,317.82c0,0.66-0.53,1.19-1.19,1.19c-0.02,0-0.04-0.01-0.06-0.01l-1.03-1.18l-0.09-0.1    c0.05-0.61,0.55-1.1,1.18-1.1C139.16,316.62,139.69,317.16,139.69,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,310.63c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,309.97,139.69,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,303.45c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S139.69,302.79,139.69,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,296.27c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S139.69,295.61,139.69,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,289.08c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S139.69,288.42,139.69,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S139.69,281.24,139.69,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S139.69,274.05,139.69,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,266.87,139.69,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S139.69,259.69,139.69,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S139.69,252.5,139.69,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S139.69,245.32,139.69,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M139.69,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S139.69,238.14,139.69,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,159.77c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C117.61,158.58,118.14,159.11,118.14,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,152.59c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C117.61,151.39,118.14,151.93,118.14,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,145.41c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.08,0.03-0.14,0.04-0.21    l1.16-0.43l0.74-0.28C117.96,144.71,118.14,145.03,118.14,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,292.67c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,291.48,121.73,292.01,121.73,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,285.49c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C121.2,284.29,121.73,284.83,121.73,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,278.31c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C121.2,277.11,121.73,277.65,121.73,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C121.2,269.93,121.73,270.46,121.73,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C121.2,262.74,121.73,263.28,121.73,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C121.2,255.56,121.73,256.09,121.73,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,248.38,121.73,248.91,121.73,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C121.2,241.19,121.73,241.73,121.73,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C121.2,234.01,121.73,234.54,121.73,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C121.2,226.82,121.73,227.36,121.73,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C121.2,219.64,121.73,220.18,121.73,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,212.46,121.73,212.99,121.73,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C121.2,205.27,121.73,205.81,121.73,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C121.2,198.09,121.73,198.62,121.73,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,190.91,121.73,191.44,121.73,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C121.2,183.72,121.73,184.26,121.73,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,176.54,121.73,177.07,121.73,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C121.2,169.35,121.73,169.89,121.73,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C121.2,162.17,121.73,162.71,121.73,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C121.2,154.99,121.73,155.52,121.73,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,149c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C121.2,147.8,121.73,148.34,121.73,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M121.73,299.85l-1-1.15C121.29,298.79,121.73,299.26,121.73,299.85z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,303.45c0,0.15-0.04,0.29-0.09,0.42l-0.37-0.42l-0.74-0.85l-0.26-0.29    c0.09-0.02,0.17-0.06,0.26-0.06C124.79,302.25,125.33,302.79,125.33,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,296.27c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C124.79,295.07,125.33,295.61,125.33,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,289.08c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C124.79,287.89,125.33,288.42,125.33,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,281.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C124.79,280.7,125.33,281.24,125.33,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,274.71c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C124.79,273.52,125.33,274.05,125.33,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C124.79,266.34,125.33,266.87,125.33,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C124.79,259.15,125.33,259.69,125.33,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C124.79,251.97,125.33,252.5,125.33,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="124.13" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C124.79,237.6,125.33,238.14,125.33,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C124.79,230.42,125.33,230.95,125.33,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C124.79,223.23,125.33,223.77,125.33,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C124.79,216.05,125.33,216.58,125.33,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="124.13" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C124.79,201.68,125.33,202.22,125.33,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C124.79,194.5,125.33,195.03,125.33,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="124.13" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C124.79,180.13,125.33,180.66,125.33,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C124.79,172.95,125.33,173.48,125.33,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C124.79,165.76,125.33,166.3,125.33,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C124.79,158.58,125.33,159.11,125.33,159.77z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="124.13" cy="152.59" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M125.33,145.41c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C124.79,144.21,125.33,144.75,125.33,145.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S128.92,299.2,128.92,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,292.01,128.92,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S128.92,284.83,128.92,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S128.92,277.65,128.92,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S128.92,270.46,128.92,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S128.92,263.28,128.92,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S128.92,256.09,128.92,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,248.91,128.92,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S128.92,241.73,128.92,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S128.92,234.54,128.92,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S128.92,227.36,128.92,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S128.92,220.18,128.92,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,212.99,128.92,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,206.47c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S128.92,205.81,128.92,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S128.92,198.62,128.92,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,191.44,128.92,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S128.92,184.26,128.92,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,177.07,128.92,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S128.92,169.89,128.92,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S128.92,162.71,128.92,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,156.18c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S128.92,155.52,128.92,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,149c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S128.92,148.34,128.92,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,141.81c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.27,0.1-0.51,0.26-0.71l1.16-0.44    C128.5,140.77,128.92,141.23,128.92,141.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M128.92,307.04c0,0.28-0.11,0.53-0.28,0.73l-1.52-1.74c0.18-0.11,0.38-0.18,0.6-0.18    C128.38,305.85,128.92,306.38,128.92,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,281.9c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C110.42,280.7,110.96,281.24,110.96,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,274.71c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C110.42,273.52,110.96,274.05,110.96,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,267.53c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C110.42,266.34,110.96,266.87,110.96,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C110.42,259.15,110.96,259.69,110.96,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C110.42,251.97,110.96,252.5,110.96,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,245.98c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C110.42,244.78,110.96,245.32,110.96,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C110.42,237.6,110.96,238.14,110.96,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C110.42,230.42,110.96,230.95,110.96,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C110.42,223.23,110.96,223.77,110.96,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C110.42,216.05,110.96,216.58,110.96,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C110.42,208.86,110.96,209.4,110.96,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C110.42,201.68,110.96,202.22,110.96,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C110.42,194.5,110.96,195.03,110.96,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,188.51c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C110.42,187.31,110.96,187.85,110.96,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C110.42,180.13,110.96,180.66,110.96,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C110.42,172.95,110.96,173.48,110.96,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C110.42,165.76,110.96,166.3,110.96,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C110.42,158.58,110.96,159.11,110.96,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M110.96,152.59c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C110.42,151.39,110.96,151.93,110.96,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,285.49c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C114.01,284.29,114.55,284.83,114.55,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,278.31c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C114.01,277.11,114.55,277.65,114.55,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,271.12c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C114.01,269.93,114.55,270.46,114.55,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,263.94c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C114.01,262.74,114.55,263.28,114.55,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C114.01,255.56,114.55,256.09,114.55,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C114.01,248.38,114.55,248.91,114.55,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C114.01,241.19,114.55,241.73,114.55,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C114.01,234.01,114.55,234.54,114.55,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,228.02c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C114.01,226.82,114.55,227.36,114.55,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C114.01,219.64,114.55,220.18,114.55,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C114.01,212.46,114.55,212.99,114.55,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,206.47c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C114.01,205.27,114.55,205.81,114.55,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C114.01,198.09,114.55,198.62,114.55,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C114.01,190.91,114.55,191.44,114.55,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C114.01,183.72,114.55,184.26,114.55,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C114.01,176.54,114.55,177.07,114.55,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,170.55c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C114.01,169.35,114.55,169.89,114.55,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C114.01,162.17,114.55,162.71,114.55,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C114.01,154.99,114.55,155.52,114.55,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M114.55,149c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C114.01,147.8,114.55,148.34,114.55,149z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,289.08c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C117.61,287.89,118.14,288.42,118.14,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,281.9c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C117.61,280.7,118.14,281.24,118.14,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,274.71c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C117.61,273.52,118.14,274.05,118.14,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C117.61,266.34,118.14,266.87,118.14,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C117.61,259.15,118.14,259.69,118.14,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C117.61,251.97,118.14,252.5,118.14,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C117.61,244.78,118.14,245.32,118.14,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C117.61,237.6,118.14,238.14,118.14,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C117.61,230.42,118.14,230.95,118.14,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C117.61,223.23,118.14,223.77,118.14,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C117.61,216.05,118.14,216.58,118.14,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C117.61,208.86,118.14,209.4,118.14,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,202.88c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C117.61,201.68,118.14,202.22,118.14,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,195.69c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C117.61,194.5,118.14,195.03,118.14,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,188.51c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C117.61,187.31,118.14,187.85,118.14,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,181.33c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C117.61,180.13,118.14,180.66,118.14,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,174.14c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C117.61,172.95,118.14,173.48,118.14,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M118.14,166.96c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C117.61,165.76,118.14,166.3,118.14,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,267.53c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C96.06,266.34,96.59,266.87,96.59,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,260.35c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C96.06,259.15,96.59,259.69,96.59,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,253.16c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C96.06,251.97,96.59,252.5,96.59,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,245.98c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C96.06,244.78,96.59,245.32,96.59,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,238.8c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C96.06,237.6,96.59,238.14,96.59,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,231.61c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C96.06,230.42,96.59,230.95,96.59,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,224.43c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C96.06,223.23,96.59,223.77,96.59,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,217.24c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C96.06,216.05,96.59,216.58,96.59,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,210.06c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C96.06,208.86,96.59,209.4,96.59,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,202.88c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C96.06,201.68,96.59,202.22,96.59,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,195.69c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C96.06,194.5,96.59,195.03,96.59,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,188.51c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C96.06,187.31,96.59,187.85,96.59,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,181.33c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C96.06,180.13,96.59,180.66,96.59,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,174.14c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C96.06,172.95,96.59,173.48,96.59,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,166.96c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C96.06,165.76,96.59,166.3,96.59,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.59,159.77c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C96.06,158.58,96.59,159.11,96.59,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M96.55,152.37c0.01,0.08,0.04,0.14,0.04,0.22c0,0.66-0.53,1.2-1.2,1.2c-0.44,0-0.81-0.25-1.01-0.61    l1.01-0.38l0.57-0.21L96.55,152.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C99.65,269.93,100.18,270.46,100.18,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C99.65,262.74,100.18,263.28,100.18,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C99.65,255.56,100.18,256.09,100.18,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C99.65,248.38,100.18,248.91,100.18,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C99.65,241.19,100.18,241.73,100.18,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C99.65,234.01,100.18,234.54,100.18,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C99.65,226.82,100.18,227.36,100.18,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C99.65,219.64,100.18,220.18,100.18,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C99.65,212.46,100.18,212.99,100.18,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C99.65,205.27,100.18,205.81,100.18,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C99.65,198.09,100.18,198.62,100.18,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C99.65,190.91,100.18,191.44,100.18,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C99.65,183.72,100.18,184.26,100.18,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C99.65,176.54,100.18,177.07,100.18,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C99.65,169.35,100.18,169.89,100.18,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C99.65,162.17,100.18,162.71,100.18,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M100.18,156.18c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C99.65,154.99,100.18,155.52,100.18,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,274.71c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C103.24,273.52,103.77,274.05,103.77,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C103.24,266.34,103.77,266.87,103.77,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C103.24,259.15,103.77,259.69,103.77,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C103.24,251.97,103.77,252.5,103.77,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C103.24,244.78,103.77,245.32,103.77,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C103.24,237.6,103.77,238.14,103.77,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C103.24,230.42,103.77,230.95,103.77,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C103.24,223.23,103.77,223.77,103.77,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C103.24,216.05,103.77,216.58,103.77,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C103.24,208.86,103.77,209.4,103.77,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,202.88c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C103.24,201.68,103.77,202.22,103.77,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,195.69c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C103.24,194.5,103.77,195.03,103.77,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,188.51c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C103.24,187.31,103.77,187.85,103.77,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,181.33c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C103.24,180.13,103.77,180.66,103.77,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,174.14c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C103.24,172.95,103.77,173.48,103.77,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,166.96c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C103.24,165.76,103.77,166.3,103.77,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,159.77c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C103.24,158.58,103.77,159.11,103.77,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M103.77,152.59c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C103.24,151.39,103.77,151.93,103.77,152.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,278.31c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C106.83,277.11,107.37,277.65,107.37,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,271.12c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C106.83,269.93,107.37,270.46,107.37,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="106.17" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C106.83,255.56,107.37,256.09,107.37,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C106.83,248.38,107.37,248.91,107.37,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C106.83,241.19,107.37,241.73,107.37,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C106.83,234.01,107.37,234.54,107.37,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="106.17" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C106.83,219.64,107.37,220.18,107.37,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C106.83,212.46,107.37,212.99,107.37,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="106.17" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C106.83,198.09,107.37,198.62,107.37,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C106.83,190.91,107.37,191.44,107.37,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C106.83,183.72,107.37,184.26,107.37,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C106.83,176.54,107.37,177.07,107.37,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="106.17" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C106.83,162.17,107.37,162.71,107.37,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.37,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C106.83,154.99,107.37,155.52,107.37,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M107.19,148.41c0.1,0.17,0.18,0.37,0.18,0.59c0,0.66-0.54,1.19-1.2,1.19    c-0.58,0-1.05-0.42-1.15-0.98L107.19,148.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C78.1,241.19,78.63,241.73,78.63,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C78.1,234.01,78.63,234.54,78.63,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C78.1,226.82,78.63,227.36,78.63,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C78.1,219.64,78.63,220.18,78.63,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,213.65c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C78.1,212.46,78.63,212.99,78.63,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C78.1,205.27,78.63,205.81,78.63,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,199.29c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C78.1,198.09,78.63,198.62,78.63,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,192.1c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C78.1,190.91,78.63,191.44,78.63,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,184.92c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C78.1,183.72,78.63,184.26,78.63,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,177.73c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C78.1,176.54,78.63,177.07,78.63,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,170.55c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C78.1,169.35,78.63,169.89,78.63,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,163.37c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C78.1,162.17,78.63,162.71,78.63,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M78.63,249.57c0,0.24-0.09,0.45-0.21,0.64l-1.49-1.71c0.15-0.08,0.32-0.12,0.51-0.12    C78.1,248.38,78.63,248.91,78.63,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,253.16c0,0.37-0.17,0.69-0.44,0.91l-0.75-0.87l-0.04-0.04l-0.76-0.87    c0.21-0.2,0.49-0.32,0.8-0.32C81.69,251.97,82.22,252.5,82.22,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C81.69,244.78,82.22,245.32,82.22,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C81.69,237.6,82.22,238.14,82.22,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C81.69,230.42,82.22,230.95,82.22,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C81.69,223.23,82.22,223.77,82.22,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C81.69,216.05,82.22,216.58,82.22,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C81.69,208.86,82.22,209.4,82.22,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,202.88c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C81.69,201.68,82.22,202.22,82.22,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,195.69c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C81.69,194.5,82.22,195.03,82.22,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,188.51c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C81.69,187.31,82.22,187.85,82.22,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,181.33c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C81.69,180.13,82.22,180.66,82.22,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,174.14c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C81.69,172.95,82.22,173.48,82.22,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,166.96c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C81.69,165.76,82.22,166.3,82.22,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M82.22,159.77c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C81.69,158.58,82.22,159.11,82.22,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C85.28,248.38,85.82,248.91,85.82,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C85.28,241.19,85.82,241.73,85.82,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C85.28,234.01,85.82,234.54,85.82,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="84.62" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C85.28,219.64,85.82,220.18,85.82,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C85.28,212.46,85.82,212.99,85.82,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="84.62" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C85.28,198.09,85.82,198.62,85.82,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C85.28,190.91,85.82,191.44,85.82,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C85.28,183.72,85.82,184.26,85.82,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C85.28,176.54,85.82,177.07,85.82,177.73z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="84.62" cy="170.55" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C85.28,162.17,85.82,162.71,85.82,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M85.82,256.76c0,0.49-0.3,0.91-0.73,1.1l-1.48-1.7c0.21-0.35,0.57-0.6,1.01-0.6    C85.28,255.56,85.82,256.09,85.82,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M84.62,157.38c-0.29,0-0.54-0.12-0.75-0.28l1.9-0.71C85.67,156.95,85.21,157.38,84.62,157.38z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,260.35c0,0.63-0.5,1.13-1.12,1.18l-0.08-0.1l-0.95-1.08l-0.2-0.23    c0.11-0.55,0.57-0.97,1.15-0.97C88.87,259.15,89.41,259.69,89.41,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,253.16c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C88.87,251.97,89.41,252.5,89.41,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="88.21" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C88.87,237.6,89.41,238.14,89.41,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C88.87,230.42,89.41,230.95,89.41,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C88.87,223.23,89.41,223.77,89.41,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C88.87,216.05,89.41,216.58,89.41,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="88.21" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C88.87,201.68,89.41,202.22,89.41,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C88.87,194.5,89.41,195.03,89.41,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="88.21" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C88.87,180.13,89.41,180.66,89.41,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C88.87,172.95,89.41,173.48,89.41,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C88.87,165.76,89.41,166.3,89.41,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M89.41,159.77c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C88.87,158.58,89.41,159.11,89.41,159.77z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,263.94c0,0.66-0.54,1.2-1.2,1.2c-0.15,0-0.3-0.04-0.44-0.09l-0.69-0.8    c-0.03-0.1-0.06-0.2-0.06-0.31c0-0.66,0.53-1.2,1.19-1.2C92.46,262.74,93,263.28,93,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C92.46,255.56,93,256.09,93,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C92.46,248.38,93,248.91,93,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C92.46,241.19,93,241.73,93,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C92.46,234.01,93,234.54,93,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,228.02c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C92.46,226.82,93,227.36,93,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C92.46,219.64,93,220.18,93,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C92.46,212.46,93,212.99,93,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,206.47c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C92.46,205.27,93,205.81,93,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,199.29c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C92.46,198.09,93,198.62,93,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C92.46,190.91,93,191.44,93,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C92.46,183.72,93,184.26,93,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C92.46,176.54,93,177.07,93,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,170.55c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C92.46,169.35,93,169.89,93,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,163.37c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C92.46,162.17,93,162.71,93,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M93,156.18c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C92.46,154.99,93,155.52,93,156.18z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S53.49,216.58,53.49,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S53.49,209.4,53.49,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S53.49,202.22,53.49,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,195.69c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S53.49,195.03,53.49,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,188.51c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S53.49,187.85,53.49,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S53.49,180.66,53.49,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M53.49,174.14c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S53.49,173.48,53.49,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S57.08,220.18,57.08,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S57.08,212.99,57.08,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,206.47c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S57.08,205.81,57.08,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S57.08,198.62,57.08,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S57.08,191.44,57.08,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S57.08,184.26,57.08,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S57.08,177.07,57.08,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M57.08,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S57.08,169.89,57.08,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S60.67,223.77,60.67,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S60.67,216.58,60.67,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S60.67,209.4,60.67,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S60.67,202.22,60.67,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S60.67,195.03,60.67,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S60.67,187.85,60.67,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S60.67,180.66,60.67,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S60.67,173.48,60.67,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M60.67,166.96c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.12,0.04-0.24,0.07-0.35    l1.13-0.42l0.62-0.23C60.44,166.17,60.67,166.53,60.67,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S64.26,227.36,64.26,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S64.26,220.18,64.26,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S64.26,212.99,64.26,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,206.47c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S64.26,205.81,64.26,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,199.29c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S64.26,198.62,64.26,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,192.1c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S64.26,191.44,64.26,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,184.92c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S64.26,184.26,64.26,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,177.73c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S64.26,177.07,64.26,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M64.26,170.55c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S64.26,169.89,64.26,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C67.32,230.42,67.86,230.95,67.86,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C67.32,223.23,67.86,223.77,67.86,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C67.32,216.05,67.86,216.58,67.86,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="66.66" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C67.32,201.68,67.86,202.22,67.86,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C67.32,194.5,67.86,195.03,67.86,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="66.66" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C67.32,180.13,67.86,180.66,67.86,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C67.32,172.95,67.86,173.48,67.86,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M67.86,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C67.32,165.76,67.86,166.3,67.86,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S71.45,234.54,71.45,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S71.45,227.36,71.45,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S71.45,220.18,71.45,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S71.45,212.99,71.45,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,206.47c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S71.45,205.81,71.45,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S71.45,198.62,71.45,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S71.45,191.44,71.45,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S71.45,184.26,71.45,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S71.45,177.07,71.45,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,170.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S71.45,169.89,71.45,170.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.45,163.37c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.38,0.18-0.7,0.44-0.91    l0.76-0.29C70.92,162.18,71.45,162.71,71.45,163.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M71.4,242.17l-0.79-0.91C71.02,241.4,71.32,241.74,71.4,242.17z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M73.84,160.97c-0.08,0-0.15-0.03-0.23-0.05l0.23-0.08l0.94-0.35    C74.56,160.77,74.23,160.97,73.84,160.97z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,245.98c0,0.1-0.03,0.2-0.06,0.29l-0.25-0.29l-0.89-1.01l-0.13-0.16    c0.04,0,0.09-0.03,0.13-0.03C74.5,244.78,75.04,245.32,75.04,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C74.5,237.6,75.04,238.14,75.04,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C74.5,230.42,75.04,230.95,75.04,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C74.5,223.23,75.04,223.77,75.04,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C74.5,216.05,75.04,216.58,75.04,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C74.5,208.86,75.04,209.4,75.04,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,202.88c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C74.5,201.68,75.04,202.22,75.04,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,195.69c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C74.5,194.5,75.04,195.03,75.04,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,188.51c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C74.5,187.31,75.04,187.85,75.04,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,181.33c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C74.5,180.13,75.04,180.66,75.04,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,174.14c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C74.5,172.95,75.04,173.48,75.04,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M75.04,166.96c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C74.5,165.76,75.04,166.3,75.04,166.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M21.08,184.49l-0.52-0.58C20.79,184.04,20.98,184.24,21.08,184.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M24.75,188.51c0,0.06-0.02,0.1-0.03,0.16l-0.14-0.16l-1.02-1.17l-0.02-0.02    c0.01,0,0.01-0.01,0.02-0.01C24.22,187.31,24.75,187.85,24.75,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M24.75,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S24.75,180.66,24.75,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M28.34,184.92c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S28.34,184.26,28.34,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M28.34,192.1c0,0.2-0.06,0.38-0.15,0.55l-1.45-1.66c0.13-0.05,0.26-0.08,0.41-0.08    C27.81,190.91,28.34,191.44,28.34,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M27.15,178.93c-0.34,0-0.64-0.14-0.86-0.37l2.04-0.75C28.29,178.43,27.78,178.93,27.15,178.93z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M31.94,195.69c0,0.33-0.14,0.63-0.35,0.85l-0.74-0.85l-0.11-0.12l-0.71-0.81    c0.2-0.16,0.44-0.26,0.71-0.26C31.4,194.5,31.94,195.03,31.94,195.69z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="30.74" cy="188.51" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M31.94,181.33c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S31.94,180.66,31.94,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M35.53,192.1c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C34.99,190.91,35.53,191.44,35.53,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M35.53,184.92c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C34.99,183.72,35.53,184.26,35.53,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M35.53,177.73c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C34.99,176.54,35.53,177.07,35.53,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M35.53,199.29c0,0.45-0.26,0.83-0.64,1.03l-1.51-1.72c0.22-0.3,0.55-0.51,0.95-0.51    C34.99,198.09,35.53,198.62,35.53,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M39.12,202.88c0,0.59-0.43,1.05-0.99,1.15l-0.21-0.23l-0.8-0.92l-0.32-0.37    c0.16-0.48,0.59-0.83,1.12-0.83C38.59,201.68,39.12,202.22,39.12,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M39.12,195.69c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C38.59,194.5,39.12,195.03,39.12,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M39.12,188.51c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C38.59,187.31,39.12,187.85,39.12,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M39.12,181.33c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C38.59,180.13,39.12,180.66,39.12,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M39.05,173.81c0.04,0.11,0.07,0.21,0.07,0.33c0,0.66-0.53,1.2-1.2,1.2c-0.48,0-0.89-0.29-1.08-0.71    l1.08-0.4l0.24-0.09L39.05,173.81z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M42.71,206.47c0,0.66-0.53,1.2-1.19,1.2c-0.1,0-0.18-0.04-0.27-0.06l-0.91-1.04    c0-0.03-0.02-0.06-0.02-0.1c0-0.66,0.54-1.2,1.2-1.2S42.71,205.81,42.71,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M42.71,199.29c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S42.71,198.62,42.71,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M42.71,192.1c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S42.71,191.44,42.71,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M42.71,184.92c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S42.71,184.26,42.71,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M42.71,177.73c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S42.71,177.07,42.71,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2S46.3,209.4,46.3,210.06    z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,202.88c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S46.3,202.22,46.3,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,195.69c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S46.3,195.03,46.3,195.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,188.51c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S46.3,187.85,46.3,188.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,181.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S46.3,180.66,46.3,181.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M46.3,174.14c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S46.3,173.48,46.3,174.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.9,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S49.9,212.99,49.9,213.65z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="48.7" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.9,199.29c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S49.9,198.62,49.9,199.29z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.9,192.1c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S49.9,191.44,49.9,192.1z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.9,184.92c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S49.9,184.26,49.9,184.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.9,177.73c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S49.9,177.07,49.9,177.73z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M49.65,169.86c0.14,0.19,0.25,0.43,0.25,0.69c0,0.66-0.54,1.2-1.2,1.2c-0.63,0-1.12-0.49-1.18-1.1    L49.65,169.86z"
          />
        </g>
        <g style={{ display: "inline" }}>
          <path
            style={{ fill: "#DBDBDB" }}
            d="M415.866,334.895l0.145,0.171C415.964,335.006,415.922,334.946,415.866,334.895z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.376,288.215l0.088,0.104C376.434,288.283,376.41,288.246,376.376,288.215z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M336.886,241.534l0.031,0.036C336.906,241.559,336.898,241.546,336.886,241.534z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C347.49,244.78,348.02,245.32,348.02,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,253.16c0,0.4-0.2,0.73-0.5,0.95l-0.69-0.82l-0.11-0.13l-0.72-0.85    c0.21-0.21,0.5-0.34,0.83-0.34C347.49,251.97,348.02,252.5,348.02,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,249.57c0,0.24-0.09,0.45-0.21,0.64l-1.46-1.73c0.15-0.07,0.31-0.1,0.48-0.1    C343.9,248.38,344.43,248.91,344.43,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,206.47c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S344.43,205.81,344.43,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S344.43,212.99,344.43,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S344.43,220.18,344.43,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M333.66,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S333.66,223.77,333.66,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M326.41,202.55c0.03,0.1,0.06,0.21,0.06,0.33c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19    c0-0.24,0.09-0.44,0.2-0.63l1,0.14L326.41,202.55z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="314.5" cy="206.47" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M330.06,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S330.06,212.99,330.06,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M312.11,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S312.11,202.22,312.11,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M319.29,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S319.29,216.58,319.29,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M330.06,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S330.06,227.36,330.06,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M307.32,200.48c-0.49,0-0.91-0.3-1.1-0.72l2.01,0.28C308.01,200.3,307.69,200.48,307.32,200.48z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S344.43,241.73,344.43,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M331.33,203.23l1.13,0.15l0.99,0.14c-0.22,0.32-0.57,0.55-0.99,0.55    C331.92,204.07,331.49,203.71,331.33,203.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M319.29,202.88c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S319.29,202.22,319.29,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M337.25,228.02c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C336.71,226.82,337.25,227.36,337.25,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M315.7,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.08,0-0.15-0.03-0.23-0.05l-0.96-1.14    c0,0-0.01,0-0.01-0.01c0-0.66,0.54-1.19,1.2-1.19S315.7,212.99,315.7,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M326.47,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S326.47,223.77,326.47,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S344.43,227.36,344.43,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C340.3,230.42,340.84,230.95,340.84,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M308.51,206.47c0,0.39-0.2,0.72-0.49,0.94l-1.53-1.8c0.22-0.21,0.51-0.34,0.83-0.34    C307.98,205.27,308.51,205.81,308.51,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M304.92,202.88c0,0.23-0.09,0.44-0.2,0.63l-0.54-0.63l-0.45-0.55l-0.48-0.55    c0.15-0.06,0.31-0.1,0.48-0.1C304.39,201.68,304.92,202.22,304.92,202.88z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M322.88,213.65c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C322.35,212.46,322.88,212.99,322.88,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M312.11,210.06c0,0.55-0.38,0.99-0.89,1.13l-0.31-0.36l-0.65-0.77l-0.43-0.5    c0.19-0.41,0.6-0.7,1.08-0.7C311.57,208.86,312.11,209.4,312.11,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M337.25,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C336.71,219.64,337.25,220.18,337.25,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M319.29,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S319.29,209.4,319.29,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M326.47,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S326.47,216.58,326.47,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,217.24c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C340.3,216.05,340.84,216.58,340.84,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M337.25,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C336.71,234.01,337.25,234.54,337.25,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M337.25,206.47c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C336.71,205.27,337.25,205.81,337.25,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M326.47,210.06c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S326.47,209.4,326.47,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M301.29,199.08c0.01,0.07,0.04,0.13,0.04,0.21c0,0.06-0.03,0.11-0.04,0.17l-0.36-0.43    L301.29,199.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M322.88,206.47c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C322.35,205.27,322.88,205.81,322.88,206.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M344.43,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S344.43,234.54,344.43,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M330.06,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S330.06,220.18,330.06,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M337.25,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C336.71,212.46,337.25,212.99,337.25,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,210.06c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C340.3,208.86,340.84,209.4,340.84,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M333.66,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S333.66,216.58,333.66,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M333.66,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S333.66,230.95,333.66,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C340.3,237.6,340.84,238.14,340.84,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C340.3,223.23,340.84,223.77,340.84,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M330.06,206.47c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S330.06,205.81,330.06,206.47z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="332.46" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M322.88,220.84c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C322.35,219.64,322.88,220.18,322.88,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M340.84,245.98c0,0.07-0.03,0.12-0.04,0.18l-0.15-0.18l-1.01-1.19v-0.01    C340.3,244.78,340.84,245.32,340.84,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C347.49,237.6,348.02,238.14,348.02,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C347.49,230.42,348.02,230.95,348.02,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C347.49,223.23,348.02,223.77,348.02,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C347.49,216.05,348.02,216.58,348.02,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M348.02,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    C347.49,208.86,348.02,209.4,348.02,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C351.08,248.38,351.62,248.91,351.62,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C351.08,241.19,351.62,241.73,351.62,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C351.08,234.01,351.62,234.54,351.62,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="350.42" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C351.08,219.64,351.62,220.18,351.62,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,213.65c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C351.08,212.46,351.62,212.99,351.62,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.62,256.76c0,0.55-0.39,0.99-0.9,1.13l-1.38-1.63c0.19-0.41,0.6-0.7,1.08-0.7    C351.08,255.56,351.62,256.09,351.62,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M351.52,206.01c0.06,0.14,0.1,0.3,0.1,0.46c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2    c0-0.28,0.12-0.53,0.28-0.74L351.52,206.01z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,260.35c0,0.66-0.54,1.19-1.2,1.19c-0.08,0-0.16-0.03-0.24-0.04l-0.95-1.13    c0-0.01,0-0.01,0-0.02c0-0.66,0.53-1.2,1.19-1.2S355.21,259.69,355.21,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S355.21,252.5,355.21,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S355.21,245.32,355.21,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S355.21,238.14,355.21,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S355.21,230.95,355.21,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S355.21,223.77,355.21,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S355.21,216.58,355.21,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M355.21,210.06c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S355.21,209.4,355.21,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S358.8,263.28,358.8,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S358.8,256.09,358.8,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S358.8,248.91,358.8,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S358.8,241.73,358.8,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S358.8,234.54,358.8,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S358.8,227.36,358.8,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S358.8,220.18,358.8,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M358.8,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S358.8,212.99,358.8,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M356.45,206.69l2.21,0.31c-0.19,0.39-0.59,0.67-1.06,0.67    C357.02,207.67,356.56,207.24,356.45,206.69z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C361.86,266.34,362.39,266.87,362.39,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C361.86,259.15,362.39,259.69,362.39,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C361.86,251.97,362.39,252.5,362.39,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C361.86,244.78,362.39,245.32,362.39,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C361.86,237.6,362.39,238.14,362.39,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C361.86,230.42,362.39,230.95,362.39,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C361.86,223.23,362.39,223.77,362.39,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C361.86,216.05,362.39,216.58,362.39,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M362.39,210.06c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C361.86,208.86,362.39,209.4,362.39,210.06z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S365.98,270.46,365.98,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S365.98,263.28,365.98,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S365.98,256.09,365.98,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S365.98,248.91,365.98,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S365.98,241.73,365.98,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S365.98,234.54,365.98,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S365.98,227.36,365.98,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S365.98,220.18,365.98,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M365.98,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S365.98,212.99,365.98,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S369.58,274.05,369.58,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S369.58,266.87,369.58,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S369.58,259.69,369.58,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S369.58,252.5,369.58,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="368.38" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S369.58,238.14,369.58,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S369.58,230.95,369.58,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S369.58,223.77,369.58,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M369.58,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S369.58,216.58,369.58,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="368.38" cy="210.06" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S373.17,277.65,373.17,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S373.17,270.46,373.17,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="371.97" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S373.17,256.09,373.17,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S373.17,248.91,373.17,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S373.17,241.73,373.17,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S373.17,234.54,373.17,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="371.97" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S373.17,220.18,373.17,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M373.17,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S373.17,212.99,373.17,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S376.76,281.24,376.76,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S376.76,274.05,376.76,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S376.76,266.87,376.76,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S376.76,259.69,376.76,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S376.76,252.5,376.76,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S376.76,245.32,376.76,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S376.76,238.14,376.76,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S376.76,230.95,376.76,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S376.76,223.77,376.76,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.76,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S376.76,216.58,376.76,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M376.58,209.47c0.1,0.17,0.18,0.37,0.18,0.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2    c0-0.33,0.13-0.63,0.35-0.85l0.84,0.12L376.58,209.47z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,285.49c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C379.82,284.29,380.35,284.83,380.35,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,278.31c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C379.82,277.11,380.35,277.65,380.35,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,271.12c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C379.82,269.93,380.35,270.46,380.35,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,263.94c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    C379.82,262.74,380.35,263.28,380.35,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,256.76c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    C379.82,255.56,380.35,256.09,380.35,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,249.57c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C379.82,248.38,380.35,248.91,380.35,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,242.39c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C379.82,241.19,380.35,241.73,380.35,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,235.2c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C379.82,234.01,380.35,234.54,380.35,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,228.02c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C379.82,226.82,380.35,227.36,380.35,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,220.84c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C379.82,219.64,380.35,220.18,380.35,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,213.65c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C379.82,212.46,380.35,212.99,380.35,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M380.35,292.67c0,0.07-0.03,0.13-0.04,0.2l-1.17-1.39c0.01,0,0.01,0,0.01,0    C379.82,291.48,380.35,292.01,380.35,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M381.57,210.16l1.18,0.16l1.11,0.15c-0.17,0.46-0.6,0.79-1.11,0.79    C382.12,211.26,381.62,210.77,381.57,210.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,296.27c0,0.24-0.09,0.45-0.21,0.64l-0.54-0.64l-0.44-0.52l-0.49-0.57    c0.15-0.07,0.31-0.11,0.49-0.11C383.41,295.07,383.94,295.61,383.94,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,289.08c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S383.94,288.42,383.94,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S383.94,281.24,383.94,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S383.94,274.05,383.94,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S383.94,266.87,383.94,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S383.94,259.69,383.94,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S383.94,252.5,383.94,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S383.94,245.32,383.94,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S383.94,238.14,383.94,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S383.94,230.95,383.94,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S383.94,223.77,383.94,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M383.94,217.24c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S383.94,216.58,383.94,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,292.67c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S387.53,292.01,387.53,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,285.49c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S387.53,284.83,387.53,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,278.31c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S387.53,277.65,387.53,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S387.53,270.46,387.53,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S387.53,263.28,387.53,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S387.53,256.09,387.53,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S387.53,248.91,387.53,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S387.53,241.73,387.53,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S387.53,234.54,387.53,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S387.53,227.36,387.53,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S387.53,220.18,387.53,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,213.65c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S387.53,212.99,387.53,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M387.53,299.86c0,0.4-0.2,0.73-0.5,0.95L385.5,299c0.22-0.21,0.51-0.34,0.84-0.34    C387,298.66,387.53,299.2,387.53,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,303.45c0,0.56-0.39,1-0.9,1.14l-0.3-0.35l-0.67-0.79l-0.41-0.49    c0.19-0.42,0.6-0.71,1.08-0.71C390.59,302.25,391.13,302.79,391.13,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S391.13,295.61,391.13,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S391.13,288.42,391.13,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S391.13,281.24,391.13,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S391.13,274.05,391.13,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S391.13,266.87,391.13,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S391.13,259.69,391.13,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S391.13,252.5,391.13,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="389.93" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S391.13,238.14,391.13,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S391.13,230.95,391.13,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S391.13,223.77,391.13,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M391.13,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S391.13,216.58,391.13,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,307.04c0,0.66-0.54,1.2-1.2,1.2c-0.09,0-0.16-0.03-0.25-0.05l-0.94-1.11v-0.04    c0-0.66,0.53-1.19,1.19-1.19S394.72,306.38,394.72,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S394.72,299.2,394.72,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S394.72,292.01,394.72,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S394.72,284.83,394.72,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S394.72,277.65,394.72,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S394.72,270.46,394.72,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S394.72,263.28,394.72,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S394.72,256.09,394.72,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S394.72,248.91,394.72,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S394.72,241.73,394.72,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S394.72,234.54,394.72,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S394.72,227.36,394.72,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S394.72,220.18,394.72,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M394.72,213.65c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S394.72,212.99,394.72,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,310.63c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C397.78,309.44,398.31,309.97,398.31,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,303.45c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C397.78,302.25,398.31,302.79,398.31,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,296.27c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C397.78,295.07,398.31,295.61,398.31,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,289.08c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C397.78,287.89,398.31,288.42,398.31,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,281.9c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C397.78,280.7,398.31,281.24,398.31,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,274.71c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C397.78,273.52,398.31,274.05,398.31,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,267.53c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C397.78,266.34,398.31,266.87,398.31,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,260.35c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C397.78,259.15,398.31,259.69,398.31,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,253.16c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C397.78,251.97,398.31,252.5,398.31,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,245.98c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C397.78,244.78,398.31,245.32,398.31,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,238.8c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C397.78,237.6,398.31,238.14,398.31,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,231.61c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C397.78,230.42,398.31,230.95,398.31,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,224.43c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C397.78,223.23,398.31,223.77,398.31,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M398.31,217.24c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C397.78,216.05,398.31,216.58,398.31,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,314.23c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S401.9,313.57,401.9,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,307.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S401.9,306.38,401.9,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,299.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S401.9,299.2,401.9,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,292.67c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S401.9,292.01,401.9,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,285.49c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S401.9,284.83,401.9,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,278.31c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S401.9,277.65,401.9,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S401.9,270.46,401.9,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S401.9,263.28,401.9,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S401.9,256.09,401.9,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S401.9,248.91,401.9,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S401.9,241.73,401.9,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S401.9,234.54,401.9,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S401.9,227.36,401.9,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.9,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S401.9,220.18,401.9,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M401.63,212.92c0.16,0.21,0.27,0.45,0.27,0.73c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2    c0-0.4,0.21-0.73,0.51-0.95L401.63,212.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,317.82c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C404.96,316.62,405.49,317.16,405.49,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,310.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C404.96,309.44,405.49,309.97,405.49,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,303.45c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C404.96,302.25,405.49,302.79,405.49,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,296.27c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C404.96,295.07,405.49,295.61,405.49,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,289.08c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C404.96,287.89,405.49,288.42,405.49,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,281.9c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C404.96,280.7,405.49,281.24,405.49,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,274.71c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C404.96,273.52,405.49,274.05,405.49,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C404.96,266.34,405.49,266.87,405.49,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C404.96,259.15,405.49,259.69,405.49,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C404.96,251.97,405.49,252.5,405.49,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C404.96,244.78,405.49,245.32,405.49,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C404.96,237.6,405.49,238.14,405.49,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C404.96,230.42,405.49,230.95,405.49,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C404.96,223.23,405.49,223.77,405.49,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M405.49,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C404.96,216.05,405.49,216.58,405.49,217.24z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="407.89" cy="321.41" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,314.23c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C408.55,313.03,409.09,313.57,409.09,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,307.04c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C408.55,305.85,409.09,306.38,409.09,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,299.86c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C408.55,298.66,409.09,299.2,409.09,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,292.67c0,0.67-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C408.55,291.48,409.09,292.01,409.09,292.67z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="407.89" cy="285.49" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,278.31c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C408.55,277.11,409.09,277.65,409.09,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,271.12c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C408.55,269.93,409.09,270.46,409.09,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="407.89" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C408.55,255.56,409.09,256.09,409.09,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C408.55,248.38,409.09,248.91,409.09,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C408.55,241.19,409.09,241.73,409.09,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C408.55,234.01,409.09,234.54,409.09,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="407.89" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M409.09,220.84c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C408.55,219.64,409.09,220.18,409.09,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M406.69,213.65c0-0.01,0.01-0.02,0.01-0.03l2.33,0.32c-0.13,0.52-0.58,0.91-1.14,0.91    C407.23,214.85,406.69,214.31,406.69,213.65z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,325c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S412.68,324.34,412.68,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,317.82c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S412.68,317.16,412.68,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,310.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S412.68,309.97,412.68,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,303.45c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S412.68,302.79,412.68,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S412.68,295.61,412.68,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S412.68,288.42,412.68,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S412.68,281.24,412.68,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S412.68,274.05,412.68,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S412.68,266.87,412.68,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S412.68,259.69,412.68,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S412.68,252.5,412.68,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S412.68,245.32,412.68,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S412.68,238.14,412.68,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S412.68,230.95,412.68,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S412.68,223.77,412.68,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M412.68,217.24c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S412.68,216.58,412.68,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M415.07,214.85c-0.2,0-0.38-0.06-0.54-0.15l0.76,0.11C415.21,214.82,415.15,214.85,415.07,214.85z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,328.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S416.27,327.93,416.27,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,321.41c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S416.27,320.75,416.27,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S416.27,313.57,416.27,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S416.27,306.38,416.27,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S416.27,299.2,416.27,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S416.27,292.01,416.27,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S416.27,284.83,416.27,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S416.27,277.65,416.27,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S416.27,270.46,416.27,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S416.27,263.28,416.27,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S416.27,256.09,416.27,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S416.27,248.91,416.27,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S416.27,241.73,416.27,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S416.27,234.54,416.27,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S416.27,227.36,416.27,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M416.27,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S416.27,220.18,416.27,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,339.37c0,0.07-0.03,0.13-0.04,0.2l-0.17-0.2l-0.98-1.17l-0.03-0.02    c0.01,0,0.02-0.01,0.03-0.01C419.33,338.17,419.86,338.71,419.86,339.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,332.19c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C419.33,330.99,419.86,331.52,419.86,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,325c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C419.33,323.81,419.86,324.34,419.86,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,317.82c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C419.33,316.62,419.86,317.16,419.86,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,310.63c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C419.33,309.44,419.86,309.97,419.86,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,303.45c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C419.33,302.25,419.86,302.79,419.86,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,296.27c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C419.33,295.07,419.86,295.61,419.86,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,289.08c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C419.33,287.89,419.86,288.42,419.86,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,281.9c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C419.33,280.7,419.86,281.24,419.86,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,274.71c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C419.33,273.52,419.86,274.05,419.86,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,267.53c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C419.33,266.34,419.86,266.87,419.86,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,260.35c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C419.33,259.15,419.86,259.69,419.86,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,253.16c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C419.33,251.97,419.86,252.5,419.86,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,245.98c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    C419.33,244.78,419.86,245.32,419.86,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,238.8c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C419.33,237.6,419.86,238.14,419.86,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,231.61c0,0.66-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C419.33,230.42,419.86,230.95,419.86,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,224.43c0,0.66-0.53,1.19-1.19,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    C419.33,223.23,419.86,223.77,419.86,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M419.86,217.24c0,0.67-0.53,1.2-1.19,1.2c-0.66,0-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C419.33,216.05,419.86,216.58,419.86,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,335.78c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S423.45,335.12,423.45,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,328.59c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S423.45,327.93,423.45,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,321.41c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S423.45,320.75,423.45,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,314.23c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S423.45,313.57,423.45,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,307.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S423.45,306.38,423.45,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,299.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S423.45,299.2,423.45,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,292.67c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S423.45,292.01,423.45,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,285.49c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S423.45,284.83,423.45,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,278.31c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S423.45,277.65,423.45,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S423.45,270.46,423.45,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S423.45,263.28,423.45,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S423.45,256.09,423.45,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S423.45,248.91,423.45,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S423.45,241.73,423.45,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S423.45,234.54,423.45,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S423.45,227.36,423.45,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,220.84c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S423.45,220.18,423.45,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M423.45,342.96c0,0.25-0.09,0.46-0.21,0.65l-1.47-1.74c0.15-0.06,0.31-0.11,0.49-0.11    C422.92,341.76,423.45,342.3,423.45,342.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,346.55c0,0.4-0.21,0.74-0.51,0.96l-0.69-0.81l-0.12-0.15l-0.72-0.85    c0.22-0.21,0.51-0.34,0.84-0.34C426.51,345.36,427.05,345.89,427.05,346.55z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="425.85" cy="339.37" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,332.19c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S427.05,331.52,427.05,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,325c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S427.05,324.34,427.05,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,317.82c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S427.05,317.16,427.05,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,310.63c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S427.05,309.97,427.05,310.63z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="425.85" cy="303.45" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S427.05,295.61,427.05,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S427.05,288.42,427.05,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S427.05,281.24,427.05,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S427.05,274.05,427.05,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S427.05,266.87,427.05,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S427.05,259.69,427.05,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S427.05,252.5,427.05,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="425.85" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S427.05,238.14,427.05,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S427.05,230.95,427.05,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M427.05,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S427.05,223.77,427.05,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M426.66,216.38c0.23,0.21,0.39,0.52,0.39,0.86c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2    c0-0.46,0.28-0.85,0.66-1.05l0.54,0.07L426.66,216.38z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,342.96c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S430.64,342.3,430.64,342.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,335.78c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S430.64,335.12,430.64,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,328.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S430.64,327.93,430.64,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,321.41c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S430.64,320.75,430.64,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S430.64,313.57,430.64,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S430.64,306.38,430.64,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S430.64,299.2,430.64,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S430.64,292.01,430.64,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S430.64,284.83,430.64,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S430.64,277.65,430.64,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S430.64,270.46,430.64,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S430.64,263.28,430.64,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S430.64,256.09,430.64,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S430.64,248.91,430.64,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S430.64,241.73,430.64,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S430.64,234.54,430.64,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S430.64,227.36,430.64,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S430.64,220.18,430.64,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M430.64,350.14c0,0.56-0.39,1.01-0.91,1.14l-1.38-1.62c0.19-0.42,0.61-0.71,1.09-0.71    C430.1,348.95,430.64,349.48,430.64,350.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M431.84,217.24c0-0.05,0.02-0.09,0.03-0.15l1.09,0.15l0.07,0.01l1.17,0.16    c-0.09,0.58-0.57,1.03-1.17,1.03C432.37,218.44,431.84,217.91,431.84,217.24z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,353.74c0,0.66-0.54,1.19-1.2,1.19c-0.09,0-0.17-0.03-0.26-0.05l-0.92-1.09    c0-0.02-0.01-0.04-0.01-0.05c0-0.66,0.53-1.2,1.19-1.2S434.23,353.08,434.23,353.74z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,346.55c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S434.23,345.89,434.23,346.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,339.37c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S434.23,338.71,434.23,339.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,332.19c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S434.23,331.52,434.23,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,325c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S434.23,324.34,434.23,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,317.82c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S434.23,317.16,434.23,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,310.63c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S434.23,309.97,434.23,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,303.45c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S434.23,302.79,434.23,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S434.23,295.61,434.23,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S434.23,288.42,434.23,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S434.23,281.24,434.23,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S434.23,274.05,434.23,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S434.23,266.87,434.23,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S434.23,259.69,434.23,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S434.23,252.5,434.23,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,245.98c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S434.23,245.32,434.23,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S434.23,238.14,434.23,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S434.23,230.95,434.23,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M434.23,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S434.23,223.77,434.23,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,357.33c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C437.29,356.13,437.82,356.67,437.82,357.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,350.14c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C437.29,348.95,437.82,349.48,437.82,350.14z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,342.96c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C437.29,341.76,437.82,342.3,437.82,342.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,335.78c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C437.29,334.58,437.82,335.12,437.82,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,328.59c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C437.29,327.4,437.82,327.93,437.82,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,321.41c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C437.29,320.21,437.82,320.75,437.82,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,314.23c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C437.29,313.03,437.82,313.57,437.82,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,307.04c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C437.29,305.85,437.82,306.38,437.82,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,299.86c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.66,0.53-1.2,1.2-1.2    C437.29,298.66,437.82,299.2,437.82,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,292.67c0,0.67-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.53-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C437.29,291.48,437.82,292.01,437.82,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,285.49c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C437.29,284.29,437.82,284.83,437.82,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,278.31c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C437.29,277.11,437.82,277.65,437.82,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,271.12c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C437.29,269.93,437.82,270.46,437.82,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,263.94c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.2,1.2-1.2    C437.29,262.74,437.82,263.28,437.82,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,256.76c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19c0-0.67,0.53-1.2,1.2-1.2    C437.29,255.56,437.82,256.09,437.82,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,249.57c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.19,1.2-1.19    C437.29,248.38,437.82,248.91,437.82,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,242.39c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C437.29,241.19,437.82,241.73,437.82,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,235.2c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2s0.53-1.19,1.2-1.19    C437.29,234.01,437.82,234.54,437.82,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,228.02c0,0.66-0.53,1.2-1.19,1.2c-0.67,0-1.2-0.54-1.2-1.2c0-0.66,0.53-1.2,1.2-1.2    C437.29,226.82,437.82,227.36,437.82,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M437.82,220.84c0,0.66-0.53,1.19-1.19,1.19c-0.67,0-1.2-0.53-1.2-1.19s0.53-1.2,1.2-1.2    C437.29,219.64,437.82,220.18,437.82,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M440.22,218.44c-0.3,0-0.57-0.12-0.78-0.3l0.78,0.11l0.51,0.07    C440.57,218.39,440.4,218.44,440.22,218.44z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,360.92c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S441.41,360.26,441.41,360.92z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,353.74c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,353.08,441.41,353.74z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,346.55c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S441.41,345.89,441.41,346.55z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,339.37c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S441.41,338.71,441.41,339.37z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,332.19c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S441.41,331.52,441.41,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,325c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S441.41,324.34,441.41,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,317.82c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,317.16,441.41,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,310.63c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S441.41,309.97,441.41,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,303.45c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S441.41,302.79,441.41,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,296.27c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,295.61,441.41,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,289.08c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S441.41,288.42,441.41,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,281.24,441.41,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S441.41,274.05,441.41,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S441.41,266.87,441.41,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,259.69,441.41,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S441.41,252.5,441.41,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S441.41,245.32,441.41,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S441.41,238.14,441.41,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S441.41,230.95,441.41,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M441.41,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S441.41,223.77,441.41,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,364.51c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S445.01,363.85,445.01,364.51z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,357.33c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S445.01,356.67,445.01,357.33z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,350.14c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S445.01,349.48,445.01,350.14z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="443.81" cy="342.96" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,335.78c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S445.01,335.12,445.01,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,328.59c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S445.01,327.93,445.01,328.59z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="443.81" cy="321.41" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S445.01,313.57,445.01,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S445.01,306.38,445.01,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S445.01,299.2,445.01,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S445.01,292.01,445.01,292.67z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="443.81" cy="285.49" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S445.01,277.65,445.01,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S445.01,270.46,445.01,271.12z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="443.81" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S445.01,256.09,445.01,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S445.01,248.91,445.01,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S445.01,241.73,445.01,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S445.01,234.54,445.01,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="443.81" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M445.01,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S445.01,220.18,445.01,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M447.4,366.91c0.16,0,0.31,0.03,0.44,0.09l-0.21,1.1l-0.23,1.19v0.01c-0.66,0-1.2-0.54-1.2-1.2    S446.74,366.91,447.4,366.91z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="447.4" cy="360.92" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,353.74c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,353.08,448.6,353.74z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,346.55c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S448.6,345.89,448.6,346.55z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="447.4" cy="339.37" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,332.19c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S448.6,331.52,448.6,332.19z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,325c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19S448.6,324.34,448.6,325z    "
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,317.82c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,317.16,448.6,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,310.63c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S448.6,309.97,448.6,310.63z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="447.4" cy="303.45" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,296.27c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,295.61,448.6,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,289.08c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S448.6,288.42,448.6,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,281.9c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,281.24,448.6,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,274.71c0,0.67-0.54,1.2-1.2,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S448.6,274.05,448.6,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,267.53c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S448.6,266.87,448.6,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,260.35c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,259.69,448.6,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,253.16c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S448.6,252.5,448.6,253.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="447.4" cy="245.98" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,238.8c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S448.6,238.14,448.6,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,231.61c0,0.66-0.54,1.2-1.2,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S448.6,230.95,448.6,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M448.6,224.43c0,0.66-0.54,1.19-1.2,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S448.6,223.77,448.6,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,342.96c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S452.19,342.3,452.19,342.96z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,335.78c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S452.19,335.12,452.19,335.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,328.59c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S452.19,327.93,452.19,328.59z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,321.41c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S452.19,320.75,452.19,321.41z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,314.23c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S452.19,313.57,452.19,314.23z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,307.04c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S452.19,306.38,452.19,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,299.86c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    S452.19,299.2,452.19,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,292.67c0,0.67-0.54,1.2-1.2,1.2s-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S452.19,292.01,452.19,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,285.49c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S452.19,284.83,452.19,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,278.31c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S452.19,277.65,452.19,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,271.12c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S452.19,270.46,452.19,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,263.94c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.2,1.19-1.2    S452.19,263.28,452.19,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,256.76c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.67,0.53-1.2,1.19-1.2    S452.19,256.09,452.19,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,249.57c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    S452.19,248.91,452.19,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,242.39c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    S452.19,241.73,452.19,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,235.2c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    S452.19,234.54,452.19,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S452.19,227.36,452.19,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M450.99,348.95c0.11,0,0.21,0.03,0.32,0.06l-0.45,2.3c-0.59-0.06-1.06-0.55-1.06-1.17    C449.8,349.48,450.33,348.95,450.99,348.95z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M452.19,220.84c0,0.66-0.54,1.19-1.2,1.19s-1.19-0.53-1.19-1.19c0-0.57,0.4-1.02,0.92-1.15    l0.85,0.12C451.93,220.02,452.19,220.39,452.19,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M454.58,330.99c0.07,0,0.13,0.03,0.19,0.04l-0.19,0.95l-0.04,0.21l-0.22,1.14    c-0.53-0.12-0.93-0.58-0.93-1.14C453.39,331.52,453.92,330.99,454.58,330.99z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,325c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C455.25,323.81,455.78,324.34,455.78,325z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,317.82c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C455.25,316.62,455.78,317.16,455.78,317.82z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,310.63c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C455.25,309.44,455.78,309.97,455.78,310.63z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,303.45c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C455.25,302.25,455.78,302.79,455.78,303.45z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,296.27c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C455.25,295.07,455.78,295.61,455.78,296.27z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,289.08c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C455.25,287.89,455.78,288.42,455.78,289.08z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,281.9c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C455.25,280.7,455.78,281.24,455.78,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,274.71c0,0.67-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.53-1.19-1.2c0-0.66,0.53-1.19,1.19-1.19    C455.25,273.52,455.78,274.05,455.78,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,267.53c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C455.25,266.34,455.78,266.87,455.78,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,260.35c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C455.25,259.15,455.78,259.69,455.78,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,253.16c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C455.25,251.97,455.78,252.5,455.78,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,245.98c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C455.25,244.78,455.78,245.32,455.78,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,238.8c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C455.25,237.6,455.78,238.14,455.78,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,231.61c0,0.66-0.53,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C455.25,230.42,455.78,230.95,455.78,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M455.78,224.43c0,0.66-0.53,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C455.25,223.23,455.78,223.77,455.78,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M458.18,313.03c0.02,0,0.03,0.01,0.05,0.01l-0.44,2.3c-0.47-0.16-0.81-0.59-0.81-1.11    C456.98,313.57,457.52,313.03,458.18,313.03z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,307.04c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S459.37,306.38,459.37,307.04z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,299.86c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S459.37,299.2,459.37,299.86z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,292.67c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S459.37,292.01,459.37,292.67z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,285.49c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S459.37,284.83,459.37,285.49z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,278.31c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S459.37,277.65,459.37,278.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,271.12c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S459.37,270.46,459.37,271.12z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,263.94c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.2,1.2-1.2    S459.37,263.28,459.37,263.94z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,256.76c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    S459.37,256.09,459.37,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,249.57c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S459.37,248.91,459.37,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,242.39c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S459.37,241.73,459.37,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,235.2c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S459.37,234.54,459.37,235.2z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M459.37,228.02c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S459.37,227.36,459.37,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M456.98,220.84c0-0.1,0.03-0.19,0.06-0.27l2.32,0.32c-0.02,0.63-0.54,1.14-1.18,1.14    C457.52,222.03,456.98,221.5,456.98,220.84z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M461.69,295.09l-0.23,1.18l-0.2,1.07c-0.41-0.2-0.69-0.6-0.69-1.07    C460.57,295.63,461.07,295.13,461.69,295.09z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.9,288.78l-0.06,0.3l-0.13,0.71c-0.22,0.29-0.55,0.49-0.94,0.49c-0.66,0-1.2-0.54-1.2-1.2    c0-0.66,0.54-1.19,1.2-1.19C462.32,287.89,462.77,288.27,462.9,288.78z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,281.9c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S462.96,281.24,462.96,281.9z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,274.71c0,0.67-0.53,1.2-1.19,1.2s-1.2-0.53-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    S462.96,274.05,462.96,274.71z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,267.53c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S462.96,266.87,462.96,267.53z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,260.35c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S462.96,259.69,462.96,260.35z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,253.16c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S462.96,252.5,462.96,253.16z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,245.98c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2c0-0.66,0.54-1.2,1.2-1.2    S462.96,245.32,462.96,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,238.8c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    S462.96,238.14,462.96,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,231.61c0,0.66-0.53,1.2-1.19,1.2s-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    S462.96,230.95,462.96,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M462.96,224.43c0,0.66-0.53,1.19-1.19,1.19s-1.2-0.53-1.2-1.19c0-0.66,0.54-1.2,1.2-1.2    S462.96,223.77,462.96,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M465.14,277.16l-0.41,2.14c-0.33-0.22-0.57-0.57-0.57-0.99    C464.16,277.72,464.59,277.26,465.14,277.16z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="465.36" cy="263.94" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M466.56,256.76c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19c0-0.67,0.54-1.2,1.2-1.2    C466.02,255.56,466.56,256.09,466.56,256.76z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M466.56,249.57c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2c0-0.66,0.54-1.19,1.2-1.19    C466.02,248.38,466.56,248.91,466.56,249.57z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M466.56,242.39c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.2-0.53-1.2-1.19s0.54-1.2,1.2-1.2    C466.02,241.19,466.56,241.73,466.56,242.39z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M466.56,235.2c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2s0.54-1.19,1.2-1.19    C466.02,234.01,466.56,234.54,466.56,235.2z"
          />
          <circle style={{ fill: "#DBDBDB" }} cx="465.36" cy="228.02" r="1.2" />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M465.36,222.03c-0.37,0-0.69-0.18-0.91-0.44l1.57,0.21C465.83,221.94,465.61,222.03,465.36,222.03z    "
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M466.41,270.58l-0.28,1.44c-0.21,0.18-0.47,0.3-0.77,0.3c-0.66,0-1.2-0.54-1.2-1.2    s0.54-1.19,1.2-1.19C465.82,269.93,466.21,270.2,466.41,270.58z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M468.59,259.22l-0.21,1.13l-0.18,0.91c-0.26-0.22-0.44-0.54-0.44-0.91    C467.76,259.81,468.11,259.38,468.59,259.22z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M469.9,252.46l-0.14,0.7l-0.19,1c-0.18,0.12-0.39,0.2-0.62,0.2c-0.66,0-1.19-0.54-1.19-1.2    s0.53-1.19,1.19-1.19C469.35,251.97,469.68,252.17,469.9,252.46z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M470.15,245.98c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    C469.61,244.78,470.15,245.32,470.15,245.98z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M470.15,238.8c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19s0.53-1.2,1.19-1.2    C469.61,237.6,470.15,238.14,470.15,238.8z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M470.15,231.61c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.19-0.54-1.19-1.2s0.53-1.19,1.19-1.19    C469.61,230.42,470.15,230.95,470.15,231.61z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M470.15,224.43c0,0.66-0.54,1.19-1.2,1.19c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2    C469.61,223.23,470.15,223.77,470.15,224.43z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M472.04,241.31l-0.36,1.89c-0.2-0.21-0.33-0.49-0.33-0.81    C471.35,241.91,471.64,241.5,472.04,241.31z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M473.74,228.02c0,0.66-0.54,1.2-1.2,1.2s-1.19-0.54-1.19-1.2c0-0.66,0.53-1.2,1.19-1.2    S473.74,227.36,473.74,228.02z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M472.54,234.01c0.33,0,0.63,0.13,0.84,0.34l-0.37,1.96c-0.15,0.06-0.3,0.09-0.47,0.09    c-0.66,0-1.19-0.54-1.19-1.2S471.88,234.01,472.54,234.01z"
          />
          <path
            style={{ fill: "#DBDBDB" }}
            d="M475.48,223.45l-0.19,0.98l-0.12,0.66c-0.14-0.19-0.23-0.41-0.23-0.66    C474.94,224.02,475.16,223.67,475.48,223.45z"
          />
        </g>
      </g>
      <g id="Floor">
        <polygon
          style={{ fill: "#263238" }}
          points="35.91,388.82 88.932,388.577 141.955,388.489 248,388.322 354.045,388.488 407.068,388.576    460.09,388.82 407.068,389.065 354.045,389.153 248,389.319 141.955,389.152 88.932,389.064  "
        />
      </g>
      <g id="Shadows">
        <g>
          <ellipse
            style={{ fill: "#EBEBEB" }}
            cx="250"
            cy="443.142"
            rx="210.09"
            ry="24.49"
          />
        </g>
      </g>
      <g id="Character_2">
        <g id="Capa_4"></g>
        <g>
          <g>
            <g>
              <g>
                <path
                  style={{ fill: "#EB9481" }}
                  d="M277.473,447.706c2.078,0.703,61.621,0.912,63.898-0.515c0.866-0.537,1.402-9.207,1.752-19.702       c0.076-2.266,0.141-4.607,0.193-6.985l4.828-31.759l-39.805-0.255l-3.758,31.766l0.152,6.987c0,0-22.833,10.117-25.161,11.95       C277.257,441.026,275.396,447.002,277.473,447.706z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M277.473,447.706c2.078,0.703,61.621,0.912,63.898-0.515c1.052-0.659,1.625-13.32,1.945-26.686       l-38.734-0.248l0.152,6.987c0,0-22.833,10.117-25.161,11.95C277.257,441.026,275.396,447.002,277.473,447.706z"
                />
                <g>
                  <path
                    style={{ fill: "#f7045e" }}
                    d="M277.473,447.706c2.078,0.703,61.621,0.912,63.898-0.515c0.866-0.537,1.402-9.207,1.752-19.702        l-38.389-0.246c0,0-22.833,10.117-25.161,11.95C277.257,441.026,275.396,447.002,277.473,447.706z"
                  />
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M278.336,445.327c9.982-0.21,50.264-0.115,60.147,0.436c0.081,0.004,0.08,0.059,0,0.063          c-9.889,0.424-50.169,0.003-60.148-0.335C278.124,445.483,278.125,445.331,278.336,445.327z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M283.41,437.065c2.368-0.088,4.677,1.044,5.963,2.458c1.235,1.359,2.289,3.267,2.212,5.337          c-0.002,0.059-0.112,0.061-0.121-0.001c-0.556-3.908-4.097-7.351-8.055-7.67C283.33,437.183,283.329,437.068,283.41,437.065          z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M300.859,428.222c3.995-0.041,8.622,1.385,11.093,4.661c0.096,0.127-0.086,0.261-0.204,0.2          c-3.605-1.874-7.033-3.274-10.959-4.369C300.512,428.637,300.573,428.225,300.859,428.222z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M297.771,429.414c3.995-0.042,8.622,1.385,11.093,4.661c0.096,0.127-0.086,0.261-0.204,0.199          c-3.605-1.874-7.033-3.274-10.959-4.369C297.424,429.828,297.485,429.417,297.771,429.414z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M294.683,430.605c3.995-0.041,8.622,1.385,11.093,4.661c0.096,0.127-0.086,0.261-0.204,0.2          c-3.605-1.874-7.033-3.274-10.959-4.369C294.336,431.02,294.396,430.608,294.683,430.605z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M291.594,431.797c3.995-0.042,8.622,1.385,11.093,4.661c0.096,0.127-0.086,0.261-0.204,0.2          c-3.605-1.874-7.033-3.274-10.959-4.369C291.247,432.211,291.308,431.8,291.594,431.797z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M335.974,436.273c4.482,0.029,4.445,6.995-0.045,6.966          C331.447,443.21,331.484,436.244,335.974,436.273z"
                      />
                    </g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M296.878,423.404c2.146,2.789,5.425,4.552,8.262,6.529c0.294,0.205,0.633-0.034,0.72-0.32         c0.066,0.005,0.13-0.029,0.125-0.114c-0.211-3.444-1.585-7.402-3.594-10.229c-1.241-1.746-3.783-3.393-5.811-1.723         C294.661,419.126,295.625,421.775,296.878,423.404z M296.704,420.094c0.413-4.345,4.322-0.992,5.186,0.33         c0.555,0.85,1.017,1.784,1.45,2.7c0.907,1.921,1.524,3.934,2.23,5.928c-1.334-1.057-2.747-1.99-4.119-2.996         C299.816,424.855,296.477,422.489,296.704,420.094z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M320.18,427.001c-0.304-2.609-3.278-3.184-5.382-2.786c-3.408,0.644-7.142,2.544-9.682,4.88         c-0.063,0.057-0.039,0.127,0.011,0.169c-0.136,0.266-0.058,0.673,0.297,0.729c3.416,0.542,7,1.545,10.481,1.023         C317.937,430.71,320.468,429.469,320.18,427.001z M310.771,429.755c-1.687-0.226-3.352-0.533-5.046-0.696         c1.891-0.947,3.732-1.97,5.717-2.725c0.947-0.361,1.927-0.712,2.917-0.941c1.539-0.355,6.681-0.06,3.966,3.357         C316.828,430.634,312.781,430.024,310.771,429.755z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#37474F" }}
                  d="M406.637,241.943c0,0-46.226,78.639-47.464,84.16c-1.902,8.484-11.984,92.27-11.984,92.27       l-48.099-0.866c0,0,8.29-73.872,16.141-98.209c5.655-17.53,46.451-75.67,46.451-75.67L406.637,241.943z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M303.464,410.132c6.908-0.05,31.385-0.326,40.58,0.532c0.07,0.007,0.068,0.123-0.002,0.133        c-3.165,0.454-33.676-0.253-40.58-0.475C303.332,410.318,303.334,410.133,303.464,410.132z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M398.747,249.158c-3.029,4.751-6.225,9.403-9.322,14.113        c-3.099,4.712-6.186,9.431-9.233,14.175c-6.09,9.483-12.021,19.065-17.842,28.707c-2.73,4.522-5.331,9.086-7.206,14.018        c-1.858,4.886-3.122,9.969-4.057,15.094c-0.943,5.162-1.564,10.374-2.15,15.584c-0.624,5.548-1.239,11.096-1.859,16.645        c-1.267,11.353-2.534,22.705-3.802,34.058c-0.156,1.393-0.308,2.785-0.447,4.179c-0.02,0.204,0.345,0.245,0.371,0.04        c1.415-11.281,2.591-22.595,3.876-33.891c0.64-5.623,1.28-11.247,1.92-16.87c0.595-5.229,1.161-10.464,1.973-15.665        c0.797-5.109,1.832-10.198,3.388-15.143c1.625-5.167,3.926-10.018,6.65-14.717c5.574-9.613,11.472-19.062,17.373-28.487        c5.952-9.506,11.953-18.985,18.177-28.324c0.769-1.153,1.544-2.3,2.341-3.434        C398.958,249.151,398.805,249.067,398.747,249.158z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#EB9481" }}
                  d="M365.77,454.318c2.192,0.145,61.267-15.432,63.108-17.388c0.061-0.066,0.11-0.204,0.139-0.404       c0.236-1.933-2.789-9.19-4.906-18.475c-0.131-0.594-1.244-3.411-2.802-7.218c-4.284-10.456-11.935-28.409-11.935-28.409       l-38.576,9.893l14.209,28.551l1.921,6.716c0,0-19.506,15.603-21.287,17.966C363.869,447.91,363.586,454.164,365.77,454.318z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M365.77,454.318c2.192,0.145,61.267-15.432,63.108-17.388c0.061-0.066,0.11-0.204,0.139-0.404       c0.236-1.933-2.789-9.19-4.906-18.475c-0.131-0.594-1.244-3.411-2.802-7.218l-36.302,10.035l1.921,6.716       c0,0-19.506,15.603-21.287,17.966C363.869,447.91,363.586,454.164,365.77,454.318z"
                />
                <g>
                  <path
                    style={{ fill: "#f7045e" }}
                    d="M365.774,454.314c2.188,0.151,61.265-15.427,63.103-17.387        c0.701-0.739-0.83-8.731-4.762-18.876l-37.187,9.535c0,0-19.506,15.596-21.29,17.961        C363.865,447.91,363.586,454.163,365.774,454.314z"
                  />
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M366.002,451.794c9.6-2.744,48.579-12.906,58.277-14.889c0.079-0.016,0.093,0.037,0.016,0.06          c-9.456,2.928-48.516,12.774-58.252,14.987C365.838,451.999,365.799,451.852,366.002,451.794z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M368.806,442.512c2.267-0.688,4.789-0.181,6.392,0.859c1.54,1,3.046,2.577,3.498,4.598          c0.013,0.058-0.093,0.087-0.117,0.03c-1.533-3.638-5.833-6.066-9.742-5.366          C368.759,442.647,368.729,442.536,368.806,442.512z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M383.43,429.52c3.853-1.057,8.691-0.855,11.914,1.683c0.125,0.098-0.017,0.275-0.147,0.245          c-3.964-0.895-7.635-1.375-11.71-1.435C383.199,430.009,383.154,429.595,383.43,429.52z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M380.746,431.458c3.853-1.057,8.691-0.856,11.914,1.684c0.125,0.098-0.017,0.274-0.147,0.245          c-3.963-0.895-7.635-1.375-11.71-1.435C380.516,431.947,380.471,431.534,380.746,431.458z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M378.063,433.397c3.853-1.057,8.691-0.856,11.914,1.684c0.125,0.098-0.017,0.274-0.147,0.245          c-3.963-0.895-7.635-1.376-11.71-1.435C377.833,433.886,377.787,433.472,378.063,433.397z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M375.38,435.335c3.853-1.057,8.69-0.856,11.914,1.684c0.125,0.098-0.017,0.275-0.147,0.245          c-3.963-0.895-7.635-1.375-11.71-1.435C375.149,435.824,375.104,435.411,375.38,435.335z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M419.437,428.366c4.342-1.113,6.079,5.633,1.73,6.748          C416.825,436.228,415.087,429.482,419.437,428.366z"
                      />
                    </g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M378.352,425.873c2.786,2.151,6.405,3.021,9.652,4.211c0.337,0.124,0.604-0.194,0.614-0.493         c0.065-0.012,0.118-0.061,0.092-0.142c-1.081-3.277-3.417-6.754-6.08-8.977c-1.644-1.372-4.522-2.318-6.058-0.188         C375.12,422.301,376.726,424.618,378.352,425.873z M377.342,422.716c-0.707-4.307,3.927-2.06,5.099-1.001         c0.753,0.681,1.437,1.466,2.089,2.242c1.366,1.627,2.475,3.417,3.666,5.165c-1.559-0.682-3.163-1.225-4.746-1.849         C381.564,426.529,377.732,425.091,377.342,422.716z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M401.803,423.42c-0.958-2.446-3.981-2.245-5.914-1.324c-3.132,1.49-6.259,4.278-8.121,7.183         c-0.046,0.071-0.006,0.133,0.054,0.16c-0.064,0.292,0.116,0.666,0.473,0.63c3.441-0.345,7.163-0.288,10.396-1.678         C400.578,427.578,402.709,425.734,401.803,423.42z M393.405,428.478c-1.689,0.211-3.377,0.338-5.057,0.611         c1.588-1.397,3.107-2.855,4.835-4.091c0.824-0.59,1.682-1.18,2.581-1.652c1.398-0.735,6.446-1.759,4.69,2.237         C399.486,427.787,395.417,428.227,393.405,428.478z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#37474F" }}
                  d="M382.362,419.032l43.214-11.34c0,0-41.373-81.428-41.037-87.052       c0.298-4.982,30.309-48.678,31.969-56.858c2.717-13.389-3.806-25.349-10.129-32.757l-44.696,12.601       c0,0,3.559,7.73,10.097,12.615c0,0-28.091,46.902-28.81,62.335C342.246,334.094,382.362,419.032,382.362,419.032z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M381.535,410.984c6.269-1.795,4.445-1.368,10.734-3.094c3.078-0.844,23.306-6.374,26.315-6.657        c0.066-0.006,0.095,0.101,0.034,0.128c-2.757,1.239-23.078,6.414-26.172,7.199c-6.321,1.604-4.524,1.074-10.862,2.607        C381.465,411.197,381.418,411.018,381.535,410.984z"
                  />
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M364.918,254.44c4.295,1.514,8.428,3.628,12.376,5.837c0.155,0.087,0.047,0.319-0.121,0.246         c-4.172-1.819-8.428-3.564-12.491-5.603C364.362,254.76,364.588,254.324,364.918,254.44z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M370.17,253.338c0.324,0.782,0.582,1.594,0.798,2.41c0.168,0.634,0.4,1.319,0.313,1.975         c-0.017,0.127-0.192,0.136-0.261,0.049c-0.43-0.547-0.602-1.288-0.836-1.931c-0.271-0.746-0.559-1.487-0.809-2.24         C369.21,253.102,369.97,252.856,370.17,253.338z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M370.165,249.287c-0.177-0.686-0.355-1.37-0.547-2.052c-0.198-0.705-0.444-1.408-0.587-2.125         c-0.023-0.113-0.204-0.062-0.174,0.053c0.175,0.686,0.255,1.405,0.377,2.103c0.122,0.701,0.257,1.4,0.394,2.098         c0.256,1.3,0.606,2.603,0.988,3.876c-0.692-0.098-1.402-0.22-1.992-0.614c-0.64-0.428-0.963-1.208-1.215-1.909         c-0.28-0.777-0.529-1.567-0.821-2.34c-0.323-0.855-0.692-1.707-0.862-2.61c-0.021-0.114-0.199-0.062-0.174,0.053         c0.409,1.872,0.809,3.74,1.591,5.516c0.307,0.698,0.537,1.412,1.224,1.758c0.737,0.371,1.588,0.425,2.399,0.471         c0.095,0.04,0.232-0.007,0.213-0.132C370.768,252.049,370.514,250.642,370.165,249.287z"
                    />
                  </g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M370.912,257.775c-1.701,3.068-3.646,6.014-5.462,9.016c-1.774,2.934-3.465,5.915-5.12,8.917        c-3.345,6.065-6.442,12.281-9.269,18.604c-1.399,3.129-2.724,6.291-3.964,9.487c-1.243,3.204-2.472,6.444-3.247,9.799        c-0.777,3.365-0.965,6.767-0.437,10.184c0.536,3.467,1.583,6.834,2.635,10.173c2.401,7.622,5.059,15.174,8.438,22.419        c0.113,0.241,0.514,0.058,0.425-0.192c-2.358-6.66-4.807-13.298-6.928-20.041c-2.057-6.541-4.494-13.32-3.361-20.267        c0.546-3.352,1.661-6.578,2.84-9.752c1.203-3.24,2.503-6.443,3.879-9.613c2.712-6.246,5.762-12.317,8.874-18.37        c1.744-3.391,3.577-6.734,5.339-10.116c1.775-3.407,3.51-6.883,5.506-10.166C371.119,257.763,370.966,257.678,370.912,257.775        z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M403.461,251.213c-0.809-0.137-1.992,0.146-5.496-0.095c-0.968-0.067-1.93-0.253-2.876-0.469        c-0.945-0.215-1.907-0.459-2.672-1.084c-1.29-1.054-2.267-2.746-3.047-4.195c-0.857-1.593-1.727-3.409-2.133-5.179        c-0.025-0.109-0.183-0.057-0.164,0.05c0.184,1.025,0.286,2.048,0.557,3.062c0.254,0.953,0.622,1.88,1.023,2.78        c0.748,1.681,1.623,3.484,3.129,4.619c1.581,1.191,3.785,1.413,5.701,1.547c1.108,0.077,5.453-0.039,6.036-0.66        C403.623,251.478,403.615,251.239,403.461,251.213z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M414.847,398.361c-5.448-11.086-10.759-22.238-15.942-33.451        c-5.196-11.242-10.262-22.544-15.232-33.887c-1.189-2.713-2.613-5.454-3.229-8.369c-0.67-3.17,0.306-6.032,1.644-8.878        c2.454-5.22,5.32-10.238,8.348-15.143c3.037-4.92,6.246-9.73,9.415-14.565c3.143-4.796,6.448-9.588,9.024-14.721        c2.46-4.903,3.827-10.138,3.123-15.64c-0.698-5.458-3.063-10.582-5.6-15.405c-0.669-1.272-1.355-2.536-2.027-3.807        c-0.051-0.097-0.2-0.007-0.146,0.09c2.829,5.052,5.539,10.363,6.851,16.039c0.648,2.804,0.93,5.705,0.627,8.574        c-0.307,2.91-1.22,5.72-2.428,8.375c-2.35,5.162-5.625,9.916-8.716,14.652c-3.16,4.842-6.383,9.645-9.459,14.541        c-3.095,4.926-6.043,9.953-8.606,15.179c-1.141,2.327-2.392,4.754-2.67,7.368c-0.305,2.863,0.729,5.608,1.833,8.195        c4.806,11.266,9.862,22.431,15.005,33.547c5.162,11.158,10.464,22.252,15.876,33.29c0.682,1.392,1.367,2.783,2.053,4.173        C414.677,398.691,414.931,398.533,414.847,398.361z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#263238" }}
                d="M360.762,147.602c0.961-4.569,6.077-14.916,16.109-12.89      c14.317,2.89,16.393,30.482,23.518,39.079c12.911,15.579,13.334,31.001,2.752,33.467c-11.859,2.763-23.401-9.961-22.967-37.498      c0.271-17.219-4.586-24.851-8.02-24.337c-3.434,0.514-5.308,4.855-5.308,4.855L360.762,147.602z"
              />
              <path
                style={{ fill: "#263238" }}
                d="M361.24,145.885c0.701-2.118,1.665-4.154,2.868-6.063c1.213-1.896,2.75-3.642,4.684-4.92      c1.911-1.299,4.275-2.003,6.617-1.918c2.312,0.077,4.62,0.689,6.651,1.843c2.025,1.165,3.704,2.835,5.032,4.691      c1.32,1.87,2.359,3.896,3.211,5.983c1.65,4.197,2.643,8.58,3.314,12.984c-1.15-4.305-2.457-8.564-4.218-12.599      c-0.936-1.988-1.962-3.931-3.267-5.661c-1.285-1.735-2.846-3.264-4.698-4.306c-1.831-1.068-3.945-1.636-6.072-1.746      c-2.111-0.138-4.216,0.453-6.045,1.556c-1.831,1.11-3.404,2.677-4.705,4.444c-0.675,0.869-1.265,1.805-1.833,2.756      C362.235,143.893,361.722,144.879,361.24,145.885z"
              />
              <path
                style={{ fill: "#263238" }}
                d="M380.089,160.271c0.148,2.732-0.008,5.469-0.061,8.197c-0.093,2.727-0.172,5.451-0.173,8.169      c0.014,2.717,0.08,5.431,0.354,8.119c0.263,2.686,0.688,5.358,1.462,7.917c0.751,2.559,1.859,5.022,3.485,7.098      c1.637,2.043,3.733,3.829,5.998,5.195c2.285,1.361,4.87,2.236,7.547,2.418c1.335,0.099,2.69,0.035,4.017-0.216      c0.664-0.123,1.32-0.293,1.961-0.512c0.639-0.219,1.282-0.463,1.841-0.857c-1.073,0.861-2.406,1.336-3.73,1.695      c-1.336,0.35-2.724,0.514-4.115,0.507c-1.392-0.02-2.782-0.23-4.133-0.591c-1.348-0.372-2.645-0.922-3.874-1.589      c-1.229-0.667-2.385-1.465-3.46-2.355l-0.793-0.682l-0.761-0.716c-0.505-0.481-0.994-1.005-1.434-1.556      c-1.779-2.209-2.954-4.818-3.758-7.478c-1.553-5.369-1.761-10.946-1.589-16.412c0.087-2.741,0.3-5.469,0.54-8.19      C379.675,165.71,379.953,162.999,380.089,160.271z"
              />
              <path
                style={{ fill: "#f7045e" }}
                d="M359.942,147.679c-0.157-1.272,0.607-3.002,1.412-3.308c0.804-0.306,7.176,1.744,7.85,3.265      c0.674,1.521-1.917,3.165-1.917,3.165L359.942,147.679z"
              />
            </g>
            <g>
              <path
                style={{ fill: "#EB9481" }}
                d="M367.585,192.721c-3.935,5.608-32.359,28.814-47.852,29.674      c-6.986,0.388-34.381-2.593-40.479-5.105c-7.49-3.085,7.312-21.627,12.014-20.841c5.866,0.98,26.647,3.189,30.782,3.298      c6.636,0.176,26.978-11,40.715-13.905C369.835,184.348,370.615,188.402,367.585,192.721z"
              />
              <g>
                <path
                  style={{ fill: "#DBDBDB" }}
                  d="M367.27,193.785c-4.339,7.566-19.662,20.54-19.662,20.54l-2.613-4.813l-8.647-15.908       c0,0,14.523-5.787,24.153-8.598c1.301-0.381,2.453-0.637,3.464-0.757C370.186,183.453,371.012,187.276,367.27,193.785z"
                />
                <path
                  style={{ fill: "#DBDBDB" }}
                  d="M367.269,193.781c-4.335,7.565-19.662,20.549-19.662,20.549l-2.616-4.817l-1.784-3.284       l-6.864-12.622c0,0,14.527-5.791,24.161-8.605c1.303-0.372,2.452-0.635,3.459-0.755c0.131-0.011,0.252-0.033,0.372-0.033       C370.224,183.611,370.936,187.41,367.269,193.781z"
                />
                <g>
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M349.157,189.691c0.195,0.699,0.24,1.398,0.25,2.097c-0.009,0.699-0.054,1.398-0.25,2.097        c-0.196-0.699-0.241-1.398-0.25-2.097C348.918,191.089,348.963,190.39,349.157,189.691z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M347.151,198.178c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.098-0.25        c0.699-0.196,1.398-0.241,2.098-0.25C345.752,197.938,346.452,197.983,347.151,198.178z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M368.383,186.44c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.097-0.25        c0.699-0.196,1.398-0.241,2.097-0.25C366.985,186.2,367.684,186.245,368.383,186.44z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M356.806,194.966c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66        c-0.633-0.356-1.159-0.818-1.66-1.307C357.625,196.125,357.163,195.598,356.806,194.966z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M348.737,204.974c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66        c-0.633-0.356-1.159-0.818-1.66-1.307C349.557,206.133,349.094,205.606,348.737,204.974z"
                  />
                </g>
                <path
                  style={{ fill: "#263238" }}
                  d="M350.281,210.784c-1.456-2.934-7.852-14.732-9.405-17.192c-0.108-0.171-0.241-0.147-0.152,0.045       c4.154,8.891,7.865,14.39,9.44,17.198C350.204,210.904,350.318,210.858,350.281,210.784z"
                />
                <path
                  style={{ fill: "#A6A6A6" }}
                  d="M367.269,193.781c-4.335,7.565-19.662,20.549-19.662,20.549l-2.616-4.817l-1.784-3.284       c8.309-8.2,18.326-18.293,21.129-22.015C370.224,183.611,370.936,187.41,367.269,193.781z"
                />
              </g>
            </g>
            <g>
              <path
                style={{ fill: "#DBDBDB" }}
                d="M353.104,228.645l2.269,24.99c0,0,3.087-0.052,9.129-0.911c5.487-0.792,13.406-2.24,23.673-4.929      c7.726-2.033,16.79-4.765,27.143-8.443c0,0-23.425-42.261-39.594-53.924c-0.369-0.27-0.735-0.519-1.1-0.746      c-0.072-0.059-0.142-0.095-0.213-0.143c-7.353-4.651-13.948-1.017-17.686,4.185c-0.924,1.294-9.924,21.758-9.477,28.612      C347.658,223.38,353.104,228.645,353.104,228.645z"
              />
              <g>
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M366.101,201.886c0.195,0.699,0.24,1.398,0.25,2.098c-0.009,0.699-0.054,1.398-0.25,2.098       c-0.196-0.699-0.241-1.398-0.25-2.098C365.861,203.284,365.906,202.585,366.101,201.886z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M371.804,225.972c0.195,0.699,0.24,1.398,0.25,2.098c-0.009,0.699-0.054,1.398-0.25,2.098       c-0.196-0.699-0.241-1.398-0.25-2.098C371.564,227.37,371.61,226.671,371.804,225.972z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M389.277,211.072c0.195,0.699,0.24,1.398,0.25,2.098c-0.009,0.699-0.054,1.398-0.25,2.097       c-0.196-0.699-0.241-1.398-0.25-2.097C389.037,212.47,389.082,211.771,389.277,211.072z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M357.257,222.783c0.195,0.699,0.24,1.398,0.25,2.098c-0.009,0.699-0.054,1.398-0.25,2.097       c-0.196-0.699-0.241-1.398-0.25-2.097C357.018,224.182,357.063,223.482,357.257,222.783z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M379.268,206.703c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.097-0.25       c0.699-0.196,1.398-0.241,2.097-0.25C377.87,206.463,378.569,206.508,379.268,206.703z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M394.147,225.346c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.097-0.25       c0.699-0.196,1.398-0.241,2.097-0.25C392.749,225.106,393.448,225.151,394.147,225.346z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M371.531,240.13c-0.699,0.195-1.398,0.24-2.098,0.25c-0.699-0.009-1.398-0.054-2.098-0.25       c0.699-0.196,1.398-0.241,2.098-0.25C370.132,239.891,370.832,239.936,371.531,240.13z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M397.871,237.283c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.098-0.25       c0.699-0.196,1.398-0.241,2.098-0.25C396.473,237.043,397.172,237.088,397.871,237.283z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M368.383,186.44c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.097-0.25       c0.699-0.196,1.398-0.241,2.097-0.25C366.985,186.2,367.684,186.245,368.383,186.44z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M375.687,189.161c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66       c-0.633-0.356-1.159-0.818-1.66-1.307C376.507,190.319,376.044,189.793,375.687,189.161z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M397.536,212.549c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66       c-0.633-0.356-1.159-0.818-1.66-1.307C398.355,213.707,397.893,213.181,397.536,212.549z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M362.594,215.051c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66       c-0.633-0.356-1.159-0.818-1.66-1.306C363.413,216.209,362.951,215.683,362.594,215.051z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M382.644,231.949c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66       c-0.633-0.356-1.159-0.818-1.66-1.307C383.463,233.108,383.001,232.581,382.644,231.949z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M356.806,194.966c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66       c-0.633-0.356-1.159-0.818-1.66-1.307C357.625,196.125,357.163,195.598,356.806,194.966z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M355.032,211.072c0.357-0.632,0.819-1.158,1.307-1.66c0.501-0.488,1.027-0.951,1.66-1.307       c-0.356,0.633-0.818,1.159-1.306,1.66C356.191,210.252,355.664,210.715,355.032,211.072z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M402.477,228.938c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.306       c-0.356,0.633-0.818,1.159-1.306,1.66C403.636,228.119,403.109,228.582,402.477,228.938z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M357.617,236.097c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.306       c-0.356,0.633-0.818,1.159-1.306,1.66C358.775,235.278,358.249,235.74,357.617,236.097z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M384.399,201.628c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.307       c-0.356,0.633-0.818,1.159-1.306,1.66C385.557,200.809,385.031,201.271,384.399,201.628z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M377.759,246.529c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.306       c-0.356,0.633-0.818,1.159-1.306,1.66C378.917,245.71,378.391,246.172,377.759,246.529z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M377.785,219.5c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.306       c-0.356,0.633-0.819,1.159-1.306,1.66C378.943,218.681,378.417,219.144,377.785,219.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#EB9481" }}
                  d="M369.823,184.124c-0.14,0.965-4.125,7.494-8.465,7.664c-1.414,0.053-2.047-7.651-2.047-7.651       l-0.177-0.558l-2.469-7.813l8.84-6.812l1.284-0.911c0,0,0.535,2.182,1.173,4.906c0.03,0.126,0.061,0.252,0.091,0.388       c0.04,0.153,0.08,0.297,0.111,0.442c0.101,0.406,0.202,0.82,0.294,1.236c0.071,0.316,0.142,0.622,0.205,0.956       c0.071,0.334,0.134,0.659,0.206,0.993C369.501,180.268,369.876,183.714,369.823,184.124z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M367.961,172.949c-1.735,6.537-6.577,9.559-8.828,10.63l-2.469-7.813l8.84-6.812l1.284-0.911       C366.788,168.044,367.323,170.225,367.961,172.949z"
                />
              </g>
              <g>
                <path
                  style={{ fill: "#EB9481" }}
                  d="M345.701,163.97c-0.069,14.38,8.536,16.045,11.636,16.056       c2.815,0.01,12.401-0.312,13.955-14.607c1.555-14.296-5.109-18.873-11.546-19.238       C353.311,145.817,345.77,149.59,345.701,163.97z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M370.51,166.424c-2.354,0.241-3.018-4.69-3.018-4.69s-4.792,0.133-6.544-6.719       c0,0,0.183,3.205,2.262,5.646c0,0-8.472-0.687-12.205-7.67c0,0,1.508,3.567,2.871,4.815c0,0-3.569-0.901-5.368-3.736       c0,0-1.378,4.118-2.489,5.273c0,0-1.641-7.248,1.367-11.016c6.339-7.942,16.752-1.039,16.752-1.039s5.822,0.879,7.898,5.238       C375.243,159.257,370.51,166.424,370.51,166.424z"
                />
                <g>
                  <g>
                    <path
                      style={{ fill: "#EB9481" }}
                      d="M369.002,166.513c0,0,2.761-4.73,4.883-3.781c2.121,0.949,0.3,7.626-2.01,8.709         c-2.31,1.082-3.354-1.037-3.354-1.037L369.002,166.513z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M373.315,164.633c0.038-0.007,0.05,0.047,0.018,0.063c-1.616,0.814-2.368,2.435-2.853,4.092         c0.447-0.659,1.095-1.053,1.972-0.53c0.046,0.027,0.01,0.099-0.038,0.093c-0.697-0.091-1.18,0.069-1.598,0.655         c-0.296,0.415-0.479,0.924-0.677,1.391c-0.066,0.156-0.342,0.098-0.299-0.079c0.003-0.013,0.007-0.027,0.01-0.04         C369.634,168.152,371.046,165.035,373.315,164.633z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M347.502,161.094c0.338-0.053,0.641-0.158,0.97-0.244c0.371-0.098,0.677-0.138,0.942-0.438        c0.142-0.161,0.118-0.476-0.025-0.628c-0.334-0.356-0.819-0.349-1.27-0.249c-0.49,0.108-0.84,0.317-1.151,0.709        C346.666,160.624,347.056,161.164,347.502,161.094z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M356.878,162.214c-0.341-0.026-0.651-0.107-0.986-0.168c-0.378-0.069-0.686-0.085-0.973-0.363        c-0.154-0.149-0.155-0.465-0.024-0.628c0.305-0.381,0.789-0.412,1.247-0.348c0.497,0.07,0.862,0.251,1.203,0.617        C357.676,161.68,357.328,162.249,356.878,162.214z"
                  />
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M354.965,165.153c0.007-0.038,0.078,0.045,0.077,0.086c-0.016,0.987,0.132,2.119,1.029,2.433          c0.028,0.01,0.026,0.059-0.007,0.055C354.973,167.591,354.792,166.085,354.965,165.153z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M355.829,164.11c1.584-0.145,1.758,3.034,0.29,3.168          C354.676,167.41,354.502,164.231,355.829,164.11z"
                      />
                    </g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M356.463,164.243c0.287,0.165,0.577,0.467,0.913,0.482c0.389,0.017,0.664-0.332,0.899-0.666         c0.025-0.035,0.053-0.008,0.056,0.034c0.042,0.609-0.158,1.246-0.806,1.373c-0.591,0.116-1.048-0.4-1.234-1.005         C356.258,164.35,356.347,164.177,356.463,164.243z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M349.488,165.201c-0.01-0.037-0.074,0.051-0.07,0.092c0.093,0.982,0.034,2.123-0.836,2.506          c-0.027,0.012-0.021,0.061,0.012,0.054C349.67,167.632,349.733,166.117,349.488,165.201z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M348.544,164.228c-1.591-0.021-1.516,3.161-0.043,3.181          C349.952,167.429,349.878,164.246,348.544,164.228z"
                      />
                    </g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M347.886,164.453c-0.247,0.175-0.489,0.487-0.789,0.514c-0.347,0.031-0.613-0.307-0.843-0.632         c-0.024-0.034-0.048-0.006-0.048,0.036c-0.003,0.609,0.213,1.238,0.801,1.341c0.535,0.094,0.915-0.437,1.046-1.046         C348.075,164.552,347.985,164.383,347.886,164.453z"
                    />
                  </g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M356.22,173.195c-0.212,0.253-0.415,0.593-0.768,0.659c-0.339,0.063-0.754-0.051-1.08-0.141        c-0.032-0.009-0.06,0.027-0.037,0.055c0.293,0.351,0.852,0.533,1.299,0.408c0.436-0.122,0.618-0.528,0.684-0.945        C356.328,173.171,356.251,173.158,356.22,173.195z"
                  />
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M351.143,169.559c0,0-0.14,1.48-0.111,2.174c0.003,0.065,1.971-0.187,4.035,0.412         c0.31,0.09,0.296-0.167-0.056-0.358c-0.703-0.382-1.603-0.757-3.408-0.683c-0.057-0.19,0.258-2.275,0.148-2.279         c-0.381-0.016-1.117,0.274-1.561,0.425c0.202-3.092,0.899-6.126,1.055-9.208c0.004-0.11-0.155-0.136-0.183-0.037         c-0.955,3.129-1.266,6.682-1.558,9.869C349.464,170.272,350.87,169.689,351.143,169.559z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M352.633,171.727c0,0,0.973,1.253,2.024,1.361c1.209,0.124,1.297-0.963-0.059-1.509         C353.887,171.293,352.633,171.727,352.633,171.727z"
                    />
                    <path
                      style={{ fill: "#FF99BA" }}
                      d="M354.653,173.085c-0.45-0.041-0.884-0.294-1.236-0.578c0.227-0.387,0.781-0.826,2.103-0.198         C355.734,172.765,355.416,173.166,354.653,173.085z"
                    />
                  </g>
                </g>
              </g>
              <path
                style={{ fill: "#EB9481" }}
                d="M367.711,182.399c1.756-0.014,4.719,0.441,8.714,3.484c0,0-8.335,6.702-16.095,6.71      c-2.773,0.003-3.604-3.869-3.604-3.869S360.457,182.458,367.711,182.399z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#f7045e" }}
                  d="M499.457,470.316h-32.669c-23.741,0-38.255-16.407-40.868-46.2       c-1.288-14.682-2.076-32.465-2.911-51.291c-2.563-57.823-6.073-137.014-28.575-137.014h-25.872v-6h25.872       c28.241,0,31.459,72.568,34.569,142.747c0.832,18.761,1.617,36.48,2.894,51.034c1.63,18.578,9.006,40.724,34.892,40.724h32.669       V470.316z"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="387.56,229.81 386.28,235.81 368.56,235.81 368.56,229.81      "
                />
              </g>
              <rect
                x="309.342"
                y="196.904"
                style={{ fill: "#f7045e" }}
                width="10.459"
                height="73.093"
              />
              <g>
                <rect
                  x="269.576"
                  y="202.303"
                  style={{ fill: "#f7045e" }}
                  width="39.767"
                  height="61.542"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="309.339,202.306 309.339,263.848 299.463,263.848 295.034,202.306      "
                />
              </g>
              <g>
                <path
                  style={{ fill: "#DBDBDB" }}
                  d="M269.576,222.548h-30.513c-2.178,0-3.945-1.766-3.945-3.945v-1.307       c0-2.178,1.766-3.945,3.945-3.945h30.513V222.548z"
                />
                <rect
                  x="258.856"
                  y="213.354"
                  style={{ fill: "#A6A6A6" }}
                  width="10.713"
                  height="9.19"
                />
              </g>
              <g>
                <path
                  style={{ fill: "#DBDBDB" }}
                  d="M269.576,252.797h-30.513c-2.178,0-3.945-1.766-3.945-3.945v-1.307       c0-2.178,1.766-3.944,3.945-3.944h30.513V252.797z"
                />
                <rect
                  x="258.856"
                  y="243.603"
                  style={{ fill: "#A6A6A6" }}
                  width="10.713"
                  height="9.19"
                />
              </g>
              <g>
                <rect
                  x="350.539"
                  y="227.204"
                  style={{ fill: "#f7045e" }}
                  width="31.069"
                  height="12.272"
                />
                <rect
                  x="350.539"
                  y="227.209"
                  style={{ fill: "#f7045e" }}
                  width="31.074"
                  height="12.267"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="361.448,227.209 360.43,239.476 350.539,239.476 350.539,227.209      "
                />
              </g>
              <g>
                <path
                  style={{ fill: "#f7045e" }}
                  d="M344.388,261.528h-24.586v-56.907h24.586c3.397,0,6.151,2.754,6.151,6.151v44.605       C350.539,258.774,347.785,261.528,344.388,261.528z"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="332.884,204.624 328.455,261.531 319.805,261.531 319.805,204.624      "
                />
                <g style={{ opacity: "0.2" }}>
                  <rect x="328.631" y="216.125" width="1.961" height="33.9" />
                  <rect x="334.19" y="216.125" width="1.961" height="33.9" />
                  <rect x="339.749" y="216.125" width="1.961" height="33.9" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#EB9481" }}
                  d="M378.725,192.874c3.442,9.649-1.483,45.031-7.311,52.49       c-8.668,11.094-25.775,21.308-32.318,24.495c-2.851,1.389-15.669-19.092-12.873-21.011       c5.989-4.109,25.109-13.921,26.731-15.869c1.622-1.949,10.338-20.676,13.829-31.21       C371.467,187.636,376.984,187.993,378.725,192.874z"
                />
                <g>
                  <path
                    style={{ fill: "#EB9481" }}
                    d="M339.082,269.922c-9.076,5.177-27.125,4.416-29.587,3.665c-2.1-0.633-3.176-0.98-3.176-0.98        c-3.789-1.063-4.811-3.161-4.811-3.161s-4.742,0.06-5.255-2.8c-0.584-3.206,6.19-2.663,10.098-4.643        c8.954-4.536,20.413-13.488,22.865-15.053L339.082,269.922z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M319.077,263.844c-2.662,1.282-5.243,2.612-8.064,3.527c-3.096,1.004-6.243,1.451-9.436,1.987        c-0.1,0.017-0.087,0.157,0.014,0.156c5.933-0.054,12.671-2.171,17.606-5.443C319.316,263.991,319.21,263.779,319.077,263.844z        "
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M322.6,268.85c-2.659,1.104-7.345,3.015-16.288,3.661c-0.029,0.002-0.035,0.052-0.003,0.054        c5.862,0.353,11.22-0.768,16.393-3.444C322.863,269.038,322.777,268.777,322.6,268.85z"
                  />
                  <path
                    style={{ fill: "#EB9481" }}
                    d="M316.796,255.854c0.932-1.053,0.386-1.549-2.073-1.102c-2.489,0.446-11.813,4.302-12.882,0.926        c-1.079-3.387,12.206-8.626,16.358-9.056c4.152-0.44,11.243,0.328,11.243,0.328        C327.062,248.489,321.424,252.774,316.796,255.854z"
                  />
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#DBDBDB" }}
                  d="M369.079,195.301c6.051-12.73,11.57-9.358,12.098,3.229c0.493,11.772-1.162,26.184-1.162,26.184       l-21.932-6.29C358.083,218.425,365.207,203.448,369.079,195.301z"
                />
                <g>
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M374.539,187.884c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66        c-0.633-0.356-1.159-0.818-1.66-1.307C375.358,189.042,374.896,188.516,374.539,187.884z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M366.101,201.886c0.195,0.699,0.24,1.398,0.25,2.098c-0.009,0.699-0.054,1.398-0.25,2.098        c-0.196-0.699-0.241-1.398-0.25-2.098C365.861,203.284,365.906,202.585,366.101,201.886z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M379.268,206.703c-0.699,0.195-1.398,0.24-2.097,0.25c-0.699-0.009-1.398-0.054-2.097-0.25        c0.699-0.196,1.398-0.241,2.097-0.25C377.87,206.463,378.569,206.508,379.268,206.703z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M362.594,215.051c0.632,0.357,1.158,0.819,1.66,1.307c0.488,0.501,0.951,1.027,1.306,1.66        c-0.633-0.356-1.159-0.818-1.66-1.306C363.413,216.209,362.951,215.683,362.594,215.051z"
                  />
                  <path
                    style={{ fill: "#FFFFFF" }}
                    d="M377.785,219.5c0.357-0.632,0.819-1.158,1.306-1.66c0.501-0.488,1.027-0.951,1.66-1.306        c-0.356,0.633-0.819,1.159-1.306,1.66C378.943,218.681,378.417,219.144,377.785,219.5z"
                  />
                </g>
                <path
                  style={{ fill: "#263238" }}
                  d="M366.982,199.18c-0.82,1.668-1.683,3.314-2.542,4.962c0.154-0.785,0.27-1.57,0.356-2.368       c0.004-0.036-0.049-0.043-0.056-0.008c-0.124,0.622-0.274,1.238-0.387,1.862c-0.055,0.299-0.112,0.599-0.171,0.897       c-0.013,0.064-0.026,0.128-0.039,0.193c-0.668,1.285-1.332,2.572-1.963,3.876c-0.754,1.555-1.468,3.128-2.188,4.699       c-0.744,1.623-1.392,3.488-2.198,5.08c-0.07,0.138,1.943,0.572,2.136,0.639c0.197,0.069,0.289-0.127,0.088-0.152       c-0.186-0.023-1.835-0.68-1.857-0.695c0.717-1.583,1.609-3.085,2.376-4.644c0.764-1.552,1.532-3.1,2.262-4.668       c1.483-3.184,2.785-6.451,4.25-9.645C367.066,199.166,367.002,199.14,366.982,199.18z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M382.092,206.759c-0.204,0.652-0.432,1.302-0.597,1.965c-0.068,0.274-0.135,0.548-0.203,0.822       c0.081-2.272,0.152-4.544,0.221-6.816c0.001-0.027-0.04-0.033-0.042-0.006c-0.311,3.715-0.632,7.427-0.873,11.147       c-0.231,3.546-0.625,7.164-0.492,10.718c-0.296-0.044-1.983-0.422-2.011-0.312c-0.028,0.111,2.248,0.736,2.262,0.648       c0.556-3.592,0.597-7.57,0.77-11.198c0.049-1.021,0.082-2.044,0.121-3.065c0.125-0.633,0.303-1.253,0.473-1.876       c0.18-0.66,0.299-1.339,0.44-2.008C382.168,206.738,382.104,206.72,382.092,206.759z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M379.107,221.386c-3.086-1.098-15.883-5.139-18.71-5.829c-0.196-0.048-0.275,0.062-0.078,0.138       c9.164,3.508,15.655,4.884,18.738,5.809C379.135,221.527,379.186,221.414,379.107,221.386z"
                />
              </g>
            </g>
            <g>
              <path
                style={{ fill: "#EB9481" }}
                d="M271.602,217.947c3.029,0.925,2.385-3.174,2.385-3.174s2.348,5.676,5.84,5.423      c3.364-0.254,2.412-5.178,2.349-5.532c0.127,0.299,2.421,5.459,5.903,4.842c3.618-0.644,1.814-5.423,1.696-5.731      c0.209,0.245,3.02,3.437,5.559,2.367c4.362-1.841-2.602-10.2-2.094-14l6.129,0.18c0.199-0.027,0.408-0.054,0.599-0.091      l0.236-3.129c-1.75-0.617-3.727-1.768-5.658-2.847c-2.312-1.297-4.543-2.476-6.203-2.512c-2.729-0.054-3.455,2.285-3.455,2.285      s-1.796-2.838-4.552-2.439c-1.415,0.209-2.339,2.657-2.339,2.657s-1.533-2.213-3.963-1.705c-1.94,0.399-2.566,2.793-2.566,2.793      s-2.457-1.442-4.307-0.39C263.423,199.058,265.119,215.961,271.602,217.947z"
              />
              <path
                style={{ fill: "#EB9481" }}
                d="M294.027,201.833l5.341,0.49c0.199-0.027,0.408-0.054,0.599-0.091      c6.746-1.224,10.945-8.941,6.057-9.494c-3.718-0.426-7.735,4.498-11.48,3.509v0.009L294.027,201.833z"
              />
              <g>
                <path
                  style={{ fill: "#263238" }}
                  d="M271.309,197.699c-0.001-0.057-0.081-0.066-0.089-0.008c-0.799,5.695,0.069,12.196,2.778,17.307       c0.089,0.168,0.4,0.096,0.337-0.107C272.55,209.089,271.428,203.797,271.309,197.699z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M277.953,196.166c-0.001-0.066-0.111-0.065-0.12,0.005c-0.791,5.872,0.387,13.169,4.204,18.692       c0.131,0.189,0.438,0.228,0.304-0.001C280.081,210.997,278.032,201.657,277.953,196.166z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M290.174,214.069c-3.455-5.438-4.819-11.436-5.335-17.799c-0.007-0.083-0.123-0.065-0.136,0.006       c-0.596,3.181,0.156,7.202,1.005,10.282c0.814,2.949,2.14,5.447,4.276,7.632C290.066,214.274,290.247,214.185,290.174,214.069z       "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Character_1_1_">
        <g>
          <g>
            <g>
              <g>
                <path
                  style={{ fill: "#AD6359" }}
                  d="M205.704,442.042c-2.076,0.689-61.344,0.517-63.602-0.916       c-0.852-0.535-1.333-9.177-1.614-19.623c-0.064-2.258-0.109-4.579-0.145-6.955c-0.045-2.802-0.082-5.64-0.1-8.397       c-0.072-12.378,0.1-23.277,0.1-23.277h38.584l-0.018,23.277l-0.009,8.397l-0.199,6.955c0,0,22.661,10.22,24.964,12.06       C205.958,435.396,207.772,441.362,205.704,442.042z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M205.704,442.042c-2.076,0.689-61.344,0.517-63.602-0.916       c-0.852-0.535-1.333-9.177-1.614-19.623c-0.064-2.258-0.109-4.579-0.145-6.955c-0.045-2.802-0.082-5.64-0.1-8.397h38.665       l-0.009,8.397l-0.199,6.955c0,0,22.661,10.22,24.964,12.06C205.958,435.396,207.772,441.362,205.704,442.042z"
                />
                <g>
                  <path
                    style={{ fill: "#f7045e" }}
                    d="M205.883,442.132c-1.351,0.444-26.596,0.535-44.977,0.154        c-4.253-0.091-8.143-0.208-11.28-0.345c-4.325-0.199-7.227-0.435-7.699-0.734c-1.043-0.671-1.542-13.348-1.777-26.723h38.774        l-0.2,6.991c0,0,22.788,10.274,25.109,12.124C206.137,435.448,207.96,441.433,205.883,442.132z"
                  />
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M205.034,439.745c-9.99-0.274-50.308-0.438-60.204,0.051c-0.08,0.004-0.081,0.059,0,0.063          c9.896,0.488,50.214,0.325,60.204,0.051C205.245,439.903,205.245,439.751,205.034,439.745z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M180.143,419.261c-3.929-0.745-8.739-0.156-11.752,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.507-1.985,11.568-2.373          C180.412,419.73,180.424,419.314,180.143,419.261z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M182.975,420.979c-3.929-0.745-8.739-0.155-11.751,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.507-1.986,11.568-2.373          C183.245,421.449,183.257,421.033,182.975,420.979z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M185.808,422.698c-3.929-0.745-8.739-0.155-11.751,2.637          c-0.117,0.108,0.039,0.273,0.166,0.233c3.882-1.211,7.506-1.986,11.568-2.373          C186.077,423.167,186.09,422.751,185.808,422.698z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M188.641,424.416c-3.929-0.745-8.739-0.155-11.752,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.507-1.986,11.568-2.373          C188.91,424.885,188.922,424.469,188.641,424.416z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M187.43,410.19c-1.539-2.132-4.418-1.183-6.063,0.191c-2.664,2.226-5,5.707-6.081,8.988          c-0.027,0.081,0.027,0.13,0.092,0.142c0.011,0.299,0.278,0.616,0.615,0.493c3.25-1.193,6.872-2.065,9.658-4.22          C187.279,414.527,188.885,412.207,187.43,410.19z M180.55,417.188c-1.584,0.625-3.189,1.169-4.75,1.853          c1.191-1.75,2.3-3.542,3.667-5.171c0.652-0.777,1.337-1.563,2.09-2.245c1.172-1.061,5.81-3.312,5.104,0.999          C186.273,415.001,182.438,416.442,180.55,417.188z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M165.297,418.314c3.237,1.391,6.962,1.331,10.406,1.675c0.357,0.036,0.537-0.339,0.473-0.631          c0.06-0.028,0.1-0.089,0.054-0.16c-1.865-2.907-4.996-5.696-8.132-7.186c-1.936-0.92-4.961-1.12-5.919,1.329          C161.273,415.657,163.407,417.502,165.297,418.314z M163.53,415.505c-1.759-3.999,3.293-2.976,4.693-2.241          c0.9,0.472,1.759,1.062,2.584,1.652c1.73,1.236,3.251,2.695,4.841,4.092c-1.682-0.273-3.372-0.399-5.062-0.609          C168.573,418.149,164.5,417.71,163.53,415.505z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#A6A6A6" }}
                  d="M184.413,411.76l-47.822,0.859c0,0-6.509-42.31-10.795-68.645       c-1.616-9.89-2.899-17.522-3.415-19.826c-1.238-5.489-25.475-92.631-25.475-92.631l34.769-0.046l11.872-0.011       c0,0-0.756,14.566-0.309,16.101c6.2,21.086,18.875,58.491,22.828,71.521C172.14,339.139,184.413,411.76,184.413,411.76z"
                />
                <g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M180.064,404.43c-6.868-0.05-31.204-0.324-40.346,0.529c-0.07,0.007-0.068,0.122,0.002,0.132         c3.146,0.452,33.482-0.252,40.346-0.472C180.196,404.615,180.193,404.431,180.064,404.43z"
                    />
                  </g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M137.372,368.368c-1.653-10.943-3.486-21.86-5.528-32.737        c-1.005-5.355-2.097-10.696-3.494-15.964c-1.413-5.331-3.179-10.558-4.976-15.77c-3.571-10.363-7.007-20.767-10.201-31.252        c-3.192-10.476-6.202-21.015-8.986-31.607c-0.353-1.344-0.71-2.684-1.094-4.019c-0.032-0.112-0.194-0.059-0.171,0.052        c1.088,5.339,2.494,10.628,3.852,15.905c1.359,5.281,2.799,10.539,4.31,15.778c3.036,10.526,6.322,20.982,9.882,31.343        c1.775,5.164,3.612,10.31,5.182,15.542c1.568,5.223,2.796,10.533,3.885,15.874c2.209,10.833,4.084,21.743,5.832,32.659        c1.757,10.974,3.226,21.98,4.689,32.995c0.186,1.396,0.368,2.792,0.547,4.189c0.051,0.394,0.67,0.389,0.627-0.015        C140.547,390.319,139.028,379.329,137.372,368.368z"
                  />
                </g>
              </g>
              <path
                style={{ fill: "#263238" }}
                d="M131.675,231.473l-5.879,112.502c-1.616-9.89-2.899-17.522-3.415-19.826      c-1.238-5.489-25.475-92.631-25.475-92.631L131.675,231.473z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#AD6359" }}
                  d="M146.392,451.479c-2.077,0.689-61.344,0.517-63.602-0.916       c-0.852-0.535-1.333-9.177-1.614-19.623c-0.064-2.258-0.109-4.579-0.145-6.955c-0.045-2.802-0.082-5.64-0.1-8.397       c-0.073-12.378,0.1-23.277,0.1-23.277h38.584l-0.018,23.277l-0.009,8.397l-0.2,6.955c0,0,22.661,10.22,24.964,12.06       C146.646,444.832,148.459,450.799,146.392,451.479z"
                />
                <path
                  style={{ fill: "#FFFFFF" }}
                  d="M146.392,451.479c-2.077,0.689-61.344,0.517-63.602-0.916       c-0.852-0.535-1.333-9.177-1.614-19.623c-0.064-2.258-0.109-4.579-0.145-6.955c-0.045-2.802-0.082-5.64-0.1-8.397h38.665       l-0.009,8.397l-0.2,6.955c0,0,22.661,10.22,24.964,12.06C146.646,444.832,148.459,450.799,146.392,451.479z"
                />
                <g>
                  <path
                    style={{ fill: "#f7045e" }}
                    d="M146.571,451.568c-1.351,0.444-26.596,0.535-44.977,0.154        c-4.253-0.091-8.143-0.209-11.281-0.345c-4.325-0.199-7.227-0.435-7.699-0.734c-1.043-0.671-1.541-13.348-1.777-26.723h38.774        l-0.2,6.991c0,0,22.788,10.274,25.109,12.124C146.824,444.885,148.647,450.87,146.571,451.568z"
                  />
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M145.722,449.182c-9.99-0.274-50.308-0.438-60.204,0.05c-0.08,0.004-0.081,0.059,0,0.063          c9.896,0.488,50.214,0.325,60.204,0.051C145.932,449.34,145.933,449.188,145.722,449.182z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M120.83,428.698c-3.929-0.745-8.739-0.156-11.752,2.637          c-0.117,0.108,0.039,0.273,0.166,0.233c3.882-1.211,7.507-1.986,11.568-2.373          C121.099,429.167,121.111,428.751,120.83,428.698z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M123.663,430.416c-3.929-0.745-8.739-0.155-11.751,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.506-1.986,11.568-2.373          C123.932,430.885,123.944,430.469,123.663,430.416z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M126.496,432.134c-3.929-0.745-8.739-0.156-11.751,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.507-1.986,11.568-2.373          C126.765,432.604,126.777,432.188,126.496,432.134z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M129.328,433.853c-3.929-0.745-8.739-0.155-11.752,2.637          c-0.117,0.108,0.039,0.272,0.166,0.233c3.882-1.211,7.507-1.986,11.568-2.373          C129.597,434.322,129.61,433.906,129.328,433.853z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M128.117,419.627c-1.539-2.132-4.418-1.183-6.063,0.191c-2.664,2.226-5,5.707-6.081,8.988          c-0.027,0.081,0.027,0.13,0.092,0.142c0.011,0.299,0.278,0.616,0.615,0.493c3.25-1.193,6.872-2.065,9.659-4.22          C127.966,423.963,129.573,421.644,128.117,419.627z M121.238,426.625c-1.584,0.625-3.189,1.169-4.75,1.853          c1.191-1.75,2.3-3.542,3.667-5.171c0.652-0.777,1.337-1.563,2.09-2.245c1.172-1.061,5.809-3.313,5.104,0.999          C126.96,424.438,123.126,425.879,121.238,426.625z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M105.984,427.75c3.237,1.391,6.962,1.331,10.406,1.675c0.357,0.036,0.537-0.339,0.473-0.631          c0.06-0.028,0.1-0.089,0.054-0.16c-1.865-2.907-4.996-5.696-8.132-7.186c-1.936-0.92-4.962-1.12-5.919,1.329          C101.96,425.094,104.095,426.939,105.984,427.75z M104.217,424.942c-1.759-3.999,3.293-2.976,4.693-2.241          c0.899,0.472,1.759,1.062,2.584,1.652c1.73,1.236,3.251,2.695,4.841,4.092c-1.682-0.273-3.372-0.399-5.062-0.609          C109.26,427.585,105.187,427.147,104.217,424.942z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#A6A6A6" }}
                  d="M125.026,420.625H78.215c0,0,10.163-145.071,8.521-153.205       c-2.689-13.314-0.891-28.601,5.402-35.96h41.633c0,0,0.825,11.684-3.472,18.662c0,0-3.905,2.468-5.803,3.557L125.026,420.625z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M120.644,413.065c-6.481-0.169-4.619-0.212-11.102-0.309c-3.173-0.048-24.02-0.34-26.987,0.135        c-0.065,0.011-0.066,0.121-0.001,0.132c2.962,0.507,23.811,0.436,26.984,0.422c6.484-0.028,4.622-0.09,11.105-0.191        C120.765,413.252,120.766,413.068,120.644,413.065z"
                  />
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M132.895,248.248c-4.11,1.9-8.003,4.379-11.705,6.934c-0.145,0.1-0.017,0.321,0.143,0.232         c3.96-2.19,8.009-4.316,11.841-6.713C133.474,248.514,133.21,248.102,132.895,248.248z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M127.593,247.649c-0.248,0.804-0.427,1.633-0.565,2.46c-0.107,0.643-0.273,1.343-0.125,1.984         c0.029,0.124,0.203,0.117,0.263,0.024c0.375-0.582,0.475-1.331,0.647-1.99c0.199-0.764,0.415-1.524,0.591-2.292         C128.521,247.325,127.746,247.153,127.593,247.649z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M127.22,243.638c0.111-0.695,0.223-1.39,0.349-2.082c0.13-0.716,0.308-1.435,0.382-2.159         c0.012-0.114,0.196-0.08,0.177,0.036c-0.109,0.696-0.122,1.414-0.177,2.117c-0.056,0.706-0.123,1.41-0.194,2.114         c-0.132,1.311-0.356,2.633-0.616,3.929c0.676-0.162,1.367-0.348,1.914-0.794c0.594-0.484,0.84-1.286,1.025-2.003         c0.205-0.796,0.377-1.601,0.594-2.393c0.24-0.877,0.526-1.755,0.609-2.664c0.01-0.115,0.192-0.081,0.178,0.036         c-0.23,1.891-0.451,3.777-1.059,5.608c-0.239,0.719-0.4,1.448-1.047,1.855c-0.695,0.437-1.532,0.57-2.331,0.69         c-0.091,0.049-0.23,0.015-0.223-0.11C126.88,246.428,127,245.012,127.22,243.638z"
                    />
                  </g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M93.496,266.887c-0.298-3.06-0.796-6.097-1.13-9.153c-0.671-6.146-0.209-12.29,1.108-18.323        c0.025-0.113-0.152-0.156-0.174-0.043c-1.016,5.325-1.903,10.643-1.636,16.086c0.264,5.393,1.22,10.715,1.307,16.124        c0.088,5.478-0.343,10.952-0.694,16.414c-0.353,5.495-0.707,10.991-1.06,16.487c-1.421,22.089-2.731,44.185-4.076,66.278        c-0.375,6.167-0.724,12.335-1.081,18.503c-0.363,6.276-0.801,12.556-1.034,18.838c-0.02,0.543,0.788,0.503,0.832-0.02        c0.939-11.082,1.483-22.211,2.19-33.311c0.7-10.988,1.343-21.981,2.014-32.971c0.671-10.989,1.33-21.979,1.983-32.97        c0.326-5.494,0.637-10.989,0.953-16.484c0.315-5.473,0.72-10.95,0.801-16.433C93.847,272.899,93.788,269.883,93.496,266.887z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M108.236,239.03c-0.03-0.068-0.122-0.09-0.164-0.017c-0.343,0.592-0.282,1.569-0.394,2.244        c-0.135,0.821-0.307,1.629-0.569,2.418c-0.532,1.604-1.248,3.032-2.499,4.202c-2.621,2.453-6.129,3.359-9.513,4.188        c-0.126,0.031-0.08,0.202,0.034,0.21c1.99,0.142,4.014-0.284,5.896-0.923c1.662-0.564,3.264-1.341,4.555-2.549        c1.283-1.201,2.081-2.745,2.459-4.453c0.193-0.874,0.248-1.802,0.281-2.695c0.016-0.434,0.02-0.869,0.019-1.304        C108.341,239.926,108.412,239.421,108.236,239.03z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#AD6359" }}
                d="M127.841,182.883c0.862,6.796,14.183,32.074,28.36,43.276c5.2,4.109,32.769,6.542,39.664,6.013      c8.077-0.619,9.287-19.835,4.763-21.335c-5.645-1.871-31.785-4.176-36.92-6.036c-6.242-2.261-17.6-16.771-28.394-25.751      C129.759,174.428,127.177,177.648,127.841,182.883z"
              />
              <g>
                <path
                  style={{ fill: "#AD6359" }}
                  d="M195.865,232.172c2.553-0.068,14.227-1.409,17.392-2.509c3.165-1.1,12.044-4.926,11.447-7.435       c-0.596-2.51-3.285-1.597-3.285-1.597s3.493-2.209,2.663-4.68c-0.83-2.471-4.967-0.915-4.967-0.915s3.422-2.53,2.418-4.936       c-1.005-2.406-4.902-0.144-4.902-0.144s3.237-2.167,1.575-3.801c-1.999-1.965-7.515,2.705-11.1,3.657       c-0.814,0.216-16.814,3.208-16.814,3.208L195.865,232.172z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M216.969,209.769c-4.064,3.425-6.435,4.22-11.618,4.935c-0.101,0.014-0.103,0.168,0.004,0.163        c5.139-0.238,8.257-0.807,11.79-4.91C217.318,209.756,217.163,209.606,216.969,209.769z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M219.433,214.758c-4.532,3.188-7.096,4.074-12.469,5.222c-0.076,0.016-0.057,0.147,0.022,0.137        c5.365-0.686,8.463-1.324,12.573-5.105C219.744,214.841,219.641,214.612,219.433,214.758z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M221.144,220.681c-4.611,2.77-7.38,3.862-12.739,4.307c-0.126,0.01-0.123,0.196,0.005,0.198        c5.509,0.077,8.975-1.259,12.804-4.356C221.475,220.619,221.434,220.507,221.144,220.681z"
                  />
                </g>
              </g>
              <g>
                <path
                  style={{ fill: "#AD6359" }}
                  d="M186.553,210.921c0,0,5.617-3.578,8.431-4.606c2.814-1.027,14.712-0.997,14.851,1.572       c0.18,3.318-6.691,4.654-10.143,4.868c0,0,0.424,7.675-4.301,9.701S186.553,210.921,186.553,210.921z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M209.585,208.815c-2.019,3.144-6.567,3.133-9.899,3.592c-0.253,0.035-0.314,0.251-0.25,0.439       c-0.037,3.229-1.03,7.773-3.849,9.688c-0.083,0.056-0.014,0.181,0.076,0.136c3.303-1.643,4.642-6.028,4.457-9.567       c3.425-0.416,7.987-0.606,9.496-4.27C209.625,208.812,209.598,208.795,209.585,208.815z"
                />
              </g>
              <g>
                <path
                  style={{ fill: "#455A64" }}
                  d="M172.047,231.798c0,0-14.121-3.858-15.839-5.208c-1.874-1.471-3.661-3.063-5.361-4.742       c-12.842-12.632-20.607-29.83-22.711-35.882c-2.211-6.388-0.171-12.505,5.779-8.385c0.439,0.309,0.895,0.67,1.379,1.086       c7.006,6.102,25.715,24.65,27.981,25.606c2.259,0.942,13.276,2.385,13.276,2.385L172.047,231.798z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M150.847,221.849c-12.842-12.632-20.607-29.83-22.711-35.882       c-2.211-6.388-0.171-12.505,5.779-8.385L150.847,221.849z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M171.993,206.254c-0.715,1.892-1.153,3.923-1.634,5.885c-0.485,1.979-0.978,3.958-1.409,5.95       c-0.849,3.921-1.641,7.901-2.042,11.895c-0.01,0.103,0.152,0.133,0.18,0.032c1.08-3.919,1.88-7.931,2.705-11.91       c0.407-1.962,0.781-3.932,1.188-5.895c0.406-1.959,0.902-3.948,1.118-5.938C172.105,206.209,172.014,206.199,171.993,206.254z"
                />
              </g>
            </g>
            <g>
              <path
                style={{ fill: "#263238" }}
                d="M141.537,196.73c0.338,1.485,0.597,3.021,0.791,4.56c0.006-0.059,0.014-0.117,0.02-0.176      c0.078-0.852,0.142-1.721,0.287-2.564c0.008-0.044,0.08-0.053,0.084-0.002c0.068,0.853,0.003,1.746-0.099,2.595      c-0.049,0.406-0.101,0.812-0.162,1.217c0.072,0.69,0.131,1.378,0.172,2.06c0.078,1.299,0.329,6.846,0.304,7.802      c-0.005,0.201-0.238,0.218-0.281,0.007c-0.197-0.981-0.694-6.695-0.83-7.982c-0.262-2.472-0.394-5.03-0.385-7.516      C141.439,196.673,141.525,196.678,141.537,196.73z"
              />
              <g>
                <path
                  style={{ fill: "#455A64" }}
                  d="M143.13,238.265c-1.975,1.803-4.44,4.435-18.484,5.411c-0.879,0.056-1.773,0.077-2.68,0.055       c-13.52-0.379-32.49-11.034-33.615-11.842c0,0,14.569-39.416,33.837-54.553c2.753-2.163,8.654-1.091,12.343,1.135       c1.318,0.795,5.682,8.094,6.555,14.729C141.949,199.827,145.125,236.469,143.13,238.265z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    style={{ fill: "#AD6359" }}
                    d="M132.175,188.304c-3.4,0.272-8.262-5.605-9.863-10.01c-0.108-0.302,0.77-3.173,1.671-6.712        c0.538-2.15,1.087-4.556,1.43-6.787c0.068-0.459,1.499,0.622,1.499,0.622l2.781,1.141l9.196,3.785        c0,0-2.094,4.467-2.667,8.362c-0.044,0.326-0.059,0.633-0.044,0.931c-0.002,0.043,0.003,0.096-0.003,0.155        C136.191,181.574,135.461,188.038,132.175,188.304z"
                  />
                </g>
                <path
                  style={{ fill: "#263238" }}
                  d="M136.177,179.636c-0.002,0.043,0.003,0.096-0.003,0.155c-0.448-0.005-0.929-0.053-1.45-0.145       c-9.393-1.656-8.732-13.821-8.732-13.821l3.7,0.733l9.196,3.785c0,0-2.094,4.467-2.667,8.362       C136.177,179.031,136.163,179.338,136.177,179.636z"
                />
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: "#AD6359" }}
                    d="M124.458,150.16c-1.786,4.937,0.123,19.559,3.525,22.971c4.932,4.947,13.375,5.826,17.67-0.186        c4.164-5.828,1.339-24.84-2.495-27.978C137.51,140.343,127.097,142.868,124.458,150.16z"
                  />
                </g>

                <line
                  style={{
                    fill: "none",
                    stroke: "#F6A89F",
                    strokeWidth: "0",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                  }}
                  x1="127.631"
                  y1="172.971"
                  x2="127.631"
                  y2="172.966"
                />
                <g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M137.783,160.279c-0.012-0.036-0.071,0.056-0.065,0.097c0.155,0.981,0.167,2.132-0.684,2.571         c-0.026,0.014-0.017,0.063,0.015,0.054C138.119,162.711,138.087,161.184,137.783,160.279z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M136.773,159.361c-1.6,0.079-1.325,3.273,0.158,3.2         C138.39,162.489,138.115,159.295,136.773,159.361z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M143.841,159.624c0.004-0.038,0.081,0.04,0.084,0.081c0.054,0.992,0.283,2.119,1.206,2.371         c0.029,0.008,0.03,0.058-0.003,0.056C144.022,162.072,143.733,160.572,143.841,159.624z"
                    />
                    <path
                      style={{ fill: "#263238" }}
                      d="M144.636,158.514c1.581-0.258,1.98,2.924,0.516,3.162         C143.71,161.912,143.31,158.73,144.636,158.514z"
                    />
                  </g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M135.526,157.035c0.426-0.147,0.794-0.354,1.2-0.542c0.458-0.213,0.845-0.336,1.118-0.786        c0.146-0.242,0.042-0.644-0.179-0.808c-0.516-0.383-1.142-0.261-1.703-0.027c-0.61,0.254-1.015,0.606-1.326,1.187        C134.333,156.62,134.965,157.229,135.526,157.035z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M145.642,157.43c-0.449-0.034-0.857-0.14-1.298-0.22c-0.497-0.09-0.902-0.11-1.281-0.477        c-0.203-0.197-0.204-0.612-0.032-0.827c0.401-0.501,1.038-0.542,1.641-0.459c0.654,0.091,1.135,0.328,1.584,0.811        C146.691,156.727,146.234,157.475,145.642,157.43z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M137.705,169.212c0.257,0.211,0.521,0.51,0.883,0.509c0.347-0.001,0.737-0.191,1.042-0.341        c0.03-0.015,0.065,0.016,0.047,0.047c-0.224,0.402-0.744,0.686-1.21,0.645c-0.455-0.04-0.709-0.408-0.854-0.808        C137.594,169.209,137.667,169.182,137.705,169.212z"
                  />
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M142.452,164.993c0,0,0.193,1.474,0.188,2.169c-0.001,0.065-1.977-0.116-4.018,0.556         c-0.306,0.101-0.301-0.156,0.043-0.36c0.689-0.407,1.575-0.814,3.382-0.805c0.05-0.192-0.339-2.264-0.229-2.272         c0.38-0.03,1.126,0.234,1.575,0.369c-0.313-3.083-1.118-6.09-1.383-9.165c-0.007-0.11,0.15-0.142,0.182-0.044         c1.066,3.092,1.504,6.632,1.909,9.807C144.156,165.645,142.729,165.113,142.452,164.993z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M125.523,162.348c2.518-0.181,2.2-6.336,2.2-6.336s3.836-0.737,5.652-6.034        c0,0,4.983,3.045,9.122,2.24c6.487-1.261,6.208-9.705,3.755-11.396c-3.477-2.397-9.577,1.23-9.577,1.23        s-9.327-2.476-12.018,3.845c0,0-4.143,0.232-4.373,4.814S123.646,162.483,125.523,162.348z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M126.828,156.748c1.842-0.387,3.59-1.262,4.825-2.713c1.274-1.498,1.993-3.783,1.411-5.707        c-0.032-0.105,0.142-0.147,0.167-0.038c0.488,2.142,0.388,4.351-0.957,6.18c-1.232,1.676-3.331,2.732-5.427,2.464        C126.746,156.921,126.718,156.771,126.828,156.748z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M119.919,148.033c1.259-1.638,3.212-1.916,5.144-1.872c0.111,0.002,0.102,0.182-0.007,0.171        c-1.904-0.194-3.803,1.098-4.775,2.667c-0.953,1.538-0.709,3.456-0.296,5.136c0.793,3.224,2.73,6.117,5.489,7.978        c0.046,0.031,0.001,0.101-0.046,0.072c-3.129-1.936-5.434-5.109-6.158-8.733C118.918,151.683,118.758,149.544,119.919,148.033        z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M136.423,142.361c0.862-1.343,2.021-2.32,3.537-2.78c1.745-0.529,3.764-0.317,5.401,0.483        c1.388,0.678,2.175,2.082,2.295,3.625c0.137,1.76-0.666,3.426-1.643,4.818c-0.028,0.04-0.089,0.001-0.063-0.042        c0.787-1.315,1.39-2.801,1.275-4.374c-0.113-1.559-0.954-2.857-2.366-3.464c-2.68-1.153-6.581-0.91-8.294,1.825        C136.506,142.546,136.364,142.454,136.423,142.361z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#AD6359" }}
                    d="M126.952,162.124c0,0-3.133-4.79-5.297-3.687c-2.164,1.103,0.091,7.976,2.56,8.983        c2.469,1.007,3.446-1.26,3.446-1.26L126.952,162.124z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M122.35,160.392c-0.04-0.005-0.05,0.051-0.015,0.066c1.729,0.764,2.601,2.416,3.195,4.12        c-0.501-0.664-1.199-1.041-2.086-0.448c-0.047,0.031-0.005,0.104,0.045,0.095c0.722-0.132,1.236,0.009,1.703,0.598        c0.33,0.417,0.549,0.939,0.78,1.416c0.077,0.159,0.362,0.084,0.308-0.098c-0.004-0.013-0.009-0.027-0.013-0.041        C126.38,163.869,124.741,160.691,122.35,160.392z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{ fill: "#f7045e" }}
                  d="M32.669,470.316H0v-6h32.669c25.885,0,33.261-22.146,34.891-40.724       c1.277-14.554,2.063-32.275,2.894-51.036c3.111-70.178,6.329-142.745,34.569-142.745h25.872v6h-25.872       c-22.501,0-26.012,79.19-28.575,137.011c-0.834,18.828-1.623,36.611-2.911,51.294C70.924,453.909,56.41,470.316,32.669,470.316       z"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="130.9,229.81 130.9,235.81 110.26,235.81 108.98,229.81      "
                />
              </g>

              <rect
                x="220.684"
                y="194.582"
                transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 452.3846 466.1487)"
                style={{ fill: "#f7045e" }}
                width="11.016"
                height="76.985"
              />
              <g>
                <rect
                  x="178.8"
                  y="198.24"
                  style={{ fill: "#f7045e" }}
                  width="41.884"
                  height="68.881"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="220.68,198.242 220.68,267.124 210.279,267.124 205.614,198.242      "
                />
              </g>
              <g>
                <rect
                  x="115.36"
                  y="226.676"
                  transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 261.7897 465.6235)"
                  style={{ fill: "#f7045e" }}
                  width="31.069"
                  height="12.272"
                />
                <rect
                  x="115.355"
                  y="226.675"
                  style={{ fill: "#f7045e" }}
                  width="31.074"
                  height="12.267"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="135.52,238.942 136.539,226.675 146.429,226.675 146.429,238.942      "
                />
              </g>
              <g>
                <path
                  style={{ fill: "#f7045e" }}
                  d="M152.58,203.109h26.223v59.937H152.58c-3.397,0-6.151-2.754-6.151-6.151V209.26       C146.43,205.863,149.183,203.109,152.58,203.109z"
                />
                <polygon
                  style={{ opacity: "0.2" }}
                  points="165.024,263.043 169.689,203.106 178.8,203.106 178.8,263.043      "
                />
                <g style={{ opacity: "0.2" }}>
                  <rect
                    x="167.439"
                    y="215.225"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 336.9429 466.1546)"
                    width="2.065"
                    height="35.704"
                  />

                  <rect
                    x="161.584"
                    y="215.225"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 325.2327 466.1546)"
                    width="2.065"
                    height="35.704"
                  />

                  <rect
                    x="155.729"
                    y="215.225"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 313.5226 466.1546)"
                    width="2.065"
                    height="35.704"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                style={{ fill: "#AD6359" }}
                d="M113.447,191.148c-1.05,14.439-2.135,43.856,4.243,52.484      c8.369,11.322,31.977,18.19,38.959,20.246c3.042,0.896,10.194-18.975,8.411-21.859c-1.783-2.884-26.171-9.03-28.094-10.683      c-1.923-1.652-7.959-25.121-10.888-37.057C122.383,179.223,113.862,185.45,113.447,191.148z"
              />
              <g>
                <path
                  style={{ fill: "#AD6359" }}
                  d="M183.117,249.656c3.857,0.922,9.697-1.054,9.885,1.889c0.168,2.622-4.011,3.599-4.011,3.599       s5.063,0.421,4.441,3.295s-5.42,3.006-5.42,3.006s4.636,0.634,3.81,3.456c-0.826,2.821-5.287,3.206-5.287,3.206       s1.178,0.877-0.301,2.608c-2.117,2.476-9.151,2.185-12.788,1.161c-3.637-1.024-16.197-7.46-18.102-8.482l6.37-23.4       c0,0,7.308-1.164,11.085-1.302c3.778-0.138,16.349,2.863,15.815,6.033c-0.534,3.17-9.376,0.899-11.662,0.817       c-2.286-0.082-2.695,0.433-1.693,1.271C175.918,247.362,181.41,249.248,183.117,249.656z"
                />
                <g>
                  <path
                    style={{ fill: "#263238" }}
                    d="M188.774,255.091c-5.918,0.947-8.671,0.338-14.095-1.983c-0.106-0.045-0.197,0.102-0.091,0.16        c5.105,2.757,8.448,4.019,14.247,2.108C189.118,255.282,189.055,255.046,188.774,255.091z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M188.255,261.345c-6.233,0.447-9.224-0.188-15.084-2.201c-0.083-0.029-0.14,0.109-0.058,0.145        c5.583,2.456,8.947,3.639,15.116,2.375C188.507,261.605,188.54,261.324,188.255,261.345z"
                  />
                  <path
                    style={{ fill: "#263238" }}
                    d="M186.465,268.062c-6.065-0.003-9.376-0.557-14.814-3.242c-0.128-0.063-0.233,0.118-0.11,0.194        c5.279,3.277,9.404,4,14.904,3.233C186.821,268.194,186.847,268.062,186.465,268.062z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: "#455A64" }}
                    d="M138.237,259.189c0,0-19.07-9.575-21.957-16.918c-3.602-9.162-5.073-35.872-3.673-49.575        c1.4-13.703,10.94-12.044,14.052,0.117c3.024,11.814,9.774,35.43,10.466,36.534c0.897,1.433,11.771,4.119,11.771,4.119"
                  />
                </g>
                <path
                  style={{ fill: "#263238" }}
                  d="M136.329,226.293c-0.33-1.135-0.905-3.682-1.239-4.778c-0.671-2.206-1.336-4.413-2.026-6.614       c-0.691-2.203-1.371-4.41-2.108-6.598c-0.124-0.369-0.242-0.749-0.364-1.122c0.06-0.236-0.216-3.139-0.194-3.851       c0.003-0.113-0.179-0.116-0.171-0.01c0.055,0.71-0.02,1.423-0.066,2.132c-0.009,0.132,0,0.265-0.001,0.397       c-0.459-1.437-0.916-2.875-1.396-4.267c-0.037-0.106-0.198-0.067-0.167,0.036c0.902,2.997,1.34,4.891,1.986,7.318       c0.628,2.357,1.338,4.691,2.031,7.029c0.694,2.341,1.435,4.668,2.165,6.998c0.306,0.975,1.009,3.211,1.444,4.082       C136.65,227.9,136.673,227.475,136.329,226.293z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M114.821,234.359c-0.394-2.473-0.778-4.947-1.082-7.434c-0.607-4.968-0.975-9.929-1.214-14.928       c-0.136-2.853-0.347-5.702-0.507-8.552c-0.008-0.141-0.218-0.136-0.219,0.005c-0.011,2.537-0.085,5.074-0.017,7.612       c0.01,0.368,0.027,0.735,0.038,1.103c-0.043-0.171-0.08-0.343-0.124-0.514c-0.367-1.454-0.888-2.874-1.212-4.338       c-0.021-0.098-0.174-0.053-0.15,0.045c0.368,1.461,0.55,2.965,0.899,4.432c0.174,0.732,0.353,1.46,0.557,2.184       c0.03,0.107,0.069,0.21,0.102,0.316c0.059,1.46,0.129,2.92,0.222,4.38c0.317,5.016,0.812,9.994,1.731,14.942       c0.931,5.009,1.766,6.776,2.61,9.489c0.03,0.096,0.176,0.052,0.15-0.045C115.96,240.635,115.215,236.832,114.821,234.359z"
                />
                <path
                  style={{ fill: "#263238" }}
                  d="M145.273,233.458c-1.091,1.749-1.934,3.691-2.806,5.557c-0.879,1.881-1.766,3.762-2.594,5.666       c-1.63,3.75-3.214,7.571-4.411,11.483c-0.031,0.101,0.126,0.163,0.173,0.068c1.86-3.703,3.458-7.553,5.077-11.366       c0.798-1.881,1.564-3.775,2.361-5.656c0.796-1.878,1.688-3.767,2.3-5.713C145.393,233.436,145.304,233.408,145.273,233.458z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Error_404">
        <g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M163.919,135.361c0.109-0.136,0.3-0.15,0.422-0.041c0.708,0.572,1.894,1.24,3.134,1.226     c0.831,0,1.581-0.245,2.098-0.627c0.532-0.381,0.818-0.885,0.818-1.417c0-0.545-0.259-0.954-0.777-1.308     c-0.518-0.34-1.281-0.6-2.207-0.776h-0.027c-0.885-0.164-1.662-0.409-2.248-0.804c-0.586-0.381-0.981-0.954-0.981-1.662     c0-0.723,0.395-1.349,0.995-1.785c0.6-0.436,1.403-0.695,2.303-0.695c1.253,0,2.194,0.613,2.943,1.104     c0.136,0.095,0.177,0.272,0.081,0.422c-0.081,0.136-0.272,0.177-0.409,0.082c-0.749-0.491-1.567-1.009-2.616-1.009     c-0.777,0-1.472,0.232-1.949,0.572c-0.477,0.354-0.749,0.818-0.749,1.309c0.013,0.477,0.231,0.831,0.708,1.158     c0.477,0.327,1.186,0.559,2.044,0.722h0.027c0.954,0.177,1.799,0.449,2.426,0.872c0.626,0.409,1.049,1.035,1.049,1.798     s-0.437,1.444-1.077,1.908c-0.641,0.463-1.499,0.749-2.453,0.749c-1.458-0.014-2.725-0.749-3.516-1.376     C163.838,135.675,163.824,135.484,163.919,135.361z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M177.624,127.472c2.548,0,4.551,2.166,4.565,4.837c-0.014,2.671-2.017,4.837-4.565,4.837     c-2.575,0-4.592-2.167-4.605-4.837C173.033,129.639,175.049,127.472,177.624,127.472z M177.624,136.479     c2.099,0,3.87-1.84,3.884-4.17c-0.014-2.357-1.785-4.156-3.884-4.156c-2.167,0-3.938,1.799-3.938,4.156     C173.687,134.639,175.458,136.479,177.624,136.479z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M189.762,134.994c0-0.695-0.354-1.131-0.777-1.444c-0.422-0.3-0.927-0.45-1.104-0.477     c-0.041-0.014-0.055-0.027-0.068-0.027h-2.316v3.774c0,0.177-0.15,0.327-0.341,0.327c-0.177,0-0.327-0.15-0.327-0.327v-8.884     c0-0.164,0.136-0.313,0.299-0.327h0.027h0.041h2.657c1.498,0,2.711,1.212,2.711,2.725c0,1.09-0.64,2.03-1.567,2.466     c0.041,0.027,0.082,0.041,0.136,0.068c0.627,0.355,1.281,1.049,1.281,2.126c0,0.559,0.055,0.899,0.123,1.104     c0.055,0.164,0.123,0.218,0.136,0.232c0.15-0.014,0.314,0.068,0.368,0.218c0.041,0.177-0.041,0.368-0.218,0.436     c-0.014,0-0.082,0.014-0.164,0.014h-0.027c-0.177,0-0.463-0.136-0.613-0.436C189.857,136.247,189.762,135.798,189.762,134.994z      M187.854,128.276h-2.357v4.115h2.289h0.123c1.104-0.027,2.003-0.94,2.003-2.058     C189.912,129.189,188.985,128.276,187.854,128.276z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M198.276,134.994c0-0.695-0.355-1.131-0.777-1.444c-0.422-0.3-0.927-0.45-1.104-0.477     c-0.041-0.014-0.055-0.027-0.068-0.027h-2.316v3.774c0,0.177-0.15,0.327-0.341,0.327c-0.177,0-0.327-0.15-0.327-0.327v-8.884     c0-0.164,0.136-0.313,0.3-0.327h0.027h0.041h2.657c1.499,0,2.712,1.212,2.712,2.725c0,1.09-0.641,2.03-1.567,2.466     c0.041,0.027,0.081,0.041,0.136,0.068c0.627,0.355,1.281,1.049,1.281,2.126c0,0.559,0.055,0.899,0.122,1.104     c0.055,0.164,0.123,0.218,0.136,0.232c0.15-0.014,0.314,0.068,0.368,0.218c0.041,0.177-0.041,0.368-0.218,0.436     c-0.014,0-0.082,0.014-0.164,0.014h-0.027c-0.177,0-0.463-0.136-0.613-0.436C198.371,136.247,198.276,135.798,198.276,134.994z      M196.368,128.276h-2.357v4.115h2.289h0.122c1.104-0.027,2.004-0.94,2.004-2.058     C198.426,129.189,197.499,128.276,196.368,128.276z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M204.323,132.568l-3.202-4.374c-0.095-0.15-0.068-0.368,0.068-0.463     c0.15-0.123,0.368-0.096,0.463,0.054l2.984,4.102l2.998-4.102c0.096-0.15,0.313-0.177,0.463-0.054     c0.15,0.095,0.177,0.313,0.068,0.463l-3.188,4.347v4.278c0,0.177-0.136,0.327-0.327,0.327c-0.177,0-0.327-0.15-0.327-0.327     V132.568z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M209.784,127.935c0-0.177,0.15-0.327,0.327-0.327c0.191,0,0.341,0.15,0.341,0.327v6.84     c0,0.191-0.15,0.341-0.341,0.341c-0.177,0-0.327-0.15-0.327-0.341V127.935z M209.784,136.492c0-0.177,0.15-0.327,0.327-0.327     c0.191,0,0.341,0.15,0.341,0.327v0.327c0,0.177-0.15,0.327-0.341,0.327c-0.177,0-0.327-0.15-0.327-0.327V136.492z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M217.453,127.935c0-0.177,0.15-0.327,0.327-0.327h0.041h2.657c1.499,0,2.712,1.226,2.712,2.725     c0,1.499-1.213,2.712-2.712,2.712l-2.357,0.027v3.747c0,0.177-0.15,0.327-0.341,0.327c-0.177,0-0.327-0.15-0.327-0.327V127.935z      M220.478,132.405c1.131-0.014,2.058-0.94,2.058-2.071c0-1.131-0.926-2.058-2.058-2.058h-2.357v4.129H220.478z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M229.66,134.68h-4.347l-0.845,2.249c-0.055,0.136-0.177,0.218-0.313,0.218     c-0.027,0-0.082-0.014-0.123-0.014c-0.164-0.068-0.259-0.272-0.191-0.436l3.311-8.843c0.054-0.123,0.19-0.205,0.327-0.205     c0.15,0,0.286,0.082,0.327,0.205l3.311,8.843c0.068,0.164-0.014,0.368-0.177,0.436c-0.055,0-0.082,0.014-0.123,0.014     c-0.123,0-0.259-0.082-0.313-0.218L229.66,134.68z M225.559,134.013h3.856l-1.921-5.124L225.559,134.013z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M237.261,132.855c-0.191,0-0.341-0.137-0.341-0.327c0-0.191,0.15-0.341,0.341-0.341h2.943h0.041     c0.164,0.014,0.3,0.163,0.3,0.341v3.27v0.055c-0.014,0.095-0.055,0.191-0.123,0.245c-0.845,0.668-1.908,1.049-3.08,1.049     c-2.739-0.014-4.96-2.153-4.96-4.851c0-2.657,2.221-4.823,4.96-4.823c1.186,0,2.235,0.409,3.093,1.076     c0.123,0.095,0.164,0.327,0.04,0.463c-0.109,0.136-0.313,0.163-0.476,0.055c-0.695-0.572-1.635-0.927-2.657-0.927     c-2.385,0-4.292,1.867-4.292,4.156c0,2.316,1.907,4.184,4.292,4.184c0.927,0,1.813-0.3,2.521-0.818v-2.807H237.261z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M243.893,127.935c0-0.177,0.15-0.327,0.327-0.327h0.027h4.878c0.191,0,0.327,0.15,0.327,0.327     c0,0.191-0.136,0.341-0.327,0.341h-4.564v3.774h3.992c0.177,0,0.327,0.15,0.327,0.341c0,0.177-0.15,0.327-0.327,0.327h-3.992     v3.761h4.564c0.191,0,0.327,0.15,0.327,0.34c0,0.177-0.136,0.327-0.327,0.327h-4.864h-0.041h-0.027     c-0.163-0.014-0.3-0.15-0.3-0.327V127.935z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M256.752,129.012v7.808c0,0.177-0.15,0.327-0.327,0.327c-0.178,0-0.327-0.15-0.327-0.327v-8.748     c-0.041-0.136,0-0.272,0.123-0.368c0.149-0.095,0.367-0.068,0.463,0.082l5.777,8.012v-7.767c0-0.177,0.15-0.327,0.327-0.327     c0.191,0,0.341,0.15,0.341,0.327v8.789c0,0.177-0.15,0.327-0.341,0.327c-0.109,0-0.19-0.041-0.259-0.123     c-0.014-0.014-0.041-0.027-0.055-0.055L256.752,129.012z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M270.063,127.472c2.548,0,4.551,2.166,4.564,4.837c-0.014,2.671-2.017,4.837-4.564,4.837     c-2.575,0-4.592-2.167-4.605-4.837C265.471,129.639,267.488,127.472,270.063,127.472z M270.063,136.479     c2.099,0,3.869-1.84,3.883-4.17c-0.014-2.357-1.784-4.156-3.883-4.156c-2.167,0-3.939,1.799-3.939,4.156     C266.124,134.639,267.896,136.479,270.063,136.479z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M279.175,128.276H276.3c-0.178,0-0.327-0.15-0.327-0.341c0-0.177,0.149-0.327,0.327-0.327h6.403     c0.178,0,0.327,0.15,0.327,0.327c0,0.191-0.149,0.341-0.327,0.341h-2.875v8.544c0,0.177-0.149,0.327-0.326,0.327     c-0.178,0-0.327-0.15-0.327-0.327V128.276z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M289.622,127.935c0-0.177,0.137-0.313,0.313-0.327h0.055h4.878c0.177,0,0.327,0.15,0.327,0.327     c0,0.191-0.15,0.341-0.327,0.341h-4.578v3.774h4.006c0.19,0,0.341,0.15,0.341,0.341c0,0.177-0.15,0.327-0.341,0.327h-4.006v4.102     c0,0.177-0.15,0.327-0.341,0.327c-0.177,0-0.327-0.15-0.327-0.327V127.935z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M301.775,127.472c2.548,0,4.551,2.166,4.564,4.837c-0.014,2.671-2.017,4.837-4.564,4.837     c-2.575,0-4.592-2.167-4.605-4.837C297.183,129.639,299.2,127.472,301.775,127.472z M301.775,136.479     c2.099,0,3.869-1.84,3.883-4.17c-0.014-2.357-1.784-4.156-3.883-4.156c-2.167,0-3.939,1.799-3.939,4.156     C297.836,134.639,299.608,136.479,301.775,136.479z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M308.57,127.935c0-0.177,0.15-0.327,0.327-0.327c0.191,0,0.341,0.15,0.341,0.327v5.355     c0,1.757,1.431,3.175,3.175,3.188c1.771-0.014,3.202-1.431,3.202-3.188v-5.355c0-0.177,0.149-0.327,0.327-0.327     c0.19,0,0.341,0.15,0.341,0.327v5.355c0,2.126-1.732,3.856-3.87,3.856c-2.112,0-3.843-1.73-3.843-3.856V127.935z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M320.135,129.012v7.808c0,0.177-0.15,0.327-0.327,0.327c-0.178,0-0.327-0.15-0.327-0.327v-8.748     c-0.041-0.136,0-0.272,0.123-0.368c0.149-0.095,0.367-0.068,0.463,0.082l5.777,8.012v-7.767c0-0.177,0.15-0.327,0.327-0.327     c0.19,0,0.341,0.15,0.341,0.327v8.789c0,0.177-0.15,0.327-0.341,0.327c-0.109,0-0.19-0.041-0.259-0.123     c-0.014-0.014-0.041-0.027-0.055-0.055L320.135,129.012z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M329.617,137.147h-0.055c-0.163-0.014-0.3-0.15-0.3-0.327v-8.884c0-0.177,0.15-0.327,0.327-0.327     h0.041h1.744c2.63,0,4.769,2.153,4.769,4.783c0,2.63-2.139,4.755-4.769,4.755H329.617z M331.374,136.479     c2.262,0,4.102-1.826,4.102-4.088c0-2.276-1.84-4.102-4.102-4.115h-1.444v8.203H331.374z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M213.965,100.919c0,2.873-2.317,5.097-5.19,5.097h-0.926v7.044c0,2.873-2.224,5.097-5.097,5.097     c-2.873,0-5.098-2.224-5.098-5.097v-7.044h-29.008h-0.093h-0.093c-1.112,0-2.224-0.278-3.151-1.112     c-2.317-1.761-2.595-4.911-0.834-7.136l34.291-42.632c0.093,0,0.093-0.093,0.186-0.185c0.093-0.093,0.185-0.186,0.278-0.371     c0.277-0.093,0.37-0.185,0.463-0.278c0.093-0.093,0.278-0.186,0.371-0.278c0.186-0.093,0.278-0.186,0.555-0.186     c0.093-0.185,0.186-0.278,0.371-0.278c0.093-0.093,0.278-0.093,0.557-0.186c0.093,0,0.277,0,0.37-0.093c0.186,0,0.464,0,0.649,0     c0,0,0.093,0,0.186,0c0.093,0,0.093,0,0.184,0c0.185,0,0.464,0,0.557,0c0.186,0.093,0.278,0.093,0.464,0.093     c0.092,0.093,0.37,0.093,0.555,0.186c0.093,0,0.278,0.093,0.371,0.093c0.186,0.185,0.37,0.278,0.463,0.37     c0.186,0.093,0.278,0.186,0.464,0.278c0,0,0.093,0,0.093,0.093c0.093,0,0.278,0.092,0.278,0.185     c0.186,0.186,0.277,0.278,0.37,0.371c0.093,0.092,0.186,0.278,0.278,0.37c0.093,0.093,0.186,0.186,0.371,0.463     c0,0.093,0.093,0.278,0.186,0.371c0.093,0.185,0.093,0.278,0.186,0.556c0,0.092,0.092,0.278,0.092,0.37     c0.093,0.186,0.093,0.278,0.093,0.556c0,0.186,0.093,0.278,0.093,0.463c0,0.093,0,0.185,0,0.278v37.442h0.926     C211.648,95.822,213.965,98.139,213.965,100.919z M197.654,72.93l-18.443,22.892h18.443V72.93z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M226.188,85.72c0.093-8.526,2.224-16.219,6.302-22.15c3.892-5.931,9.916-10.287,17.237-10.287     c7.137,0,13.253,4.356,17.146,10.287c3.984,5.931,6.208,13.624,6.208,22.15c0,8.433-2.224,16.218-6.208,22.149     c-3.894,5.839-10.01,10.287-17.146,10.287c-7.322,0-13.346-4.448-17.237-10.287C228.412,101.938,226.281,94.153,226.188,85.72z      M262.795,85.72c0-6.673-1.853-12.511-4.448-16.497c-2.688-3.892-5.745-5.746-8.619-5.653c-2.873-0.093-6.024,1.761-8.804,5.653     c-2.595,3.986-4.449,9.824-4.449,16.497c0,6.58,1.854,12.511,4.449,16.404c2.779,3.985,5.93,5.746,8.804,5.746     c2.874,0,5.931-1.761,8.619-5.746C260.941,98.232,262.795,92.3,262.795,85.72z"
            />
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M329.665,100.919c0,2.873-2.317,5.097-5.19,5.097h-0.928v7.044c0,2.873-2.224,5.097-5.095,5.097     c-2.874,0-5.098-2.224-5.098-5.097v-7.044h-29.008h-0.093h-0.093c-1.113,0-2.224-0.278-3.152-1.112     c-2.317-1.761-2.595-4.911-0.833-7.136l34.289-42.632c0.093,0,0.093-0.093,0.185-0.185c0.093-0.093,0.186-0.186,0.278-0.371     c0.278-0.093,0.371-0.185,0.464-0.278c0.093-0.093,0.278-0.186,0.371-0.278c0.185-0.093,0.278-0.186,0.554-0.186     c0.093-0.185,0.186-0.278,0.371-0.278c0.093-0.093,0.278-0.093,0.557-0.186c0.093,0,0.278,0,0.371-0.093c0.186,0,0.464,0,0.649,0     c0,0,0.093,0,0.186,0c0.093,0,0.093,0,0.183,0c0.186,0,0.464,0,0.557,0c0.185,0.093,0.278,0.093,0.464,0.093     c0.093,0.093,0.371,0.093,0.557,0.186c0.093,0,0.278,0.093,0.371,0.093c0.185,0.185,0.369,0.278,0.462,0.37     c0.186,0.093,0.278,0.186,0.464,0.278c0,0,0.093,0,0.093,0.093c0.093,0,0.278,0.092,0.278,0.185     c0.186,0.186,0.278,0.278,0.371,0.371c0.093,0.092,0.186,0.278,0.278,0.37c0.093,0.093,0.185,0.186,0.371,0.463     c0,0.093,0.093,0.278,0.185,0.371c0.093,0.185,0.093,0.278,0.186,0.556c0,0.092,0.09,0.278,0.09,0.37     c0.093,0.186,0.093,0.278,0.093,0.556c0,0.186,0.093,0.278,0.093,0.463c0,0.093,0,0.185,0,0.278v37.442h0.928     C327.348,95.822,329.665,98.139,329.665,100.919z M313.354,72.93L294.91,95.822h18.445V72.93z"
            />
          </g>
        </g>
      </g>
      <g id="Lines">
        <g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M250.162,181.71c-0.276,0-0.5-0.224-0.5-0.5v-14.069c0-0.276,0.224-0.5,0.5-0.5     c0.276,0,0.5,0.224,0.5,0.5v14.069C250.662,181.487,250.438,181.71,250.162,181.71z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M224.697,188.447c-0.173,0-0.341-0.089-0.434-0.25l-7.035-12.185     c-0.138-0.239-0.056-0.545,0.183-0.683c0.238-0.139,0.544-0.057,0.683,0.183l7.035,12.185c0.138,0.239,0.056,0.545-0.183,0.683     C224.868,188.426,224.782,188.447,224.697,188.447z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M217.381,288.771c-0.085,0-0.171-0.022-0.249-0.067c-0.239-0.138-0.321-0.443-0.183-0.683     l7.035-12.185c0.138-0.239,0.444-0.322,0.683-0.183c0.239,0.138,0.321,0.443,0.183,0.683l-7.035,12.185     C217.722,288.681,217.554,288.771,217.381,288.771z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M249.838,297.554c-0.276,0-0.5-0.224-0.5-0.5v-14.07c0-0.276,0.224-0.5,0.5-0.5     c0.276,0,0.5,0.224,0.5,0.5v14.07C250.338,297.33,250.115,297.554,249.838,297.554z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M282.338,288.932c-0.172,0-0.341-0.09-0.433-0.25l-7.034-12.185     c-0.139-0.239-0.057-0.545,0.183-0.683c0.238-0.14,0.545-0.057,0.683,0.183l7.034,12.185c0.139,0.239,0.057,0.545-0.183,0.683     C282.509,288.91,282.423,288.932,282.338,288.932z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#263238" }}
              className="dark-img"
              d="M275.583,188.609c-0.085,0-0.171-0.021-0.25-0.067c-0.239-0.138-0.321-0.444-0.183-0.683     l7.035-12.185c0.138-0.24,0.444-0.319,0.683-0.183c0.239,0.138,0.321,0.444,0.183,0.683l-7.035,12.185     C275.924,188.52,275.755,188.609,275.583,188.609z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
